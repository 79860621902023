import { Grid } from "@mui/material"
import SideNav from "./SideNav"

function Welcome() {
    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <SideNav />
            </Grid>
            <Grid item xs={9}>
                <div className="flex flex-col max-h-screen h-screen">
                    <h1>Welcome</h1>
                </div>
            </Grid>
        </Grid>
    )
}
export default Welcome