import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material";
import { useEffect, useState } from 'react';
import { setDesignGroup } from "../../redux/abtests/abTestsActions";
import { DesignGroup, DesignGroupInterface, DesignInterface } from "../../redux/abtests/interfaces";
import CustomModal from '../../reusables/CustomModal/CustomModal';
import GotDesignCard from "../../reusables/GotDesignCard";
import AddOneDesignToDesignGroupForm from './modals/AddOneDesignToDesignGroupForm';
import DeleteDesignGroupForm from './modals/DeleteDesignGroupForm';
import UpdateDesignGroupForm from './modals/UpdateDesignGroupForm';
type Props = {
    designGroup: DesignGroupInterface,
    designs: DesignInterface[],
    idx: number,
    testStarted: boolean
}

function SingleDesignGroup({ 
    designGroup, 
    designs, 
    idx,
    testStarted
}: Props) {

    // SINGLE DESIGN INTERFACE
    const [openAddSingleDesignToDesignGroup, setOpenAddSingleDesignToDesignGroup] = useState(false);

    // DESIGN GROUP DATA
    const [openDeleteDesignGroup, setOpenDeleteDesignGroup] = useState(false);
    const [designGroupId, setDesignGroupId] = useState('');
    const [openUpdateDesignGroup, setOpenUpdateDesignGroup] = useState(false);
    const [winner, setWinner] = useState(-1);
    const [expanded, setExpanded] = useState(idx === 0 ? true : false);

    useEffect(() => {
        let old_ratio = '0';
        designs.forEach((design, i) => {
            if (design.ratio !== 'NAN' && parseFloat(design.ratio) > 0) {

                if (parseFloat(old_ratio) <= parseFloat(design.ratio)) {
                    old_ratio = design.ratio;
                    setWinner(i);
                }


            }
        });
    }, [])

    return (
        <><Accordion
            className='overflow-x-scroll'
            expanded={expanded}
            key={idx}
        >
            <AccordionSummary
                onClick={() => setExpanded(!expanded)}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
            >
                <p>{designGroup.name}</p>
            </AccordionSummary>
            <AccordionDetails className='p-0'>
                <div className="flex flex-row flex-grow p-0">
                    {designs.map((design, i) => {
                        if (winner === i) {
                            return <GotDesignCard 
                            key={i} 
                            testStarted={testStarted}
                            design={design} 
                            designGroup={designGroup} 
                            isWinner={true} />
                        } else
                            return <GotDesignCard 
                            key={i} 
                            testStarted={testStarted}
                            design={design} 
                            designGroup={designGroup} />
                    }
                    )}
                </div>

                <div className="flex flex-row my-1 p-3">
                    <Button variant="outlined"
                        color='warning'
                        disabled={testStarted}
                        className="mx-2"
                        onClick={() => { setOpenUpdateDesignGroup(true); }}
                    >Update</Button>

                    <Button
                        variant="outlined"
                        color='error'
                        disabled={testStarted}
                        className="mx-2"
                        onClick={() => { setDesignGroupId(designGroup._id); setOpenDeleteDesignGroup(true); }}>Delete
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={testStarted}
                        className="mx-2"
                        onClick={() => { setOpenAddSingleDesignToDesignGroup(true); }}
                    >
                        add design
                    </Button>
                </div>
            </AccordionDetails>
        </Accordion>



            <CustomModal open={openUpdateDesignGroup} setOpen={setOpenUpdateDesignGroup}>
                <UpdateDesignGroupForm designGroup={designGroup}
                    open={openUpdateDesignGroup} setOpen={setOpenUpdateDesignGroup} />
            </CustomModal><CustomModal open={openDeleteDesignGroup} setOpen={setOpenDeleteDesignGroup}>
                <DeleteDesignGroupForm designGroupId={designGroupId}
                    open={openDeleteDesignGroup} setOpen={setOpenDeleteDesignGroup} />
            </CustomModal><CustomModal open={openAddSingleDesignToDesignGroup} setOpen={setOpenAddSingleDesignToDesignGroup}>
                <AddOneDesignToDesignGroupForm designGroup={designGroup}
                    open={openAddSingleDesignToDesignGroup} setOpen={setOpenAddSingleDesignToDesignGroup} />
            </CustomModal></>
    )

}
export default SingleDesignGroup