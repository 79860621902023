import { Button, TextField } from "@mui/material"
import { useSnackbar } from "notistack"
import { Dispatch, SetStateAction, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { updateDesignGroup } from "../../../redux/abtests/abTestsActions"
import { DesignGroupInterface, UpdateDesignGroupRequest } from "../../../redux/abtests/interfaces"
import { setAlert } from "../../../redux/admin/adminActions"
import { AlertMsg } from "../../../redux/admin/interfaces"
import { WARN_SNACKBAR } from "../../../redux/helpers/constants"
import { ReduxAction, RootState } from "../../../redux/rootReducer"

type Props = {
    updateDesignGroup:(designGroupId: string,updateDesignGroupRequest: UpdateDesignGroupRequest) => ReduxAction,
    designGroup: DesignGroupInterface | null,
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>> | ((b: boolean) => ReduxAction),
    setAlert: (alert: AlertMsg) => ReduxAction,
    alertMsg: AlertMsg
}

function UpdateDesignGroupForm({
    updateDesignGroup,
    designGroup,
    open,
    setOpen,
    setAlert,
    alertMsg,
}:Props){
    const dispatch = useDispatch();
    const [name,setName]= useState(designGroup?.name);
    const handleUpdateDesignGroup = () => {
        if (!name) {
            dispatch(setAlert({
                msg: 'design group name required!',
                config: WARN_SNACKBAR
            }));
            return;
        }

        const updateDesignGroupRequest: UpdateDesignGroupRequest = {
            name: name as string
        }
        updateDesignGroup(designGroup?._id as string,updateDesignGroupRequest)
    }
    const handleDismiss = () => {
        setOpen(false)
    }
    return (
        <div className="flex flex-col p-2">
        <p className="text-2xl mt-3 text-left">Design Group Fields</p>
        <section>
                <TextField
                    className="mt-3"
                    fullWidth
                    required
                    error={!name}
                    label="Design Group Name"
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                    }}
                />
            </section>
            <div className="">
                <Button
                    className="mt-3 mx-3"
                    onClick={handleUpdateDesignGroup}
                    disableElevation
                    sx={{ fontSize: '15px' }}
                    variant="contained">
                    Update Design
                </Button>
                <Button
                    className="mt-3 mx-3"
                    onClick={handleDismiss}
                    disableElevation
                    color='primary'
                    sx={{ fontSize: '15px' }}
                    variant="outlined">
                    Cancel
                </Button>
            </div>        
        </div>
    )
}

const mapDispatch = {
    updateDesignGroup:(designGroupId: string,updateDesignGroupRequest: UpdateDesignGroupRequest) => updateDesignGroup(designGroupId,updateDesignGroupRequest),
    setAlert: (p: AlertMsg) => setAlert(p)
}
const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    return {
        alertMsg,
    }
}
export default connect(mapStateToProps,mapDispatch)(UpdateDesignGroupForm)