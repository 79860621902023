import AddBoxIcon from '@mui/icons-material/AddBox';
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { useSnackbar } from 'notistack';
import { useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { setAlert } from '../../redux/admin/adminActions';
import { AlertMsg } from '../../redux/admin/interfaces';
import { WARN_SNACKBAR } from '../../redux/helpers/constants';
import { RemoteConfigGridRow, UpdateRemoteConfigRequest } from "../../redux/remoteConfigs/interfaces"
import { updateRemoteConfig } from '../../redux/remoteConfigs/remoteConfigsActions';
import { ReduxAction, RootState } from '../../redux/rootReducer';
import { getFileSizeMB, getFileSizeMsg } from '../../reusables/helpers.tsx/utils';
import PageTitle from "../../reusables/PageTitle";

type Itype = "image" | "text" | "float" | "boolean" | "integer";

type Props = {
    remoteConfig: RemoteConfigGridRow | null,
    updateRemoteConfig: (remoteConfig: UpdateRemoteConfigRequest, remoteConfigId: string) => ReduxAction,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setAlert: (alert: AlertMsg) => ReduxAction,
    alertMsg: AlertMsg,
}
function UpdateRemoteConfigForm({
    remoteConfig,
    updateRemoteConfig,
    open,
    setOpen,
    setAlert,
    alertMsg
}: Props) {

    const dispatch = useDispatch();


    const [key, setKey] = useState<string | undefined>(remoteConfig?.key);
    const [type, setType] = useState<Itype | undefined>(remoteConfig?.type as Itype);
    const [boolValue, setBoolValue] = useState<String | undefined>(String(remoteConfig?.type));
    const [value, setValue] = useState<String | undefined>(remoteConfig?.value);

    const handleDismiss = () => {
        setOpen(false)
    }

    // IMAGE UPLOAD VARS
    const [file, setFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | undefined | null>(undefined);
    const [fileSize, setFileSize] = useState<string>('');


    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setFile(file);
            const size = getFileSizeMsg(file);
            setFileSize(size);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setPreviewUrl(reader.result as string);
            };
        }
    };

    const handleUpdateRemoteConfig = () => {

        if (!value) {
            dispatch(setAlert({
                msg: 'value required!',
                config: WARN_SNACKBAR
            }));
            return;
        }
        if (type === 'image') {
            if (!file) {
                dispatch(setAlert({
                    msg: 'image required',
                    config: WARN_SNACKBAR
                }));
                return;
            }
            if (getFileSizeMB(file) > 1.6) {
                dispatch(setAlert({
                    msg: 'The file size is greater than 1.6MB',
                    config: WARN_SNACKBAR
                }));
                return;
            }
        }

        const updateRemoteConfigRequest: UpdateRemoteConfigRequest = {
            value: String(value),
            image: file
        }
        if (remoteConfig?._id)
            updateRemoteConfig(updateRemoteConfigRequest, remoteConfig?._id)




    }


    useEffect(() => {
        if (remoteConfig?.type === 'image') {
            setPreviewUrl(remoteConfig?.value);
        }
    }, []);
    return (
        <div>
            <PageTitle title='Update Remote Config' />
            <section>
                <TextField
                    className="my-3"
                    fullWidth
                    required
                    disabled
                    label="Remote Config Key"
                    value={key}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setKey(event.target.value);
                    }}
                />
            </section>

            {String(remoteConfig?.type) === 'text' ?
                <section className="my-3">
                    <TextField
                        name="value"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(event.target.value);
                        }}
                        label="Remote Config Text Value"
                        value={value}
                        fullWidth
                        required
                    />
                </section> : null
            }
            {String(remoteConfig?.type) === 'integer' ?
                <section className="my-3">
                    <TextField
                        name="value"
                        type='number'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            let eventStr = event.target.value;
                            const eventArr = eventStr.split('.');
                            setValue(eventArr[0]);
                        }}
                        label="Remote Config Integer Value"
                        value={value}
                        fullWidth
                        required
                    />
                </section> : null
            }
            {String(remoteConfig?.type) === 'float' ?
                <section className="my-3">
                    <TextField
                        name="value"
                        type='number'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(event.target.value);
                        }}
                        label="Remote Config Float Value"
                        value={value}
                        fullWidth
                        required
                    />
                </section> : null
            }
            {String(remoteConfig?.type) === 'boolean' ?
                <section className="my-3">
                    <Select
                        value={boolValue}
                        placeholder='Remote Config Boolean Value'
                        label="Remote Config Boolean Value"
                        name='type'
                        onChange={(e) => {
                            setBoolValue(e.target.value)
                        }}
                        fullWidth
                    >
                        <MenuItem value='true'>True</MenuItem>
                        <MenuItem value='false'>False</MenuItem>
                    </Select>
                </section> : null
            }

            {String(remoteConfig?.type) === 'image' ?
                <section className="my-3">
                    <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        disableElevation
                    >
                        Upload File <AddBoxIcon />
                        <input
                            type="file"
                            hidden
                            accept=".jpg,.jpeg,.png"
                            onChange={handleFileInputChange}
                        />
                    </Button>
                    {previewUrl && <section className='mt-2'>
                        <img src={previewUrl} className="w-[300px] h-[300px]" alt="Preview" />
                        <p className='text-base font-semibold text-[#e4b800]'>{fileSize}</p>
                    </section>}
                </section>
                : null
            }
            <div>
                <Button
                    className="mt-3 mx-3"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={handleUpdateRemoteConfig}
                >Save Remote Config</Button>

                <Button
                    className="mt-3 mx-3"
                    onClick={handleDismiss}
                    disableElevation
                    color='primary'
                    sx={{ fontSize: '15px' }}
                    variant="outlined">
                    Cancel
                </Button>
            </div>
        </div>
    )
}

const mapDispatch = {
    setAlert: (alert: AlertMsg) => setAlert(alert),
    updateRemoteConfig: (remoteConfig: UpdateRemoteConfigRequest, remoteConfigId: string) => updateRemoteConfig(remoteConfig, remoteConfigId)
}

const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    return { alertMsg }
}


export default connect(mapStateToProps, mapDispatch)(UpdateRemoteConfigForm)