import { Button, TextField } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react"
import { connect } from "react-redux";
import { deleteAbTest } from "../../../redux/abtests/abTestsActions";
import { setAlert } from "../../../redux/admin/adminActions";
import { AlertMsg } from "../../../redux/admin/interfaces";
import { ReduxAction, RootState } from "../../../redux/rootReducer"

type Props ={
    deleteAbTest: (abtestId: string) => ReduxAction,
    setAlert: (alert: AlertMsg) => ReduxAction,
    abTestId: string,
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>> | ((b: boolean) => ReduxAction),
}

const DELETE_MESSAGE = 'ab_test';

function DeleteAbTestForm({ 
    deleteAbTest,
    setAlert,
    abTestId,
    open,
    setOpen
}:Props){
    const [isMessageCorrect, setMessageCorrect] = useState(false);
    const [key, setKey] = useState<string>('');
    const handleDeleteAbTest = ()=> {
        deleteAbTest(abTestId)
    }
    const handleDismiss = () => {
        setOpen(false)
    }
    return(
        <div className="p-2">
            <p className="text-base mt-3 text-left">Please write <b>{DELETE_MESSAGE}</b> below to delete</p>
            <section>
                <TextField
                    className="my-3"
                    fullWidth
                    required
                    error={!key}
                    label="Delete Ab test Message"
                    value={key}
                    onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                        await setKey(event.target.value);
                        if (event.target.value === DELETE_MESSAGE)
                            setMessageCorrect(true)
                        else
                            setMessageCorrect(false);
                    }}
                />
            </section>
            <div className="">
                <Button
                    className="mt-3 mx-3"
                    onClick={handleDeleteAbTest}
                    disableElevation
                    disabled={!isMessageCorrect}
                    color='error'
                    sx={{ fontSize: '15px' }}
                    variant="outlined">
                    Delete Ab Test
                </Button>
                <Button
                    className="mt-3 mx-3"
                    onClick={handleDismiss}
                    disableElevation
                    color='primary'
                    sx={{ fontSize: '15px' }}
                    variant="contained">
                    Cancel
                </Button>
            </div>
        
        </div>
    )

}

const mapDispatch = {
    deleteAbTest: (abtestId: string) => deleteAbTest(abtestId),
    setAlert: (p: AlertMsg) => setAlert(p)
}
const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    return {
        alertMsg
    }
}

export default connect(mapStateToProps, mapDispatch)(DeleteAbTestForm)