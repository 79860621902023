import { Button, Grid, Paper } from "@mui/material";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SideNav from "../../pages/SideNav";
import { NavigationState } from "../../redux/navigation/interfaces";
import { getNavigation } from "../../redux/navigation/navigationActions";
import { ProjectsState } from "../../redux/projects/interfaces";
import { getProject } from "../../redux/projects/projectsActions";
import { ReduxAction, RootState } from "../../redux/rootReducer";

type Props = {
    getProject: (slug: string) => ReduxAction,
    getNavigation: (slug: string) => ReduxAction,
    project: ProjectsState['project'],
    navigation: NavigationState,

}

function ProjectNavigation({
    getProject,
    project,
    getNavigation,
    navigation

}: Props) {

    const dispatch = useDispatch();
    const { slug } = useParams();

    useEffect(() => {
        dispatch(getProject(String(slug)));
    }, []);
    useEffect(() => {
        dispatch(getNavigation(String(slug)));
    }, [project._id]);

    const handleCreateUpdateNavigation = () => {
        if (navigation._id) {
            // update navigation
            alert('Update Navigation');
        } else {
            // create navigation
            alert('Create Navigation');
        }
    }
    return (
        <Grid container >
            <Grid item xs={1}>
                <SideNav />
            </Grid>
            <Grid item xs={9} sx={{ height: '100%'}}>
                <div>

                    <Button
                        onClick={() => window.history.back()}
                        className="my-3"
                        disableElevation
                        variant="contained"
                    >Back
                    </Button>

                    <Paper className="p-3">

                        <Button
                            onClick={handleCreateUpdateNavigation}
                            variant='contained'
                        >
                            {navigation._id ? 'Update Navigation' : 'Create Navigation'}
                        </Button>
                    </Paper>
                </div>
            </Grid>
        </Grid>
    )
}
const mapStateToProps = (rootState: RootState) => {
    const { project } = rootState.projects;
    const { navigation } = rootState;
    return {
        project,
        navigation
    }
}

const mapDispatch = {
    getProject: (slug: string) => getProject(slug),
    getNavigation: (slug: string) => getNavigation(slug)
}
export default connect(mapStateToProps, mapDispatch)(ProjectNavigation)