import { ReactJSXElement } from "@emotion/react/types/jsx-namespace"
import { Modal } from "@mui/material";
import { Dispatch, SetStateAction } from "react"
import { connect } from "react-redux";
import { AdminState } from "../../redux/admin/interfaces";
import { ReduxAction, RootState } from "../../redux/rootReducer"
import './CustomModal.css'


type Props = {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>> | ((b: boolean) => ReduxAction),
    children: ReactJSXElement,
    theme: AdminState["theme"]
}

function CustomModal({
    open,
    setOpen,
    children,
    theme
}: Props) {
    return (
        <Modal
            // disablePortal={true}
            // disableEnforceFocus={true}
            // disableAutoFocus={true}
            open={open}
            onClose={() => setOpen(false)}>
            <div className='modal' style={{ backgroundColor: theme === 'dark' ? '#1e1e1e' : 'white' }}>
                {children}
            </div>
        </Modal>
    )
}

const mapStateToProps = (rootState: RootState) => {
    const { theme } = rootState.admin;
    return {
        theme
    }
}


export default connect(mapStateToProps)(CustomModal);