import { Box, Button, Paper, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { DesignGroup } from "../../redux/abtests/interfaces";

type Props = {
    designGroupsResults: DesignGroup[]
}
type Data = {
    name: string;
    score: number;
}

function BarChartArea({ designGroupsResults }: Props) {
    const [data, setData] = useState<Data[]>([]);
    const [hasDesigns, setHasDesigns] = useState(false);
    const [bestScore, setBestScore] = useState(-1);
    const [value, setValue] = useState(0);
    useEffect(() => {
        if (designGroupsResults[0]
            && designGroupsResults[0].designs
            && designGroupsResults[0].designs.length > 0) {
            setHasDesigns(true);
            createBarChartData(0);
        };

    }, []);
    // const handleBarChartClick = (i: number) => {
    //     createBarChartData(i);
    // }


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        createBarChartData(newValue);
    }

    const createBarChartData = (i: number) => {
        setBestScore(-1.0);
        const datas: Data[] = []

        designGroupsResults[i].designs.forEach(design => {

            const data: Data = {
                name: design.name,
                score: parseFloat(design.ratio),
            }
            datas.push(data);
        });
        let newBestScore = -Infinity
        datas.forEach((data, i) => {
            if (data.score > newBestScore) {
                newBestScore = data.score;
                setBestScore(data.score);
            }
        });
        setData(datas);
    }

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {hasDesigns ?
                <Tabs
                    sx={{
                        marginLeft: '70px !important'
                    }}
                    value={value}
                    onChange={handleChange}
                >
                    {designGroupsResults.map((design, i) => {
                        return <Tab
                            key={i}
                            label={design.designGroup.name} value={i} />
                    })}
                </Tabs>
                : null}


            {hasDesigns ?
                <ResponsiveContainer
                    key='ResponsiveContainer'
                    width="100%" height={490}>
                    <BarChart
                        key='BarChart'
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        maxBarSize={100}
                    >
                        <CartesianGrid
                            key='CartesianGrid'
                            strokeDasharray="3 3" />
                        <XAxis
                            key='XAxis'
                            dataKey="name" />
                        <YAxis
                            key='YAxis'
                        />
                        <Tooltip
                            key='Tooltip'
                        />
                        <Legend
                            key='Legend'
                            wrapperStyle={{ bottom: '15px' }} />
                        <Bar
                            key='Bar'
                            dataKey="score" fill="#8884d8">
                            {
                                data.map((d, idx) => {
                                    let bestColor = "#5eba7d";
                                    let otherColor = "#8884d8";
                                    if (d.score === bestScore) {
                                        return <Cell key={`Cell_${idx}`} fill={bestColor} />
                                    } else {
                                        return <Cell key={`Cell_${idx}`} fill={otherColor} />
                                    }

                                })
                            }
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>

                : null}

        </Box >
    )

}
export default BarChartArea