import { ACTIONS, CreateProjectRequest, ProjectGridRow, ProjectInterface, UpdateProjectRequest } from "./interfaces";

// DATABASE CRUD ACTION 
export function getAllProjects(page:number,limit:number,sortBy: string,dir: string) {
    return {
        type: ACTIONS.GET_ALL_PROJECTS_IN_DB,
        payload: {page,limit, sortBy, dir}
    }
}
export function getProject(slug: string) {
    return {
        type: ACTIONS.GET_PROJECT_IN_DB,
        payload: slug
    }
}


export function createProject(p: CreateProjectRequest) {
    return {
        type: ACTIONS.CREATE_PROJECT_IN_DB,
        payload: p
    }
}

export function updateProject(body: UpdateProjectRequest, projectId: string) {
    return {
        type: ACTIONS.UPDATE_PROJECT_IN_DB,
        payload: { body, projectId }
    }
}

export function deleteProject(projectId: string){
    return{
        type: ACTIONS.DELETE_PROJECT_IN_DB,
        payload: {
            projectId
        }
    }
}





// SAGA ACTIONS 
export function setProjects(projects: ProjectInterface[], gridRows: ProjectGridRow[], length: number, total: number) {
    return {
        type: ACTIONS.SET_PROJECTS,
        payload: { projects, gridRows, length, total, }
    }
}

export function setProject(project: ProjectInterface) {
    return {
        type: ACTIONS.SET_PROJECT,
        payload: { project }
    }
}

