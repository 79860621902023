import { GetDesignEventPropertiesRequest } from "../abtests/interfaces";
import {
  ACTIONS,
  AssignWinnersToRemoteConfigRequest,
  QueryResult,
  SaveQueryRequest,
  StartAbTestRequest,
  StopAbTestRequest,
  ValueCount,
} from "./interfaces";

export function startAbTest(startAbTestRequest: StartAbTestRequest) {
  return {
    type: ACTIONS.START_AB_TEST_IN_DB,
    payload: startAbTestRequest,
  };
}

export function stopAbTest(StopAbTestRequest: StopAbTestRequest) {
  return {
    type: ACTIONS.END_AB_TEST_IN_DB,
    payload: StopAbTestRequest,
  };
}

export function assignWinnersToRemoteConfig(
  assignWinnersToRemoteConfigRequest: AssignWinnersToRemoteConfigRequest
) {
  return {
    type: ACTIONS.ASSIGN_WINNER_TO_REMOTE_CONFIG_IN_DB,
    payload: assignWinnersToRemoteConfigRequest,
  };
}

export function getEventPropertiesValues() {
  return {
    type: ACTIONS.GET_EVENT_PROPERTIES_VALUES_IN_DB,
  };
}

export function getDesignEventPropertiesValues(
  getDesignEventPropertiesRequest: GetDesignEventPropertiesRequest
) {
  return {
    type: ACTIONS.GET_DESIGN_EVENT_PROPERTIES_VALUES_IN_DB,
    payload: getDesignEventPropertiesRequest,
  };
}

export function getParametersByEvent(event: string) {
  return {
    type: ACTIONS.GET_PARAMETERS_BY_EVENT,
    payload: event,
  };
}

export function getValueCounts(event: string, parameter: string) {
  return {
    type: ACTIONS.GET_VALUE_COUNTS,
    payload: { event, parameter },
  };
}

export function saveQueryRequest(saveQueryRequest: SaveQueryRequest) {
  return {
    type: ACTIONS.SAVE_QUERY_REQUEST,
    payload: saveQueryRequest,
  };
}

export function getAllQueriesResult() {
  return {
    type: ACTIONS.GET_ALL_QUERIES,
  };
}

// SAGA ACTIONS

export function setEventProperties(eventProperties: string[]) {
  return {
    type: ACTIONS.SET_EVENT_PROPERTIES_VALUES,
    payload: { eventProperties },
  };
}

export function setParameters(parameters: string[]) {
  return {
    type: ACTIONS.SET_PARAMETERS_BY_EVENT,
    payload: { parameters },
  };
}

export function setValueCounts(valuesCounts: ValueCount[]) {
  return {
    type: ACTIONS.SET_VALUE_COUNTS,
    payload: { valuesCounts },
  };
}

export function setAllQueriesResult(queriesResult: QueryResult[]) {
  return {
    type: ACTIONS.SET_ALL_QUERIES,
    payload: { queriesResult },
  };
}
