import { DesignGroup } from "../../redux/abtests/interfaces";
import { Column } from "../../reusables/GridTable";

export const abTestColumns: Column[] = [
  { id: 'name', label: 'Name', minWidth: 100 },
  { id: 'createdAt', label: 'Creation Time', minWidth: 100 },
  { id: 'designs', label: '# of designs', minWidth: 100 },
  { id: 'startDate', label: 'Start Date', minWidth: 100 },
  { id: 'endDate', label: 'End Date', minWidth: 100 },
  { id: 'comments', label: 'Comments', minWidth: 100 },

];



export const getDesignNames = (designGroups: DesignGroup[]): string[] => {
  const result: string[] = [];
  designGroups.forEach(element => {
    result.push(element.designGroup.name);
  });
  return result;
}


export const getDesignGroupIds = (designGroups: DesignGroup[]): string[] => {
  const result: string[] = [];
  designGroups.forEach(element => {
    result.push(element.designGroup._id);
  });
  return result;
}
