import { enqueueSnackbar } from "notistack";
import { ACTIONS, AlertMsg } from "./interfaces";

export function setAlert(alert: AlertMsg) {
  return {
    type: ACTIONS.SET_ALERT,
    payload: alert,
  };
}

export function toggleTheme(theme: string) {
  return {
    type: ACTIONS.TOGGLE_THEME,
    payload: theme,
  };
}

//SAGA ACTIONS
export function setToggleTheme(theme: string) {
  return {
    type: ACTIONS.SET_TOGGLE_THEME,
    payload: theme,
  };
}

export function setAlertSaga(alert: AlertMsg){
  return {
    type: ACTIONS.SET_ALERT_SAGA,
    payload: enqueueSnackbar(alert.msg,alert.config)
  }
}