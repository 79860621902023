// DABASE CRUD ACTION

import { ACTIONS, NavigationInterface } from "./interfaces"

export function getNavigation(projectSlug: string) {
    return {
        type: ACTIONS.GET_NAVIGATION_IN_DB,
        payload: projectSlug
    }
}




// SAGA ACTIONS

export function setNavigation(navigationInterface: NavigationInterface){
    return {
        type: ACTIONS.SET_NAVIGATION,
        payload: { navigationInterface }
    }
}