import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Card } from "@mui/material";

function GetSavedQueries() {
    return (
        <Card onClick={() => {
            window.location.replace('/home/projectdata/savedqueries');
        }} className="p-3 m-3  h-[180px] w-[350px] cursor-pointer
        justify-center flex flex-col  items-center">
                <p className="text-base font-bold capitalize">
                    Get Saved Queries <ArrowForwardIcon/>
                </p>
        </Card>
    )
}
export default GetSavedQueries