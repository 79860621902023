import { Autocomplete, TextField } from "@mui/material";
import { RemoteConfigInterface, RemoteConfigsState } from "../../redux/remoteConfigs/interfaces";
type Props = {
    passOverrideToParent: (idx: number, remoteConfig: string) => void,
    idx: number,
    designName: string,
    keyx: string,
    remoteConfigs: RemoteConfigInterface[],
    configEditor: string
    testStarted: boolean
}
function ConfigEditorAutocomplete({
    passOverrideToParent,
    idx,
    keyx,
    remoteConfigs,
    designName,
    configEditor,
    testStarted
}: Props) {
    return (
        <Autocomplete
            key={keyx}
            size='small'
            className="mx-1"
            disabled={testStarted}
            disablePortal
            
            options={remoteConfigs.map(r => r.slug)}
            value={configEditor}
            sx={{ width: 200 }}
            onChange={(event, newValue) => {
                passOverrideToParent(idx,newValue as string);
            }}
            // value={configEditor? configEditor: ''}
            renderInput={(params) => <TextField {...params} label={designName} />}
        />
    )
}

export default ConfigEditorAutocomplete