import { Delete } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, TableSortLabel } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import StopAbTestAndAssignWinnerForm from '../components/AbTests/modals/StopAbTestAndAssignWinnerForm';
import DeleteRemoteConfigForm from '../components/RemoteConfigs/DeleteRemoteConfigForm';
import UpdateRemoteConfigForm from '../components/RemoteConfigs/UpdateRemoteConfigForm';
import { AbTestGridRow } from '../redux/abtests/interfaces';
import { ProjectGridRow } from '../redux/projects/interfaces';
import { RemoteConfigGridRow } from '../redux/remoteConfigs/interfaces';
import CustomModal from './CustomModal/CustomModal';
export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left';
  format?: (value: number) => string;
}

export interface Pagination {
  page: number;
  rowsPerPage: number,
  dir?: string,
  sortBy?: string
}



type Props = {
  columns: Column[],
  rows: ProjectGridRow[] | RemoteConfigGridRow[] | AbTestGridRow[],
  usedFor: string,
  total: number,
  length: number
  handlePagination: (pagination: Pagination) => void
}


type Direction = "desc" | "asc" | undefined;
function GridTable({ columns,
  rows,
  total,
  length,
  usedFor,
  handlePagination
}:
  Props) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [paginationRow, setPaginationRow] = useState(50);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState<Direction>('desc');

  // DELETE REMOTE CONFIG
  const [deleteRemoteConfigFormOpen, setDeleteRemoteConfigFormOpen] = useState(false);
  const [openStop, setOpenStop] = useState(false);
  const [abTestId, setAbTestId] = useState('');
  const [id, setId] = useState<string>('');
  const [abTestSlug, setAbTestSlug] = useState('');

  // UPDATE REMOTE CONFIG
  const [updateRemoteConfigModalOpen, setUpdateRemoteConfigModalOpen] = useState(false);
  const [remoteConfig, setRemoteConfig] = useState<RemoteConfigGridRow | null>(null);


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    const pagination: Pagination = {
      page: newPage + 1,
      rowsPerPage: rowsPerPage
    }
    handlePagination(pagination)
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Retrieve the new value of rows per page from the event target
    let updatedRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(updatedRowsPerPage);
    setPage(0);

    // Update the state with the new value
    setRowsPerPage(updatedRowsPerPage);
    const pagination: Pagination = {
      page: 1,
      rowsPerPage: updatedRowsPerPage
    }
    handlePagination(pagination);
  };

  const handleTableCellClick = (row: ProjectGridRow | RemoteConfigGridRow | AbTestGridRow) => {
    if (usedFor === 'projects') {
      const projectGridRow = row as ProjectGridRow;
      navigate(`/home/projects/${projectGridRow.slug}`);
    } else if (usedFor === 'remoteConfigs') {
      // const remoteConfigRow = row as RemoteConfigGridRow
      // setRemoteConfig(remoteConfigRow);
      // setViewRemoteConfigModalOpen(true);
    } else if (usedFor === 'abTests') {
      const abTestRow = row as AbTestGridRow
      navigate(`abtests/${abTestRow.slug}`);
    }
  }

  const openDeleteRemoteForm = ((id: string) => {
    setId(id)
    setDeleteRemoteConfigFormOpen(true);
  })
  const openStopAbRunningAbTest = (row: AbTestGridRow) => {
    setAbTestId(row._id);
    setAbTestSlug(row.slug);
    setId(row.project)
    setOpenStop(true);
  }

  const checkIfItemIsDIsabled = (row: AbTestGridRow) => {
    if (row.integrationId) {
      return false
    }
    return true;
  }

  const checkRemoteConfigDisabled = (row: RemoteConfigGridRow) => {
    return row.disabled;
  }

  const openUpdateRemoteForm = (row: RemoteConfigGridRow) => {
    if (usedFor === 'remoteConfigs') {
      const remoteConfigRow = row as RemoteConfigGridRow
      setRemoteConfig(remoteConfigRow);
      setUpdateRemoteConfigModalOpen(true);
    }
  }

  const handleSorting = (sortBy: string) => {
    if (order === 'asc') {
      setOrder('desc')
    } else {
      setOrder('asc')
    }

    setPage(0);

    const pagination: Pagination = {
      page: 1,
      rowsPerPage,
      dir: order,
      sortBy
    }
    handlePagination(pagination);

  }

  return (
    <div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }} elevation={0}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      // direction={orderBy === column.id ? order : 'asc'}
                      direction={order}
                      onClick={() => handleSorting(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {usedFor === 'remoteConfigs' ?

                  <TableCell
                    key={'_id_update'}
                    align={'left'}
                    style={{ minWidth: 170 }}
                  >
                    Update
                  </TableCell>
                  : null}
                {usedFor === 'remoteConfigs' ?

                  <TableCell
                    key={'_id_delete'}
                    align={'left'}
                    style={{ minWidth: 170 }}
                  >
                    Delete
                  </TableCell>
                  : null}


                {usedFor === 'abTests' ?
                  <TableCell
                    key={'_id_stop'}
                    align={'left'}
                    style={{ minWidth: 50 }}
                  >
                    Stop Test
                  </TableCell>
                  : null}

              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                // .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      className={(row as RemoteConfigGridRow).disabled ? 'bg-gray-200' : ''}
                      hover tabIndex={-1} key={row._id}>
                      {columns.map((column) => {
                        const anyRow = row as any
                        const value = anyRow[column.id];
                        return (
                          usedFor !== 'remoteConfigs' ?
                            <TableCell
                              onClick={() => handleTableCellClick(row)}
                              key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                            :
                            column.id === 'value' && anyRow.type === 'image' ?
                              <TableCell
                                color={((row as RemoteConfigGridRow).disabled)? "gray" : undefined}
                                key={column.id} align={column.align}>
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : JSON.stringify({ key: anyRow['key'], link: value })}
                              </TableCell> :
                              <TableCell
                                onClick={()=>handleTableCellClick(row)}
                                key={column.id} align={column.align}>
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                        );
                      })}

                      {usedFor === 'remoteConfigs' ?
                        <TableCell
                          key={`${row._id}_update`}
                          align={'left'}
                          style={{ minWidth: 170 }}
                        >
                          <IconButton
                            disabled={checkRemoteConfigDisabled(row as RemoteConfigGridRow)}
                            onClick={() => {
                              openUpdateRemoteForm(row as RemoteConfigGridRow)
                            }}
                            color='primary'>
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                        :
                        null}
                      {usedFor === 'remoteConfigs' ?
                        <TableCell
                          key={`${row._id}_delete`}
                          align={'left'}
                          style={{ minWidth: 170 }}
                        >
                          <IconButton
                            disabled={checkRemoteConfigDisabled(row as RemoteConfigGridRow)}
                            onClick={() => {
                              openDeleteRemoteForm(row._id)
                            }}
                            color='error'>
                            <Delete />
                          </IconButton>
                        </TableCell>
                        :
                        null}

                      {usedFor === "abTests" ?
                        <TableCell
                          key={`${row._id}_stop`}
                          align={'left'}
                          style={{ minWidth: 50 }}
                        >
                          <IconButton
                            disabled={checkIfItemIsDIsabled(row as AbTestGridRow)}
                            onClick={() => {
                              openStopAbRunningAbTest(row as AbTestGridRow)
                            }}
                            color='error'>
                            <CloseIcon />
                          </IconButton>
                        </TableCell>
                        : null}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>


      <CustomModal open={deleteRemoteConfigFormOpen} setOpen={setDeleteRemoteConfigFormOpen}>
        <DeleteRemoteConfigForm
          open={deleteRemoteConfigFormOpen} setOpen={setDeleteRemoteConfigFormOpen}
          remoteConfig={id}
        />
      </CustomModal>


      <CustomModal open={updateRemoteConfigModalOpen} setOpen={setUpdateRemoteConfigModalOpen}>
        <UpdateRemoteConfigForm open={updateRemoteConfigModalOpen} setOpen={setUpdateRemoteConfigModalOpen}
          remoteConfig={remoteConfig}
        />
      </CustomModal>

      <CustomModal open={openStop} setOpen={setOpenStop}>

        <StopAbTestAndAssignWinnerForm
          projectId={id}
          abTestSlug={abTestSlug}
          abTestId={abTestId}
          open={openStop}
          setOpen={setOpenStop}
        // designGroupsResults={designGroupsResults}
        />
      </CustomModal>

    </div>

  );

}


export default GridTable;

