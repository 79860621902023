
// MUI IMPORTS
import BarChartIcon from '@mui/icons-material/BarChart';
import BuildIcon from '@mui/icons-material/Build';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import LightModeIcon from '@mui/icons-material/LightMode';
import { ListItemIcon } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import axios from 'axios';
import { setAlert, toggleTheme } from '../redux/admin/adminActions';
import { ReduxAction, RootState } from '../redux/rootReducer';
import { connect, useDispatch } from 'react-redux';
import { useState } from 'react';
import { AdminState, AlertMsg } from '../redux/admin/interfaces';
import { ERR_SNACKBAR, WARN_SNACKBAR } from '../redux/helpers/constants';

type Props = {
    theme: string,
    toggleTheme: (theme: string) => ReduxAction,
    setAlert:(alert: AlertMsg) => ReduxAction,
    alertMsg: AdminState['alertMsg']
}


function SideNav({
    theme,
    toggleTheme,
    setAlert,
    alertMsg
}: Props) {
    const dispatch = useDispatch();
    const handleLogout = async () => {
        const url = '/api/v1/auth/logout';
        const res = await axios.get(url);

        if (!res) setAlert({
            msg: 'NO result returned from API',
            config: ERR_SNACKBAR
        });

        const { status, message, token } = res.data;
        if (status !== 200) alert(message);
        else {
            localStorage.setItem('token', token);
            window.location.assign('/login');
        }
    }
    const handleChangeTheme = async () => {
        dispatch(toggleTheme(theme));
    }

    const drawerWidth = 240;
    return (
        <Drawer
            variant="permanent"
            sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            anchor="left"
            open={true}
        >
            <List>
                <ListItem key={'Home'} disablePadding>
                    <ListItemButton
                        onClick={() => {
                            window.location.replace('/home/');
                        }}
                    >
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Home'} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'Projects'} disablePadding>
                    <ListItemButton
                        onClick={() => {
                            window.location.replace('/home/projects');
                        }}
                    >
                        <ListItemIcon>
                            <BuildIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Projects'} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'Project Data'} disablePadding>
                    <ListItemButton
                    
                    onClick={() => {
                        window.location.replace('/home/projectdata');
                    }}
                    >
                        <ListItemIcon>
                            <BarChartIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Project Data'} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'Sign Out'} disablePadding>
                    <ListItemButton
                        onClick={handleLogout}
                    >
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Sign Out'} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'Toggle Theme'} disablePadding>
                    <ListItemButton
                        onClick={handleChangeTheme}>
                        <ListItemIcon>
                            {theme === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
                        </ListItemIcon>
                        <ListItemText primary={'Toggle Theme'} />
                    </ListItemButton>
                </ListItem>
            </List>

        </Drawer>
    )
}

const mapStateToProps = (rootState: RootState) => {
    const { theme, alertMsg } = rootState.admin;
    return {
        theme,
        alertMsg
    }
}

const mapDispatch = {
    toggleTheme: (theme: string) => toggleTheme(theme),
    setAlert: (alert: AlertMsg) => setAlert(alert),

}

export default connect(mapStateToProps, mapDispatch)(SideNav);

