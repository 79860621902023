import { ReduxAction } from "../rootReducer";
import {
  ACTIONS,
  RemoteConfigsState,
  REMOTE_CONFIG_INITIAL_STATE,
} from "./interfaces";

export const remoteConfigsReducer = (
  state: RemoteConfigsState = REMOTE_CONFIG_INITIAL_STATE,
  action: ReduxAction
): RemoteConfigsState => {
  switch (action.type) {
    // SAGA ACTIONS ------------------------------------------------------------------------
    case ACTIONS.SET_REMOTE_CONFIGS:
      return {
        ...state,
        remoteConfigs: action.payload.remoteConfigs,
        gridRows: action.payload.gridRows,
        length: action.payload.length,
        total: action.payload.total,
      };
    default:
      return { ...state };
  }
};
