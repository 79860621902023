import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { Button } from '@mui/material';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const notistackRef = React.createRef<SnackbarProvider>();
const handleDismiss = (key: SnackbarKey) => () => {
  if (notistackRef.current) {
    notistackRef.current.closeSnackbar(key);
  }
}
const dismissStyles = {
  color: 'white',
  backgroundColor: "#9d9d9d",
  "&:hover": {
    backgroundColor: "#545454"
  }
}




root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <SnackbarProvider
          maxSnack={10}
          ref={notistackRef}
          action={(key) => (
            <Button sx={dismissStyles} disableElevation variant="contained" size="small" onClick={handleDismiss(key)}>
              Dismiss
            </Button>
          )}
        >
          <App />
        </SnackbarProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

