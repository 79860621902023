import { OptionsObject } from "notistack";
export const SUCCESS_SNACKBAR: OptionsObject = {
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    variant: 'success',
    autoHideDuration: 5000
}

export const ERR_SNACKBAR: OptionsObject = {
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    variant: 'error',
    autoHideDuration: null
}


export const WARN_SNACKBAR: OptionsObject = {
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    variant: 'warning',
    autoHideDuration: null
}