import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from "redux-persist/es/stateReconciler/hardSet";


// REDUCERS
import { projectsReducer } from "./projects/projectsReducers";
import { adminReducer } from "./admin/adminReducers";
import { remoteConfigsReducer } from "./remoteConfigs/remoteConfigsReducer";
import { navigationConfigsReducer } from "./navigation/navigationReducers";
import { abTestsReducer } from "./abtests/abTestsReducers";
import { configEditorReducer } from "./configEditor/configEditorReducers";

const persistConfig = {
    key: 'admin',
    storage,
    stateReconciler: hardSet
}

/*
    admin: handles administrative state such as sesionId, theme, etc
    initAbTest: this reducer handles the create ab test form, and adding design groups to an ab test
    projects: contains all the projects and grid data
    nav: stores the current ab test or project based on user navigation
    externalApis: {
        ourApiReducer: contains dynamic design group data (properties, etc.), from our api, the /techlabfze route
        unityApiReducer: contains dynamic data from unity api (unity campaign data, config keys, etc.)
    }
*/

export const rootReducer = combineReducers({
    admin: persistReducer(persistConfig, adminReducer), // we only want to persist the admin reducer
    // initAbTest: initAbTestReducer,
    projects: projectsReducer,
    abTests: abTestsReducer,
    // nav: navReducer,
    remoteConfig: remoteConfigsReducer,
    navigation: navigationConfigsReducer,
    configEditor: configEditorReducer
});

export type RootState = ReturnType<typeof rootReducer>
export type ReduxAction = {
    type: string,
    payload?: any
}