import axios, { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { setAlert } from "../admin/adminActions";
import { ERR_SNACKBAR, SUCCESS_SNACKBAR } from "../helpers/constants";
import { ACTIONS, NavigationInterface } from "./interfaces";
import { getNavigation, setNavigation } from "./navigationActions";


// GET NAVIGATION

type GetNavigationResponse = {
    status: number,
    message: string,
    data: NavigationInterface
}
type GetNavigationInDbAction = ReturnType<typeof getNavigation>;
function* handleGetNavigation({ payload }: GetNavigationInDbAction) {
    try {
        const slug = payload;
        const url = `/api/v1/navigations/projects/${slug}`;
        const res: AxiosResponse<GetNavigationResponse> = yield call(axios.get, url);
        if (!res) throw new Error('NO RESULT RETURNED FROM API');
        const { status, message, data } = res.data;
        if (status !== 200) throw new Error(message);
        yield put(setNavigation(data));

    } catch (err: any) {
        console.log('err', err);
        yield put(setAlert({
            msg: err.response.data.message,
            config: ERR_SNACKBAR
        }));
    }
}
function* interceptGetNavigation() {
    yield takeLatest([ACTIONS.GET_NAVIGATION_IN_DB], handleGetNavigation)
}




export function* navigationSagas(){
    yield all([
        call(interceptGetNavigation),
    ])
}
