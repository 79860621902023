import { Button, Dialog, DialogContent } from "@mui/material";
import { useState } from "react";
import { DesignGroup } from "../../redux/abtests/interfaces";
import CreateDesignGroupForm from "./modals/CreateDesignGroupForm";
import SingleDesignGroup from './SingleDesignGroup';

type Props = {
    abtestId: string
    designGroupsResults: DesignGroup[],
    testStarted: boolean
}


function Designs({ 
    abtestId, 
    designGroupsResults,
    testStarted
}: Props) {
    const [open, setOpen] = useState(false);



    return (
        <div>
            <div className="p-3 my-3">
                <div className="flex flex-grow flex-row justify-between">
                    <h3 className="text-base font-bold text-start">Tests</h3>
                    <Button
                        variant="contained"
                        color='primary'
                        disabled={testStarted}
                        disableElevation
                        onClick={() => setOpen(true)}
                    >
                        Add Test Group
                    </Button>
                </div>
                <hr className="font-bold mt-1 mb-5" />

                {designGroupsResults.map((designGroup, i) => {
                    return <SingleDesignGroup 
                    key={i} 
                    idx={i} 
                    testStarted={testStarted}
                    designGroup={designGroup.designGroup} 
                    designs={designGroup.designs} 
                    />
                }
                )}


            </div>

            <Dialog
                fullWidth={true}
                maxWidth={'xl'}
                open={open}
                onClose={() => { setOpen(false) }}
            >
                <DialogContent>
                    <CreateDesignGroupForm
                        abtestId={abtestId}
                        open={open}
                        setOpen={setOpen} />
                </DialogContent>
            </Dialog>




            

        </div>
    )
}
export default Designs