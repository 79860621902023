import { Box, Grid, List, ListItemButton, ListItemText, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import SideNav from "../../pages/SideNav";
import { getAllQueriesResult } from "../../redux/configEditor/configEditorActions";
import { ConfigEditorState } from "../../redux/configEditor/interfaces";
import { ReduxAction, RootState } from "../../redux/rootReducer";
import PageTitle from "../../reusables/PageTitle";

type Props = {
    getAllQueriesResult: () => ReduxAction,
    queryResult: ConfigEditorState['queryResult']
}
type Data = {
    name: string;
    score: number;
}
function SavedQueries({
    getAllQueriesResult,
    queryResult
}: Props) {
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [data, setData] = useState<Data[]>([]);
    const [bestScore, setBestScore] = useState(-1.0);
    useEffect(() => {
        getAllQueriesResult();
    }, []);

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        handleValueCount(index);
    };


    const handleValueCount = (i: number) => {
        setBestScore(-1.0);
        if (queryResult[i].valuesCounts.length > 0) {
            const datas: Data[] = [];
            queryResult[i].valuesCounts.forEach(valuesCount => {
                const data: Data = {
                    name: valuesCount.name,
                    score: valuesCount.clicks,
                }
                datas.push(data);
            });
            let newBestScore = -Infinity
            datas.forEach((data, i) => {
                if (data.score > newBestScore) {
                    newBestScore = data.score;
                    setBestScore(data.score);
                }
            });
            setData(datas);
        }
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <SideNav />
            </Grid>
            <Grid item xs={9} sx={{ height: '100%' }}>
                <div className="h-screen max-h-screen">
                    <div className="container flex flex-col ">
                        <PageTitle title='Project Data' />
                        <div className="flex flex-row">
                            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <List component="nav">
                                    {queryResult ? queryResult.map((query, i) => {
                                        return <ListItemButton
                                            selected={selectedIndex === i}
                                            key={i}
                                            onClick={(event) => handleListItemClick(event, 0)}

                                        >
                                            <ListItemText primary={query.name} />
                                        </ListItemButton>
                                    }) : null}
                                </List>
                            </Box>
                            <Paper className='my-3 mx-2 p-3 w-full h-full'>
                                <PageTitle title='Score Results' />
                                <ResponsiveContainer
                                    key='ResponsiveContainer'
                                    width="100%" height={490}
                                >
                                    <BarChart
                                        key='BarChart'
                                        data={data}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                        maxBarSize={100}
                                    >
                                        <CartesianGrid
                                            key='CartesianGrid'
                                            strokeDasharray="3 3" />
                                        <XAxis
                                            key='XAxis'
                                            dataKey="name" />
                                        <YAxis
                                            key='YAxis'
                                        />
                                        <Tooltip
                                            key='Tooltip'
                                        />
                                        <Legend
                                            key='Legend'
                                            wrapperStyle={{ bottom: '15px' }} />
                                        <Bar
                                            key='Bar'
                                            dataKey="score" fill="#8884d8">
                                            {
                                                data.map((d, idx) => {
                                                    console.log(d);
                                                    let bestColor = "#5eba7d";
                                                    let otherColor = "#8884d8";
                                                    if (d.score === bestScore) {
                                                        return <Cell key={`Cell_${idx}`} fill={bestColor} />
                                                    } else {
                                                        return <Cell key={`Cell_${idx}`} fill={otherColor} />
                                                    }

                                                })
                                            }
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Paper>
                        </div>
                    </div>
                </div>


            </Grid>
        </Grid>
    )
}



const mapStateToProps = (rootState: RootState) => {
    const { queryResult } = rootState.configEditor;
    return {
        queryResult
    }
}

const mapDispatch = {
    getAllQueriesResult: () => getAllQueriesResult()
}

export default connect(mapStateToProps, mapDispatch)(SavedQueries)