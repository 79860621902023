import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../redux/admin/adminActions";
import { AlertMsg } from "../../redux/admin/interfaces";
import { deleteRemoteConfig } from "../../redux/remoteConfigs/remoteConfigsActions";
import { ReduxAction, RootState } from "../../redux/rootReducer";

type Props = {
    deleteRemoteConfig: (remoteConfigId: string) => ReduxAction,
    setAlert: (alert: AlertMsg) => ReduxAction,
    remoteConfig: string,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const DELETE_MESSAGE = 'remote_config';

function DeleteRemoteConfigForm(
    {
        deleteRemoteConfig,
        setAlert,
        remoteConfig,
        setOpen
    }: Props) {
    const [isMessageCorrect, setMessageCorrect] = useState(false);
    const [key, setKey] = useState<string>('');
    const handleDeleteRemoteConfig = () => {
        // TODO create alert action
        deleteRemoteConfig(remoteConfig);
    }
    const handleDismiss = () => {
        setOpen(false)
    }
    return (
        <div className=" p-2">

            <p className="text-base mt-3 text-left">Please write <b>remote_config</b> below to delete</p>
            <section>
                <TextField
                    className="my-3"
                    fullWidth
                    required
                    error={!key}
                    label="Delete Remote Config Message"
                    value={key}
                    onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                        await setKey(event.target.value);
                        console.log(DELETE_MESSAGE);
                        if (event.target.value === DELETE_MESSAGE)
                            setMessageCorrect(true)
                        else
                            setMessageCorrect(false);
                    }}
                />
            </section>
            <div className="">
                <Button
                    className="mt-3 mx-3"
                    disabled={!isMessageCorrect}
                    onClick={handleDeleteRemoteConfig}
                    disableElevation
                    color='error'
                    sx={{ fontSize: '15px' }}
                    variant="outlined">
                    Delete  Remote Config
                </Button>
                <Button
                    className="mt-3 mx-3"
                    onClick={handleDismiss}
                    disableElevation
                    color='primary'
                    sx={{ fontSize: '15px' }}
                    variant="contained">
                    Cancel
                </Button>
            </div>
        </div>
    )
}
const mapDispatch = {
    deleteRemoteConfig: (remoteConfigId: string) => deleteRemoteConfig(remoteConfigId),
    setAlert: (p: AlertMsg) => setAlert(p)
}
const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    return {
        alertMsg
    }
}


export default connect(mapStateToProps, mapDispatch)(DeleteRemoteConfigForm)
