import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import SideNav from '../../pages/SideNav';
import { ProjectsState } from "../../redux/projects/interfaces";
import { getAllProjects } from "../../redux/projects/projectsActions";
import { ReduxAction, RootState } from "../../redux/rootReducer";
import CustomModal from "../../reusables/CustomModal/CustomModal";
import GridTable, { Pagination } from "../../reusables/GridTable";
import PageTitle from "../../reusables/PageTitle";
import CreateProjectForm from "./CreateProjectForm";
import { projectColumns } from './helpers';

// alert
import BreadCrumb from '../BreadCrumb/BreadCrumb';

type Props = {
    gridRows: ProjectsState["gridRows"],
    total: ProjectsState["total"],
    length: ProjectsState["length"],
    getAllProjects: (page: number, limit: number, sortBy: string, dir: string) => ReduxAction,
}


function Projects({
    gridRows,
    total,
    length,
}: Props) {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);

    const [open, setOpen] = useState(false);
    useEffect(() => {
        dispatch(getAllProjects(page, limit, '', ''));
    }, []);
    useEffect(() => {
        return () => {
            setOpen(false);
        }
    }, []);

    const handlePagination = (pagination: Pagination) => {
        const dir = pagination.dir || '';
        const sortBy = pagination.sortBy || '';
        dispatch(getAllProjects(pagination.page, pagination.rowsPerPage, sortBy, dir));
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <SideNav />
            </Grid>
            <Grid item xs={9} sx={{ height: '100%' }}>
                <div className="container flex flex-col h-screen max-h-screen">
                    <BreadCrumb />
                    <PageTitle title="Projects" />
                    <GridTable
                        columns={projectColumns}
                        rows={gridRows}
                        total={total}
                        length={length}
                        usedFor="projects"
                        handlePagination={handlePagination}
                    />
                    <div className="mt-3 p-3">
                        <Button
                            disableElevation
                            onClick={() => setOpen(true)}
                            variant="contained">Create Project</Button>
                    </div>

                    <CustomModal open={open} setOpen={setOpen}>
                        <CreateProjectForm open={open} setOpen={setOpen} />
                    </CustomModal>

                </div>
            </Grid>
        </Grid>

    )
}

const mapStateToProps = (rootState: RootState) => {
    const gridRows = rootState.projects.gridRows;
    const total = rootState.projects.total;
    const length = rootState.projects.length;
    const { alertMsg } = rootState.admin
    return {
        gridRows,
        total,
        length,
        alertMsg
    }
}

const mapDispatch = {
    getAllProjects: (page: number, limit: number, sortBy: string, dir: string) => getAllProjects(page, limit, sortBy, dir),
}

export default connect(mapStateToProps, mapDispatch)(Projects);