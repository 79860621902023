import { all, call, put, takeLatest } from "redux-saga/effects";
import { setAlert, setAlertSaga, setToggleTheme, toggleTheme } from "./adminActions";
import { ACTIONS } from "./interfaces";






type SetAlertAction = ReturnType<typeof setAlert>;
function* handleSetAlert({payload}:SetAlertAction){

  yield put(setAlertSaga(payload));
}

function* interceptSetAlert(){
  yield takeLatest([ACTIONS.SET_ALERT],handleSetAlert);
}




type ToggleThemection = ReturnType<typeof toggleTheme>;
function* handleToggleTheme({ payload }: ToggleThemection) {
  try {
    console.log("payload", payload);
    const themeUpdated = payload === "dark" ? "light" : "dark";
    yield put(setToggleTheme(themeUpdated));
  } catch (err: any) {
    console.log(err);
  }
}
function* interceptToggleTheme() {
  yield takeLatest([ACTIONS.TOGGLE_THEME], handleToggleTheme);
}

export function* adminSagas() {
  yield all([
    call(interceptToggleTheme),
    call(interceptSetAlert)
  
  ]);
}
