import AddBoxIcon from '@mui/icons-material/AddBox';
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SideNav from '../../pages/SideNav';
import { ProjectsState } from "../../redux/projects/interfaces";
import { getProject } from "../../redux/projects/projectsActions";
import { RemoteConfigsState } from "../../redux/remoteConfigs/interfaces";
import { getAllRemoteConfigs } from "../../redux/remoteConfigs/remoteConfigsActions";
import { ReduxAction, RootState } from "../../redux/rootReducer";
import CustomModal from '../../reusables/CustomModal/CustomModal';
import GridTable, { Pagination } from "../../reusables/GridTable";
import PageTitle from "../../reusables/PageTitle";
import SearchInput from '../../reusables/SearchInput';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import CreateRemoteConfigForm from './CreateRemoteConfigForm';
import { remoteConfigColumns } from "./helpers";
type Props = {
    getProject: (slug: string) => ReduxAction,
    getAllRemoteConfigs: (projectId: string, searchField: string, page: number, limit: number, sortBy: string, dir: string) => ReduxAction
    project: ProjectsState["project"],
    gridRows: RemoteConfigsState["gridRows"],
    length: RemoteConfigsState['length'],
    total: RemoteConfigsState['total'],
}

function RemoteConfigs({
    getProject,
    getAllRemoteConfigs,
    project,
    length,
    total,
    gridRows,
}: Props) {

    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { slug } = useParams();

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [sort,setSort] = useState('');
    const [dir, setDir] = useState('');

    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (slug)
            dispatch(getProject(String(slug)));
    }, []);
    useEffect(() => {
        if (project._id) {
            dispatch(getAllRemoteConfigs(project._id, '', page, limit, '', ''));
        }
    }, [project._id]);

    const handlePagination = (pagination: Pagination) => {
        let sortBy = '';
        if (pagination.sortBy)
            sortBy = pagination.sortBy;
        let dir = '';
        if (pagination.dir)
            dir = pagination.dir;

        setPage(pagination.page);
        setLimit(pagination.rowsPerPage);
        getAllRemoteConfigs(project._id, searchQuery, pagination.page, pagination.rowsPerPage, sortBy,dir);
    }

    const searchAndPaginateRemoteConfigs = () => {
        getAllRemoteConfigs(project._id, searchQuery, page, limit,sort,dir);
    }



    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <SideNav />
            </Grid>
            <Grid item xs={9} sx={{ height: '100%' }}>
                <div className="h-screen max-h-screen">
                    <BreadCrumb />
                    <Button
                        onClick={() => window.history.back()}
                        className="my-3"
                        disableElevation
                        variant="contained"
                    >Back
                    </Button>

                    <PageTitle title='Remote Config' />
                    {/* SEARCH COMPONENT */}
                    <div className='flex flex-row flex-grow my-3'>
                        <SearchInput
                            setSearchQuery={setSearchQuery}
                            label={'enter key value to search against'}
                            buttonClick={searchAndPaginateRemoteConfigs}
                        />
                        {/* <IconButton type="submit" aria-label="search"
                        onClick={searchAndPaginateRemoteConfigs}
                        >
                            <SearchIcon style={{ fill: "blue" }} />
                        </IconButton> */}
                    </div>
                    <GridTable
                        columns={remoteConfigColumns}
                        rows={gridRows}
                        total={total}
                        length={length}
                        handlePagination={handlePagination}
                        usedFor='remoteConfigs'
                    />

                    <div className="p-3 mt-3">
                        <Button
                            disableElevation
                            onClick={() => setOpen(true)}
                            endIcon={<AddBoxIcon />}
                            variant="contained">Create RemoteConfig</Button>
                    </div>

                    <CustomModal open={open} setOpen={setOpen}>
                        <CreateRemoteConfigForm open={open} setOpen={setOpen}
                            projectId={project._id}
                        />
                    </CustomModal>

                </div>
            </Grid>
        </Grid>
    )
}
const mapStateToProps = (rootState: RootState) => {
    const { project } = rootState.projects;
    const { gridRows, remoteConfigs, total, length } = rootState.remoteConfig;
    return {
        project,
        gridRows,
        remoteConfigs,
        total,
        length,

    }
}

const mapDispatch = {
    getProject: (slug: string) => getProject(slug),
    getAllRemoteConfigs: (projectId: string, searchField: string, page: number, limit: number, sortBy: string, dir: string) => getAllRemoteConfigs(projectId, searchField, page, limit, sortBy, dir),
}
export default connect(mapStateToProps, mapDispatch)(RemoteConfigs)