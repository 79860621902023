export const ACTIONS = {
    GET_NAVIGATION_IN_DB: "GET_NAVIGATION_IN_DB",
    CREATE_NAVIGATION_IN_DB: "CREATE_NAVIGATION_IN_DB",

    // SAGA ACTION
    SET_NAVIGATION: "SET_NAVIGATION",


}


// INTIAL STATE
export const NAVIGATION_INITIAL_STATE : NavigationState = {
    title: '',
    slug: '',
    description: '',
    iosUrl: '',
    androidUrl: '',
    amazonUrl: '',
    macUrl: '',
    windowsUrl: '',
    other: '',
    createdAt: '',
    updatedAt: '',
    project: ''
}

export interface NavigationState {
    _id?: string,
    title: string,
    slug: string,
    description: string,
    iosUrl: string,
    androidUrl: string,
    amazonUrl: string,
    macUrl: string,
    windowsUrl: string,
    other: string,
    createdAt: string,
    updatedAt: string,
    project: string
}

export interface NavigationInterface {
    _id?: string,
    title: string,
    slug: string,
    description: string,
    iosUrl: string,
    androidUrl: string,
    amazonUrl: string,
    macUrl: string,
    windowsUrl: string,
    other: string,
    createdAt: string,
    updatedAt: string,
    project: string
}