export const ACTIONS = {
  START_AB_TEST_IN_DB: "START_AB_TEST_IN_DB",
  END_AB_TEST_IN_DB: "END_AB_TEST_IN_DB",
  GET_EVENT_PROPERTIES_VALUES_IN_DB: "GET_EVENT_PROPERTIES_VALUES_IN_DB",
  GET_DESIGN_EVENT_PROPERTIES_VALUES_IN_DB:
    "GET_DESIGN_EVENT_PROPERTIES_VALUES_IN_DB",
  ASSIGN_WINNER_TO_REMOTE_CONFIG_IN_DB: "ASSIGN_WINNER_TO_REMOTE_CONFIG_IN_DB",
  GET_PARAMETERS_BY_EVENT: "GET_PARAMETERS_BY_EVENT",
  GET_VALUE_COUNTS: "GET_VALUE_COUNTS",
  SAVE_QUERY_REQUEST:"SAVE_QUERY_REQUEST",
  GET_ALL_QUERIES:"GET_ALL_QUERIES",

  // SAGA ACTIONS
  SET_START_AB_TEST_SUCCESS: "SET_START_AB_TEST_SUCCESS",
  SET_EVENT_PROPERTIES_VALUES: "SET_EVENT_PROPERTIES_VALUES",
  SET_PARAMETERS_BY_EVENT: "SET_PARAMETERS_BY_EVENT",
  SET_VALUE_COUNTS: "SET_VALUE_COUNTS",
  SET_ALL_QUERIES: "SET_ALL_QUERIES",
};

export const CONFIG_EDITOR_INITIAL_STATE: ConfigEditorState = {
  status: 0,
  message: "",
  eventProperties: [],
  parameters: [],
  valuesCounts: [],
  queryResult: []
};

export interface ConfigEditorState {
  status: number;
  message: string;
  eventProperties: string[];
  parameters: string[];
  valuesCounts: ValueCount[];
  queryResult: QueryResult []
}

export interface StartAbTestRequest {
  projectId: string;
  abTestId: string;
  designGroupIds: string[];
  overrides: string[];
  eventName: string;
  event: string;
}

export interface ValueCount {
  _id: string;
  name: string;
  clicks: number;
  properties: number;
  ratio: string;

}

export interface QueryResult {
  _id: string;
  name: string;
  valuesCounts: ValueCount[];
}


export interface StopAbTestRequest {
  projectId: string;
  abTestId: string;
}

export interface AssignWinnersToRemoteConfigRequest {
  designGroupIds: string[];
  needsChange: boolean[];
  projectId: string;
}



export interface SaveQueryRequest {
  name: string;
  valuesCounts: ValueCount[]
}