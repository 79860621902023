import AddIcon from '@mui/icons-material/Add';
import { Button, TextField } from "@mui/material";
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { createAbTest } from '../../../redux/abtests/abTestsActions';
import { CreateAbTestRequest } from "../../../redux/abtests/interfaces";
import { setAlert } from '../../../redux/admin/adminActions';
import { AlertMsg } from '../../../redux/admin/interfaces';
import { WARN_SNACKBAR } from '../../../redux/helpers/constants';
import { ReduxAction, RootState } from '../../../redux/rootReducer';
import PageTitle from '../../../reusables/PageTitle';
type Props = {
    projectId: string,
    createAbTest: (p: CreateAbTestRequest) => ReduxAction,
    open: boolean,
    setAlert: (alertMsg: AlertMsg) => ReduxAction,
    alertMsg: AlertMsg,
    setOpen: Dispatch<SetStateAction<boolean>> | ((b: boolean) => ReduxAction),
}

function CreateAbTestForm({ projectId, createAbTest, open, setOpen, setAlert, alertMsg }: Props) {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [comments, setComments] = useState('');

    const handleCreateAbTest = () => {
        if (!name) {
           dispatch(setAlert({
                msg: 'AB Test Name Required!',
                config: WARN_SNACKBAR
            }));
            return;
        }
        if (!comments) setComments('');
        const createAbTestRequest: CreateAbTestRequest = {
            project: projectId,
            name,
            comments,
        }
        createAbTest(createAbTestRequest)


    }
    const handleDismiss = () => {
        setOpen(false);
    }

    return (
        <div className="flex flex-col p-2">
            <PageTitle title='Ab Test Fields' />
            <section>
                <TextField
                    className="mt-3"
                    fullWidth
                    required
                    error={!name}
                    label="Name"
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                    }}
                />
            </section>
            <section>
                <TextField
                    className="mt-3"
                    fullWidth
                    label="Comments"
                    value={comments}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setComments(event.target.value);
                    }}
                />
            </section>
            <Button
                onClick={handleCreateAbTest}
                className="mt-3"
                disableElevation
                fullWidth
                variant="contained">
                Create  Ab Test
            </Button>
            <Button
                onClick={handleDismiss}
                className="mt-3"
                disableElevation
                fullWidth
                variant="outlined">
                Cancel
            </Button>
        </div>
    )
}

const mapDispatch = {
    createAbTest: (p: CreateAbTestRequest) => createAbTest(p),
    setAlert: (p: AlertMsg) => setAlert(p)
}
const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    return {
        alertMsg
    }
}
export default connect(mapStateToProps, mapDispatch)(CreateAbTestForm);