
import { ReduxAction } from "../rootReducer";
import { ACTIONS, AbTestState, AB_TEST_INITIAL_STATE } from "./interfaces";

export const abTestsReducer = (state: AbTestState = AB_TEST_INITIAL_STATE, action: ReduxAction): AbTestState => {
    switch (action.type) {
        // SAGA ACTIONS ------------------------------------------------------------------------
        case ACTIONS.SET_AB_TESTS:
            return {
                ...state,
                abTests: action.payload.abTests,
                gridRows: action.payload.gridRows,
                length: action.payload.length,
                total: action.payload.total
            }
        case ACTIONS.SET_AB_TEST:
            return {
                ...state,
                data:{
                    abTest: action.payload.abTest,
                    designGroupsResults: action.payload.designGroupResults

                }
            }

        case ACTIONS.SET_DESIGNS:
            return {
                ...state,
                designGroup: {
                    designGroup: action.payload.designGroup,
                    designs: action.payload.designs
                },

            }
        default:
            return { ...state }
    }
}