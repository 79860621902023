import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { RemoteConfigInterface } from "../../redux/remoteConfigs/interfaces";

type Props = {
    passEventNameToParent: (idx: number, remoteConfig: string) => void,
    passEventValueToParent: (idx: number, remoteConfig: string) => void
    idx: number
    remoteConfigs: RemoteConfigInterface[],
    keyx: string
    eventName: string
    event: string
    testStarted: boolean

}
function AbEventName({
    passEventNameToParent,
    passEventValueToParent,
    idx,
    keyx,
    remoteConfigs,
    eventName,
    event,
    testStarted
}: Props) {
    const [eventT, setEventT] = useState(event);
    const [eventNameT, setEventNameT] = useState(eventName);
    return (
        <div className="my-2 flex flex-row">
            <Autocomplete
                key={keyx}
                size='small'
                className="mx-1"
                disabled={testStarted}
                disablePortal
                options={remoteConfigs.map(r => r.slug)}
                value={eventNameT}
                sx={{ width: 300 }}
                onChange={(event, newValue) => {
                    setEventNameT(newValue as string);
                    passEventNameToParent(idx, newValue as string);
                }}
                renderInput={(params) => <TextField {...params} label={'Event Name'} />}
            />
            <TextField

                key={`textField-${keyx}`}
                name="value"
                size='small'
                disabled={testStarted}
                value={eventT}
                className="mx-1"
                sx={{ width: 300 }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEventT(event.target.value)
                    passEventValueToParent(idx, event.target.value);

                }}
                label="Deign Text Value"
                required
            />
        </div>
    )
}




export default AbEventName