import { createTheme, Paper } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Route, Routes
} from "react-router-dom";
import AbTest from "./components/AbTests/AbTest";
import ProjectNavigation from "./components/Navigation/ProjectNavigation";
import Project from "./components/Projects/Project";
import Projects from "./components/Projects/Projects";
import RemoteConfigs from "./components/RemoteConfigs/RemoteConfigs";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Welcome from "./pages/Welcome";
import { AdminState } from "./redux/admin/interfaces";
import { RootState } from "./redux/rootReducer";
import './index.css';
import ProjectData from "./pages/ProjectData";
import NewQuery from "./components/ProjectData/NewQuery";
import SavedQueries from "./components/ProjectData/SavedQueries";
import axios from "axios";


type Props = {
  theme: AdminState["theme"],
}

function App({ theme }: Props) {
  const [isSignedIn, setIsignedIn] = useState(false);

  const myTheme = createTheme({
    palette: {
      mode: theme,
    },
  });


  useEffect(() => {

    const token = localStorage.getItem('token');
    setIsignedIn(!!token)
  }, [])


  useEffect(() => {
    const refreshTokenApi = async () => {
      try {
        const url = '/api/v1/auth/refresh';
        const refreshToken = localStorage.getItem('refreshToken');
        const res = await axios.post(url, { refreshToken });
        if (!res) return;
        const { status, token, } = res.data;
        if (status === 200) {
          localStorage.setItem('token', token);
          localStorage.setItem('refreshToken', res.data.refreshToken);
        }
      } catch (err: any) {

      }
    };
    if(isSignedIn)
      refreshTokenApi();
    const time: number = 864000 //10days in seconds;
    const intervalId = setInterval(refreshTokenApi, time); // Call every 10 days

    return () => clearInterval(intervalId); // Clean up the interval
  }, []);


  return (
    <ThemeProvider theme={myTheme}  >
      <Paper elevation={0}>
        <Routes>
          <Route path='/login' element={<Login />} />
          {isSignedIn ?
            <Route path='/'>
              <Route path='/home' element={<Welcome />} />
              <Route path='/home/projectdata' element={<ProjectData />} />
              <Route path='/home/projectdata/newquery' element={<NewQuery />} />
              <Route path='/home/projectdata/savedqueries' element={<SavedQueries />} />
              <Route path='/home/projects' element={<Projects />} />
              <Route path='/home/projects/:slug' element={<Project />} />
              <Route path='/home/projects/:slug/abtests/:abTestSlug' element={<AbTest />} />
              <Route path='/home/projects/:slug/remoteconfigs' element={<RemoteConfigs />} />
              <Route path='/home/projects/:slug/navigation' element={<ProjectNavigation />} />

            </Route>
            : <Route path='/' element={<Login />} />
          }
          <Route path='*' element={<NotFound />} />
        </Routes >
      </Paper>
    </ThemeProvider>

  );
}
const mapStateToProps = (rootState: RootState) => {
  const { theme } = rootState.admin;
  return {
    theme
  }
}
export default connect(mapStateToProps)(App);
