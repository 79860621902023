
import { ReduxAction } from "../rootReducer";
import { ACTIONS, ProjectsState, PROJECTS_INITIAL_STATE } from "./interfaces";

export const projectsReducer = (state: ProjectsState = PROJECTS_INITIAL_STATE, action: ReduxAction): ProjectsState => {
    switch (action.type) {
        // SAGA ACTIONS ------------------------------------------------------------------------
        case ACTIONS.SET_PROJECTS:
            return {
                ...state,
                projects: action.payload.projects,
                gridRows: action.payload.gridRows,
                length: action.payload.length,
                total: action.payload.total
            }
        case ACTIONS.SET_PROJECT:
            return {
                ...state,
                project: action.payload.project
            }    
        default:
            return { ...state }
    }
}