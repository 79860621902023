import { Column } from "../../reusables/GridTable";

export const projectColumns: Column[] = [
  { id: "slug", label: "Integration Name", minWidth: 170 },
  { id: "name", label: "Name", minWidth: 170 },
  {
    id: "createdAt",
    label: "Date Created",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "abTestNumber",
    label: "# Ab Tests",
    minWidth: 100,
    align: "left",
  },
];
