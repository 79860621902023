import {
  ACTIONS,
  AbTestGridRow,
  AbTestInterface,
  CreateAbTestRequest,
  CreateDesignGroupRequest,
  DesignGroupInterface,
  DesignInterface,
  DesignGroup,
  DesignRequest,
  UpdateDesignGroupRequest,
  UpdateDesignRequest,
  UpdateAbTestCommentRequest,
} from "./interfaces";

// DATABASE CRUD ACTION
export function getAllAbTests(projectSlug: string,page:number,limit:number, sortBy: string, dir: string, ) {
  return {
    type: ACTIONS.GET_AB_TESTS_IN_DB,
    payload: {projectSlug , page, limit ,sortBy , dir },
  };
}
export function getAbTest(abTestSlug: string, projectSlug: string) {
  return {
    type: ACTIONS.GET_AB_TEST_IN_DB,
    payload: {abTestSlug, projectSlug},
  };
}

export function createAbTest(p: CreateAbTestRequest) {
  return {
    type: ACTIONS.CREATE_AB_TESTS_IN_DB,
    payload: p,
  };
}

export function updateAbTestComment(p: UpdateAbTestCommentRequest) {
  return {
    type: ACTIONS.UPDATE_AB_TEST_COMMENT_IN_DB,
    payload: p,
  };
}

export function createDesignGroup(p: CreateDesignGroupRequest) {
  return {
    type: ACTIONS.CREATE_DESIGN_GROUP_IN_DB,
    payload: p,
  };
}

// export function updateAbTest(body: UpdateAbTestRequest, abTestId: string) {
//     return {
//         type: ACTIONS.UPDATE_AB_TEST_IN_DB,
//         payload: { body, projectId }
//     }
// }

export function deleteAbTest(abTestId: string) {
  return {
    type: ACTIONS.DELETE_AB_TEST_IN_DB,
    payload: {
      abTestId,
    },
  };
}

export function deleteDesignGroup(designGroupId: string) {
  return {
    type: ACTIONS.DELETE_DESIGN_GROUP_IN_DB,
    payload: {
      designGroupId,
    },
  };
}

export function updateDesignGroup(designGroupId: string,updateDesignGroupRequest: UpdateDesignGroupRequest){
  return {
    type: ACTIONS.UPDATE_DESIGN_GROUP_IN_DB,
    payload: {
      designGroupId, updateDesignGroupRequest
    }
  }
}

export function deleteDesign(designId: string) {
  return {
    type: ACTIONS.DELETE_DESIGN_IN_DB,
    payload: {
      designId,
    },
  };
}

export function updateDesign(designId: string,updateDesignRequest: UpdateDesignRequest){
  return {
    type: ACTIONS.UPDATE_DESIGN_IN_DB,
    payload: {
      designId,updateDesignRequest
    },
  };
}

export function createDesignInDesignGroup(
  designGroupId: string,
  design: DesignRequest
) {
  return {
    type: ACTIONS.CREATE_DESIGN_IN_DESIGN_GROUP_IN_DB,
    payload: {
      designGroupId,
      design,
    },
  };
}

// SAGA ACTIONS
export function setAbTests(
  abTests: AbTestInterface[],
  gridRows: AbTestGridRow[],
  length: number,
  total: number
) {
  return {
    type: ACTIONS.SET_AB_TESTS,
    payload: { abTests, gridRows, length, total },
  };
}

export function setAbTest(
  abTest: AbTestInterface,
  designGroupResults: DesignGroup[]
) {
  return {
    type: ACTIONS.SET_AB_TEST,
    payload: { abTest, designGroupResults },
  };
}

export function setDesignGroup(
  designGroup: DesignGroupInterface,
  designs: DesignInterface[]
) {
  return {
    type: ACTIONS.SET_DESIGNS,
    payload: { designGroup, designs },
  };
}
