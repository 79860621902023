import { Button, Grid, Paper, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAlert } from "../redux/admin/adminActions";
import { AdminState, AlertMsg } from "../redux/admin/interfaces";
import { ERR_SNACKBAR } from "../redux/helpers/constants";
import { ReduxAction, RootState } from "../redux/rootReducer";
import PageTitle from "../reusables/PageTitle";

type Props = {
    setAlert: (alertMsg: AlertMsg) => ReduxAction,
    alertMsg: AdminState['alertMsg']
}

function Login({
    setAlert,
    alertMsg
}: Props) {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const url = '/api/v1/auth/login';
            const res = await axios.post(url, { username, password });
            if (!res) {
                setAlert({
                    msg: 'No Result Returned From API',
                    config: ERR_SNACKBAR
                });
            }
            const { status, token , refreshToken } = res.data;
            if (status === 200) {
                localStorage.setItem('token', token);
                localStorage.setItem('refreshToken', refreshToken);
                window.location.assign('/home/projects');
            }
        } catch (err: any){
            setAlert({
                msg: err.response.data.message,
                config: ERR_SNACKBAR
            });
        }
    }
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && token.length > 0) {
            navigate('/home/projects')
        }
    }, [])

    return (
        <Grid container >
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={7} sx={{ height: '100%' }} >
                <div className="max-h-screen h-screen">
                    <Paper className="my-3 p-5 ">
                        <PageTitle title='Login' />
                        <TextField
                            className="my-2"
                            fullWidth
                            type='text'
                            value={username}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUsername(event.target.value);
                            }}
                            id="username"
                            label="Username"
                            variant="filled" />

                        <TextField
                            className="my-2"
                            fullWidth
                            type="password"
                            id="password"
                            value={password}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setPassword(event.target.value);
                            }}
                            label="Password"
                            variant="filled" />
                        <Button
                            className="my-3"
                            variant='contained'
                            onClick={handleLogin}
                        >Login</Button>
                    </Paper>
                </div>
            </Grid>
        </Grid>
    )
}

const mapDispatch = {
    setAlert: (p: AlertMsg) => setAlert(p)
}
const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    return {
        alertMsg
    }
}
export default connect(mapStateToProps, mapDispatch)(Login)