import { Grid, Button, Paper } from "@mui/material"
import AbTests from "../components/AbTests/AbTests"
import BreadCrumb from "../components/BreadCrumb/BreadCrumb"
import CreateNewQuery from "../components/ProjectData/CreateNewQuery"
import GetSavedQueries from "../components/ProjectData/GetSavedQueries"
import DeleteProjectForm from "../components/Projects/DeleteProjectForm"
import UpdateProjectForm from "../components/Projects/UpdateProjectForm"
import CustomModal from "../reusables/CustomModal/CustomModal"
import PageTitle from "../reusables/PageTitle"
import SideNav from "./SideNav"

function ProjectData() {
    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <SideNav />
            </Grid>
            <Grid item xs={9} sx={{ height: '100%' }}>
                <div className="h-screen max-h-screen">
                    <div className="container flex flex-col ">
                        <PageTitle title='Project Data' />
                        <div className="flex flex-row flex-grow">
                            <CreateNewQuery />
                            <GetSavedQueries />

                        </div>

                    </div>
                </div>
            </Grid>
        </Grid>
    )
}
export default ProjectData