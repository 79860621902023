import { Edit } from "@mui/icons-material"
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import SideNav from "../../pages/SideNav"
import { getAbTest } from "../../redux/abtests/abTestsActions"
import { AbTestState } from "../../redux/abtests/interfaces"
import { getEventPropertiesValues } from "../../redux/configEditor/configEditorActions"
import { ConfigEditorState } from "../../redux/configEditor/interfaces"
import { getProject } from "../../redux/projects/projectsActions"
import { ReduxAction, RootState } from "../../redux/rootReducer"
import CustomModal from "../../reusables/CustomModal/CustomModal"
import PageTitle from "../../reusables/PageTitle"
import BreadCrumb from "../BreadCrumb/BreadCrumb"
import BarChartArea from "./BarChartArea"
import ConfigEditor from "./ConfigEditor"
import DesignPropertyData from "./DesignPropertyData"
import Designs from "./Designs"
import { getDesignGroupIds, getDesignNames } from "./helpers"
import DeleteAbTestForm from "./modals/DeleteAbTestForm"
import UpdateAbTestCommentForm from "./modals/UpdateAbTestCommentForm"

type Props = {
    getProject: (projectSlug: string) => ReduxAction,
    getAbTest: (abTestSlug: string, projectSlug: string) => ReduxAction,
    abTest: AbTestState['data']['abTest']
    designGroupsResults: AbTestState['data']['designGroupsResults'],
    eventProperties: ConfigEditorState['eventProperties'],
    getEventPropertiesValues: () => ReduxAction
}


function AbTest({
    getProject,
    abTest,
    getAbTest,
    designGroupsResults,
    getEventPropertiesValues,
    eventProperties
}: Props) {

    const navigate = useNavigate();
    const { slug, abTestSlug } = useParams();
    const [designNames, setDesigns] = useState<string[]>([]);
    const [designGroupIds, setDesignGroupIds] = useState<string[]>([]);

    const [openDelete, setOpenDelete] = useState(false);
    const [openUpdateAbTestComment, setOpenUpdateAbTestComment] = useState(false);
    const [testStarted, setTestStarted] = useState(false);
    useEffect(() => {
        if (slug)
            getProject(slug);
    }, []);

    useEffect(() => {
        if (slug && abTestSlug) {
            getAbTest(abTestSlug, slug);
        }
    }, []);
    useEffect(() => {
        if (abTestSlug)
            getEventPropertiesValues();
    }, []);
    useEffect(() => {
        if (designGroupsResults) {
            setDesigns(getDesignNames(designGroupsResults));
            setDesignGroupIds(getDesignGroupIds(designGroupsResults));
        }
    }, [designGroupsResults]);
    useEffect(() => {
        if (abTest.integrationId) {
            setTestStarted(true);
        }
    }, [abTest._id]);


    const realoadPage = (abTestSlug: string) => {
        if(slug && abTestSlug){
            navigate(`/home/projects/${slug}/abtests/${abTestSlug}`);
            window.location.reload();
        }
        
    }

    return (
        <Grid container spacing={1} >
            <Grid item xs={2}>
                <SideNav />
            </Grid>
            <Grid item xs={9} sx={{ height: '100%' }}>
                <div className="mt-3">

                    <BreadCrumb />
                    <Button
                        onClick={() => window.history.back()}
                        className="my-3"
                        disableElevation
                        variant="contained"
                    >Back
                    </Button>

                    <div className="flex flex-row">
                        {abTest.beforeAbTestSlug ?
                            <Button
                                onClick={() => realoadPage(abTest.beforeAbTestSlug)}
                                className="m-3"
                                disableElevation
                                variant="contained"
                                size="small"
                                color="inherit"
                            >{abTest.beforeAbTestSlug}
                            </Button> : null}
                        {abTest.afterAbTestSlug ?

                            <><div className="mt-3">/</div><Button
                                onClick={() => realoadPage(abTest.afterAbTestSlug)}
                                className="m-3"
                                disableElevation
                                size="small"
                                variant="contained"
                            >{abTest.afterAbTestSlug}
                            </Button></> : null
                        }
                    </div>


                    {abTest._id ? <PageTitle title={abTest.name} /> : null}

                    {abTest._id ? <Designs
                        key='designs'
                        testStarted={testStarted}
                        abtestId={abTest._id}
                        designGroupsResults={designGroupsResults} />
                        : null}
                    {abTest._id ? <ConfigEditor
                        key='config editor'
                        testStarted={testStarted}
                        designNames={designNames}
                        designGroupIds={designGroupIds}
                    />
                        :
                        null}
                </div>
                <div>
                    {abTest._id ?
                        <div className="my-3 p-3 overflow-x-scroll">
                            <PageTitle title="Event/Property Data" />
                            <DesignPropertyData
                                key='event/property'
                                designNames={designNames}
                                eventProperties={eventProperties}
                                designGroupsResults={designGroupsResults}

                            />
                        </div> : null}
                </div>
                <div className="flex flex-row mt-3" >
                    <div className="basis-1/3 mr-3">
                        <div>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                key={'tcellHead'}
                                                align={'left'}
                                            >
                                                Summary
                                            </TableCell>

                                            <TableCell
                                                key={'tcellHeadDesc'}
                                                align={'left'}
                                            >
                                                Press and hold or hover over comments to view
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                key='Creator'
                                                align={'left'}
                                            >Creator</TableCell>
                                            <TableCell
                                                key='admin'
                                                align={'left'}
                                            >admin</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell
                                                key='name'
                                                align={'left'}
                                            >Name</TableCell>
                                            <TableCell
                                                key='abTest_name'
                                                align={'left'}
                                            >{abTest.name}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell
                                                key='startDate'
                                                align={'left'}
                                            >Start Date</TableCell>
                                            <TableCell
                                                key='abTest_name'
                                                align={'left'}
                                            >{abTest.startDate}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell
                                                key='endDate'
                                                align={'left'}
                                            >End Date</TableCell>
                                            <TableCell
                                                key='abTest_endDate'
                                                align={'left'}
                                            >{abTest.endDate}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell
                                                key='comments'
                                                align={'left'}
                                            >Comments</TableCell>
                                            <TableCell
                                                key='abTest_comments'
                                                align={'left'}
                                            >
                                                <div className="flex justify-between">

                                                    <Tooltip className="mt-1" title={abTest.comments}>
                                                        <span>
                                                            {abTest.comments.length > 20 ?
                                                                abTest.comments.slice(0, 19) :
                                                                abTest.comments
                                                            }
                                                        </span>
                                                    </Tooltip>
                                                    <span>
                                                        <IconButton
                                                            color='default'
                                                            onClick={() => setOpenUpdateAbTestComment(true)}
                                                        ><Edit />
                                                        </IconButton>
                                                    </span>
                                                </div>



                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell
                                                key='createdAt'
                                                align={'left'}
                                            >Created At</TableCell>
                                            <TableCell
                                                key='abTest_createdAt'
                                                align={'left'}
                                            >{abTest.createdAt}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell
                                                key='updatedAt'
                                                align={'left'}
                                            >Last  Updated</TableCell>
                                            <TableCell
                                                key='abTest_updatedAt'
                                                align={'left'}
                                            >{abTest.updatedAt}</TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    <div className="basis-3/5">
                        {designGroupsResults && designGroupsResults.length > 0 ? <BarChartArea
                            key='BarChartArea'
                            designGroupsResults={designGroupsResults} /> : null}
                    </div>
                </div>

                <div className="my-3 p-3">
                    <Button
                        variant="outlined"
                        color='error'
                        onClick={() => setOpenDelete(true)}
                    >Delete</Button>
                </div>

                <CustomModal open={openDelete} setOpen={setOpenDelete}>
                    <DeleteAbTestForm
                        abTestId={abTest._id}
                        open={openDelete} setOpen={setOpenDelete} />
                </CustomModal>

                <CustomModal open={openUpdateAbTestComment} setOpen={setOpenUpdateAbTestComment}>
                    <UpdateAbTestCommentForm
                        abTest={abTest}
                        open={openUpdateAbTestComment} setOpen={setOpenUpdateAbTestComment} />
                </CustomModal>
            </Grid>
        </Grid>
    )
}
const mapStateToProps = (rootState: RootState) => {
    const { abTest, designGroupsResults } = rootState.abTests.data;
    const { eventProperties } = rootState.configEditor;
    const { alertMsg } = rootState.admin;
    const { project } = rootState.projects;
    return {
        abTest,
        eventProperties,
        designGroupsResults,
        alertMsg,
        project
    }
}

const mapDispatch = {
    getProject: (slug: string) => getProject(slug),
    getAbTest: (abTestSlug: string, projectSlug: string) => getAbTest(abTestSlug, projectSlug),
    getEventPropertiesValues: () => getEventPropertiesValues(),
}
export default connect(mapStateToProps, mapDispatch)(AbTest)