import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ProjectsState } from "../../redux/projects/interfaces";
import { RemoteConfigsState } from "../../redux/remoteConfigs/interfaces";
import { getAllRemoteConfigs } from "../../redux/remoteConfigs/remoteConfigsActions";
import { ReduxAction, RootState } from "../../redux/rootReducer";

import { AbTestState } from "../../redux/abtests/interfaces";
import { startAbTest } from "../../redux/configEditor/configEditorActions";
import { StartAbTestRequest } from "../../redux/configEditor/interfaces";
import CustomModal from "../../reusables/CustomModal/CustomModal";
import AbEventName from "./AbEventName";
import ConfigEditorAutocomplete from "./ConfigEditorAutocomplete";
import StopAbTestAndAssignWinnerForm from "./modals/StopAbTestAndAssignWinnerForm";
import { eventNames } from "process";

type Props = {
    getAllRemoteConfigs: (projectId: string, searchField: string, page: number, limit: number, sortBy: string, dir: string) => ReduxAction,
    startAbTest: (startAbTestRequest: StartAbTestRequest) => ReduxAction,
    project: ProjectsState['project'],
    abTest: AbTestState['data']['abTest'],
    designGroupsResults: AbTestState['data']['designGroupsResults'],
    remoteConfigs: RemoteConfigsState['remoteConfigs'],
    designNames: string[],
    designGroupIds: string[],
    testStarted: boolean
}

function ConfigEditor({
    getAllRemoteConfigs,
    startAbTest,
    project,
    abTest,
    designGroupIds,
    remoteConfigs,
    designNames,
    designGroupsResults,
    testStarted
}: Props) {
    const [overrides, setOverrides] = useState<string[]>([]);
    const [eventNameKeys, setEventNameKeys] = useState<string[]>([]);
    const [eventValues, setEventValues] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (project._id)
            getAllRemoteConfigs(project._id, '', 1, 10000, '', '')
    }, [project._id]);
    const handleStartTest = () => {
        let event = eventValues[0];
        if(!event){
            event = abTest.event;
        }
        let eventName =  eventNameKeys[0];
        if(!eventName){
            eventName = abTest.eventName;
        }
        const startAbTestRequest: StartAbTestRequest = {
            projectId: project._id,
            abTestId: abTest._id,
            designGroupIds,
            overrides,
            event: event,
            eventName: eventName            
        };
        startAbTest(startAbTestRequest);
    }
    const passOverrideToParent = (idx: number, remoteConfig: string) => {
        overrides[idx] = remoteConfig;
    }
    const passEventNameToParent = (idx: number, remoteConfig: string) => {
        eventNameKeys[idx] = remoteConfig;
    }
    const passEventValueToParent = (idx: number, remoteConfig: string) => {
        eventValues[idx] = remoteConfig;
    }
    return (
        <div className="p-3 overflow-x-scroll">
            <h3 className="text-base font-bold mb-3">Config Editor</h3>
            <div className="flex flex-row" >

                <AbEventName
                    passEventNameToParent={passEventNameToParent}
                    passEventValueToParent={passEventValueToParent}
                    idx={0}
                    testStarted={testStarted}
                    key={`event-editor`}
                    keyx={`event-editor`}
                    remoteConfigs={remoteConfigs}
                    eventName={abTest.eventName}
                    event={abTest.event}
                />
            </div>
            <div className="flex flex-row">
                {designNames.map((designName, i) => {
                    return (
                        <div
                            key={i}
                        >
                            <ConfigEditorAutocomplete
                                passOverrideToParent={passOverrideToParent}
                                idx={i}
                                testStarted={testStarted}
                                key={`config-editor-${i}`}
                                keyx={`config-editor-${i}`}
                                designName={designName}
                                remoteConfigs={remoteConfigs}
                                configEditor={designGroupsResults[i].designGroup.configEditor}
                            />
                        </div>

                    )
                }
                )}
            </div>
            <div className="flex flex-row flex-grow">

                {!abTest.integrationId ? <Button
                    className="m-1"
                    key='start-test'
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={handleStartTest}
                >
                    start Test
                </Button>
                    :
                    <Button
                        className="m-1"
                        key='end-test'
                        variant="outlined"
                        color="error"
                        disableElevation
                        onClick={() => setOpen(true)}
                    >
                        stop Test
                    </Button>}

            </div>

            <CustomModal open={open} setOpen={setOpen}>
                <StopAbTestAndAssignWinnerForm
                    projectId={project._id}
                    abTestId={abTest._id}
                    open={open}
                    setOpen={setOpen}
                    // designGroupsResults={designGroupsResults}
                />
            </CustomModal>
        </div>
    )
}

const mapStateToProps = (rootState: RootState) => {
    const { project } = rootState.projects;
    const { remoteConfigs } = rootState.remoteConfig;
    const { abTest, designGroupsResults } = rootState.abTests.data;

    return {
        project,
        remoteConfigs,
        abTest,
        designGroupsResults
    }
}

const mapDispatch = {
    startAbTest: (startAbTestRequest: StartAbTestRequest) => startAbTest(startAbTestRequest),
    getAllRemoteConfigs: (projectId: string, searchField: string, page: number, limit: number, sortBy: string, dir: string) => getAllRemoteConfigs(projectId, searchField, page, limit, sortBy, dir)
}

export default connect(mapStateToProps, mapDispatch)(ConfigEditor)