import axios, { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { setAlert } from "../admin/adminActions";
import { ERR_SNACKBAR, SUCCESS_SNACKBAR } from "../helpers/constants";
import {
  ACTIONS,
  formatRemoteConfigGridRows,
  RemoteConfigInterface,
} from "./interfaces";
import {
  createRemoteConfig,
  deleteRemoteConfig,
  getAllRemoteConfigs,
  setRemoteConfigs,
  updateRemoteConfig,
} from "./remoteConfigsActions";

// GET ALL PROJECTS
type GetAllRemoteConfigsResponse = {
  status: number;
  message: string;
  data: {
    total: number;
    length: number;
    data: RemoteConfigInterface[];
  };
};
type GetRemoteConfigsInDbAction = ReturnType<typeof getAllRemoteConfigs>;
function* handleGetAllRemoteConfigsInDb({
  payload,
}: GetRemoteConfigsInDbAction) {
  try {
    const id = payload.projectId;
    const search = payload.searchField;
    const page = payload.page;
    const limit = payload.limit;
    const dir = payload.dir;
    const sortBy = payload.sortBy;
    let url = `/api/v1/remoteconfigs/projects/${id}?page=${page}&limit=${limit}`;
    if (search && search.length > 0) {
      url += `&key=${search}`;
    }
    if(sortBy.length > 0){
      url += `&sort=${sortBy}&dir=${dir}`;
    }
    const res: AxiosResponse<GetAllRemoteConfigsResponse> = yield call(
      axios.get,
      url
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, data } = res.data;
    if (status !== 200) throw new Error(message);

    const remoteConfigs = data.data;
    const total = data.total;
    const length = data.length;
    const gridRows = formatRemoteConfigGridRows(remoteConfigs);
    yield put(setRemoteConfigs(remoteConfigs, gridRows, length, total));
    // yield put(
    //   setAlert({
    //     msg: "Remote Configs Fetched Successfully",
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
  } catch (err: any) {
    console.log("err", err);
    yield put(
      setAlert({
        msg: err.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptGetRemoteConfigsInDb() {
  yield takeLatest(
    [ACTIONS.GET_ALL_REMOTE_CONFIGS_IN_DB],
    handleGetAllRemoteConfigsInDb
  );
}

// CREATE REMOTE CONFIG
type CreateRemoteConfigResponse = {
  status: number;
  message: string;
  data: RemoteConfigInterface;
};
type CreateRemoteConfigsInDbAction = ReturnType<typeof createRemoteConfig>;
function* handleCreateRemoteConfigAction({
  payload,
}: CreateRemoteConfigsInDbAction) {
  try {
    const url = `/api/v1/remoteconfigs/`;
    const form = new FormData();

    const { projectId, key, value, type, image } = payload;

    form.append("project", projectId);
    form.append("key", key);
    form.append("value", value);
    form.append("type", type);
    if (type === "image") {
      form.append("image", image as File);
    }

    const res: AxiosResponse<CreateRemoteConfigResponse> = yield call(
      axios.post,
      url,
      form,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, data } = res.data;
    if (status !== 201) throw new Error(message);
    yield put(
      setAlert({
        msg: "Remote Config Created Successfully",
        config: SUCCESS_SNACKBAR,
      })
    );
    window.location.reload();
  } catch (e: any) {
    console.log("err", e);
    // yield put(
    //   setAlert({
    //     msg: e.response.data.message,
    //     config: ERR_SNACKBAR,
    //   })
    // );
  }
}
function* interceptCreateRemoteConfigInDb() {
  yield takeLatest(
    [ACTIONS.CREATE_REMOTE_CONFIG_IN_DB],
    handleCreateRemoteConfigAction
  );
}
type UpdateRemoteConfigResponse = {
  status: number;
  message: string;
  data: RemoteConfigInterface;
};
type UpdateRemoteConfigsInDbAction = ReturnType<typeof updateRemoteConfig>;
function* handleUpdateRemoteConfigAction({
  payload,
}: UpdateRemoteConfigsInDbAction) {
  try {
    const remoteConfigId = payload.remoteConfigId;
    const url = `/api/v1/remoteconfigs/${remoteConfigId}`;
    const form = new FormData();

    const { value, image } = payload.remoteConfig;

    form.append("value", value);
    form.append("image", image as File);

    const res: AxiosResponse<UpdateRemoteConfigResponse> = yield call(
      axios.put,
      url,
      form,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, data } = res.data;
    if (status !== 202) throw new Error(message);
    // yield put(
    //   setAlert({
    //     msg: "Remote Config Updated Successfully",
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
    window.location.reload();
  } catch (e: any) {
    console.log("err", e);
    yield put(
      setAlert({
        msg: e.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptUpdateRemoteConfigInDb() {
  yield takeLatest(
    [ACTIONS.UPDATE_REMOTE_CONFIG_IN_DB],
    handleUpdateRemoteConfigAction
  );
}

// DELETE REMOTE CONFIG

type DeleteRemoteConfigInDbAction = ReturnType<typeof deleteRemoteConfig>;
function* handleDeleteRemoteConfigAction({
  payload,
}: DeleteRemoteConfigInDbAction) {
  try {
    const remoteConfigId = payload;
    const url = `/api/v1/remoteconfigs/${remoteConfigId}`;
    const res: AxiosResponse = yield call(axios.delete, url);
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    if (res.status !== 204) throw new Error("Delete Unsuccessful!");
    // yield put(
    //   setAlert({
    //     msg: "Remote Config Deleted Successfully",
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
    window.location.reload();
  } catch (e: any) {
    console.log("err", e);
    yield put(
      setAlert({
        msg: e.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptDeleteRemoteConfigInDB() {
  yield takeLatest(
    [ACTIONS.DELETE_REMOTE_CONFIG_IN_DB],
    handleDeleteRemoteConfigAction
  );
}

export function* remoteConfigsSagas() {
  yield all([
    call(interceptGetRemoteConfigsInDb),
    call(interceptCreateRemoteConfigInDb),
    call(interceptDeleteRemoteConfigInDB),
    call(interceptUpdateRemoteConfigInDb),
  ]);
}
