import { Autocomplete, Button, Paper, TextField } from "@mui/material"
import { useState } from "react"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { DesignGroupInterface, GetDesignEventPropertiesRequest } from "../../redux/abtests/interfaces"
import { getDesignEventPropertiesValues } from "../../redux/configEditor/configEditorActions"
import { ReduxAction, RootState } from "../../redux/rootReducer"
import PageTitle from "../../reusables/PageTitle"

type Props = {
    designName: string,
    eventProperties: string[],
    designGroup: DesignGroupInterface,
    remoteConfigs: RootState['remoteConfig']['remoteConfigs'],
    getDesignEventPropertiesValues: (getDesignEventPropertiesRequest: GetDesignEventPropertiesRequest) => ReduxAction,
}
function EventPropertyDataInput({ remoteConfigs, designName, eventProperties, designGroup, getDesignEventPropertiesValues }: Props) {
    const { slug } = useParams();
    const [event, setEvent] = useState(designGroup.event || '');
    const [property, setProperty] = useState(designGroup.property || '');
    const [parameter, setParameter] = useState(designGroup.parameter || '');
    const dispatch = useDispatch();

    const handleGetData = () => {
        const getDesignEventPropertiesRequest: GetDesignEventPropertiesRequest = {
            projectSlug: slug as string,
            designGroupId: designGroup._id,
            event,
            property,
            parameter
        }
        dispatch(getDesignEventPropertiesValues(getDesignEventPropertiesRequest));
    }

    return (
        <><div className=" basis-1/5">
            <Paper className="my-3 mx-2 p-1 ">
                <PageTitle title={designName} />
                <div className="flex flex-col my-1">
                    <Autocomplete
                        className="my-1"
                        size="small"
                        key='event'
                        options={eventProperties}
                        value={event}
                        getOptionLabel={(option) => option}
                        onChange={(event, newValue) => {
                            setEvent(newValue as string);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Event Name"
                                placeholder="Event Name" />
                        )} />
                    <Autocomplete
                        className="my-1"
                        size="small"
                        key='property'
                        value={property}
                        options={eventProperties}
                        getOptionLabel={(option) => option}
                        onChange={(event, newValue) => {
                            setProperty(newValue as string);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Property Name"
                                placeholder="Property Name" />
                        )} />
                    <Autocomplete
                        className="my-1"
                        size="small"
                        key='parameter'
                        value={parameter}
                        options={remoteConfigs.map(item => item.key)}
                        getOptionLabel={(option) => option}
                        onChange={(event, newValue) => {
                            setParameter(newValue as string);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Parameter Name"
                                placeholder="Parameter Name" />
                        )} />
                </div>
            </Paper>
            <div className="flex mx-2 p-1 ">
                <div className="flex-none">
                    <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        size='small'
                        onClick={handleGetData}
                    >Get Data</Button>
                </div>
                <div className="flex-none">
                    <Button
                        className="mx-1"
                        variant="outlined"
                        disableElevation
                        color="warning"
                        size='small'
                    >Reset Fields</Button>
                </div>
            </div>
        </div>

        </>
    )
}

// 

const mapStateToProps = (rootState: RootState) => {
    const { remoteConfigs } = rootState.remoteConfig;
    return {
        remoteConfigs
    }
}

const mapDispatch = {
    getDesignEventPropertiesValues: (getDesignEventPropertiesRequest: GetDesignEventPropertiesRequest) => getDesignEventPropertiesValues(getDesignEventPropertiesRequest),
}

export default connect(mapStateToProps, mapDispatch)(EventPropertyDataInput)




