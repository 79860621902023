
import { ReduxAction } from "../rootReducer";
import { ACTIONS, NavigationState, NAVIGATION_INITIAL_STATE } from "./interfaces";

export const navigationConfigsReducer = (state: NavigationState = NAVIGATION_INITIAL_STATE, action: ReduxAction): NavigationState => {
    switch (action.type) {
        // SAGA ACTIONS ------------------------------------------------------------------------
        case ACTIONS.SET_NAVIGATION:
            return {
                ...state,
                _id: state._id ? state._id : undefined,
                title: state.title,
                slug: state.slug,
                description: state.description,
                iosUrl: state.iosUrl,
                androidUrl: state.androidUrl,
                amazonUrl: state.amazonUrl,
                macUrl: state.macUrl,
                windowsUrl: state.windowsUrl,
                other: state.other,
                createdAt: state.createdAt,
                updatedAt: state.updatedAt,
                project: state.project

            }
        default:
            return { ...state }
    }
}