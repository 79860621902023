import { Button, Paper } from "@mui/material"
import { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { getAllAbTests } from "../../redux/abtests/abTestsActions"
import { AbTestState } from "../../redux/abtests/interfaces"
import { ProjectsState } from "../../redux/projects/interfaces"
import { ReduxAction, RootState } from "../../redux/rootReducer"
import CustomModal from "../../reusables/CustomModal/CustomModal"
import GridTable, { Pagination } from "../../reusables/GridTable"
import CreateAbTestForm from "./modals/CreateAbTestForm"
import { abTestColumns } from "./helpers"
type Props = {
    gridRows: AbTestState["gridRows"],
    project: ProjectsState['project'],
    total: AbTestState["total"],
    length: AbTestState["length"],
    getAllAbTests: (projectSlug: string, page: number, limit: number, sortBy: string, dir: string) => ReduxAction,
}


function AbTests({
    gridRows,
    total,
    length,
    project,
    getAllAbTests
}: Props) {
    const { slug } = useParams();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);


    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);

    useEffect(() => {
        if (slug)
            dispatch(getAllAbTests(slug, page, limit, '', ''));
    }, [])

    const handlePagination = (pagination: Pagination) => {
        const dir: string = pagination.dir || '';
        const sortBy: string = pagination.sortBy || '';
        if (slug)
            dispatch(getAllAbTests(slug, pagination.page, pagination.rowsPerPage, sortBy, dir));
    }

    return (
        <div className="p-3">
            <GridTable
                columns={abTestColumns}
                rows={gridRows}
                total={total}
                length={length}
                usedFor="abTests"
                handlePagination={handlePagination}
            />

            <div className="my-3 p-3">
                <Button
                    color='primary'
                    disableElevation
                    variant="contained"
                    onClick={() => setOpen(true)}
                >Add Test</Button>
            </div>



            <CustomModal open={open} setOpen={setOpen}>
                <CreateAbTestForm projectId={project._id} open={open} setOpen={setOpen} />
            </CustomModal>
        </div>
    )
}


const mapStateToProps = (rootState: RootState) => {
    const { gridRows, total, length } = rootState.abTests;
    const { project } = rootState.projects;
    return {
        gridRows,
        total,
        length,
        project
    }
}

const mapDispatch = {
    getAllAbTests: (projectSlug: string, page: number, limit: number, sortBy: string, dir: string) => getAllAbTests(projectSlug, page, limit, sortBy, dir)
}

export default connect(mapStateToProps, mapDispatch)(AbTests)