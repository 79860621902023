import { Button } from "@mui/material"
import { useEffect } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { getAbTest } from "../../../redux/abtests/abTestsActions"
import { DesignGroup } from "../../../redux/abtests/interfaces"
import { assignWinnersToRemoteConfig, stopAbTest } from "../../../redux/configEditor/configEditorActions"
import { AssignWinnersToRemoteConfigRequest, StopAbTestRequest } from "../../../redux/configEditor/interfaces"
import { ReduxAction, RootState } from "../../../redux/rootReducer"
import AssignAbTestWinnerForm from "../../../reusables/AssignAbTestWinnerForm"
import PageTitle from "../../../reusables/PageTitle"

type Props = {
    getAbTest: (abTestSlug: string, projectSlug: string) => ReduxAction,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    projectId: string,
    abTestId: string,
    abTestSlug?: string,
    designGroupsResults?: DesignGroup[],
    stopAbTest: (stopAbTestRequest: StopAbTestRequest) => ReduxAction,
    assignWinnersToRemoteConfig: (assignWinnersToRemoteConfigRequest: AssignWinnersToRemoteConfigRequest) => ReduxAction
}

function StopAbTestAndAssignWinnerForm({
    getAbTest,
    open,
    setOpen,
    abTestSlug,
    projectId,
    abTestId,
    designGroupsResults,
    stopAbTest,
    assignWinnersToRemoteConfig
}: Props) {

    const { slug } = useParams();
    useEffect(() => {
        if (abTestSlug && slug)
            getAbTest(abTestSlug, slug)
    }, []);

    const changeRemoteConfigMap = new Map<string, boolean>();
    const handleDismiss = () => {
        setOpen(false);
    }

    const handleAssignAndStop = () => {
        const needsChange: boolean[] = [];
        const designGroupIds: string[] = [];
        changeRemoteConfigMap.forEach((value, key) => {
            needsChange.push(value);
            designGroupIds.push(key);
        });
        const assignWinnersToRemoteConfigRequest: AssignWinnersToRemoteConfigRequest = {
            projectId,
            needsChange,
            designGroupIds,
        }
        assignWinnersToRemoteConfig(assignWinnersToRemoteConfigRequest);
        handleStopTest();
    }

    const handleStopTest = () => {
        const stopAbTestRequest: StopAbTestRequest = {
            projectId,
            abTestId
        };
        stopAbTest(stopAbTestRequest);
    }



    return (

        <div>
            <PageTitle title="Please assign Winner before stopping this A/B test to be reflected in remote config" />
            {designGroupsResults?.map(((designGroup, i) => <AssignAbTestWinnerForm
                changeRemoteConfigMap={changeRemoteConfigMap}
                designGroup={designGroup?.designGroup}
                designs={designGroup.designs}
            />)
            )}

            <div className="flex flex-row flex-grow">
                <div className="flex-none mt-3 mx-2">
                    <Button
                        variant="contained"
                        color='primary'
                        disableElevation
                        onClick={handleAssignAndStop}
                    >
                        assign and stop
                    </Button>
                </div>
                <div className="flex-none mt-3 mx-2">
                    <Button
                        variant="outlined"
                        color='primary'
                        disableElevation
                        onClick={handleStopTest}
                    >
                        only stop test
                    </Button>
                </div>
                <div className="flex-none mt-3 mx-2">
                    <Button
                        variant="outlined"
                        color='error'
                        disableElevation
                        onClick={handleDismiss}
                    >
                        cancel
                    </Button>
                </div>
            </div>

        </div>
    )
}


const mapStateToProps = (rootState: RootState) => {

    const { abTest, designGroupsResults } = rootState.abTests.data;

    return {
        abTest, designGroupsResults
    }
}


const mapDispatch = {
    getAbTest: (abTestSlug: string, projectSlug: string) => getAbTest(abTestSlug, projectSlug),
    stopAbTest: (stopAbTestRequest: StopAbTestRequest) => stopAbTest(stopAbTestRequest),
    assignWinnersToRemoteConfig: (assignWinnersToRemoteConfigRequest: AssignWinnersToRemoteConfigRequest) => assignWinnersToRemoteConfig(assignWinnersToRemoteConfigRequest)
}


export default connect(mapStateToProps, mapDispatch)(StopAbTestAndAssignWinnerForm)