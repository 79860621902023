import { Autocomplete, Button, Grid, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import SideNav from "../../pages/SideNav";
import { getEventPropertiesValues, getParametersByEvent, getValueCounts } from "../../redux/configEditor/configEditorActions";
import { ConfigEditorState } from "../../redux/configEditor/interfaces";
import { ReduxAction, RootState } from "../../redux/rootReducer";
import CustomModal from "../../reusables/CustomModal/CustomModal";
import PageTitle from "../../reusables/PageTitle";
import CreateNewQueryFrom from "./CreateNewQueryFrom";

type Props = {
    getParametersByEvent: (event: string) => ReduxAction,
    parameters: ConfigEditorState['parameters'],
    getEventPropertiesValues: () => ReduxAction,
    eventProperties: ConfigEditorState['eventProperties'],
    getValueCounts: (event: string, parameter: string) => ReduxAction,
    valuesCounts: ConfigEditorState['valuesCounts'],
}

type Data = {
    name: string;
    score: number;
}
function NewQuery({
    getParametersByEvent,
    parameters,

    getEventPropertiesValues,
    eventProperties,

    getValueCounts,
    valuesCounts,
}: Props) {
    const [data, setData] = useState<Data[]>([]);
    const [bestScore, setBestScore] = useState(-1.0);
    const [open, setOpen] = useState(false);

    const [event, setEvent] = useState('');
    const [parameter, setParameter] = useState('');
    useEffect(() => {
        getEventPropertiesValues();
    }, []);
    const handleGetValueCount = () => {
        getValueCounts(event, parameter);
        setBestScore(-1.0);
        if (valuesCounts.length > 0) {
            console.log(valuesCounts);
            const datas: Data[] = [];
            valuesCounts.forEach(valuesCount => {
                const data: Data = {
                    name: valuesCount.name,
                    score: valuesCount.clicks,
                }
                datas.push(data);
            });
            let newBestScore = -Infinity
            datas.forEach((data, i) => {
                if (data.score > newBestScore) {
                    newBestScore = data.score;
                    setBestScore(data.score);
                }
            })
            setData(datas);
        }
    }



    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <SideNav />
            </Grid>
            <Grid item xs={9} sx={{ height: '100%' }}>
                <div className="h-screen max-h-screen">
                    <div className="container flex flex-col ">
                        <PageTitle title='Project Data' />
                        <div className="flex flex-col flex-grow">
                            <Paper className="my-3 mx-2 p-3 w-[400px]">
                                <PageTitle title='Choose event and parameter' />
                                <div className="flex flex-col my-1">
                                    <Autocomplete
                                        className="my-1"
                                        key='event'
                                        size='small'
                                        options={eventProperties}
                                        getOptionLabel={(option) => option}
                                        onChange={(event, newValue) => {
                                            setEvent(newValue as string);
                                            getParametersByEvent(newValue as string);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Event"
                                                placeholder="Event" />
                                        )} />
                                    <Autocomplete
                                        className="my-1"
                                        key='parameter'
                                        size='small'
                                        options={parameters}
                                        getOptionLabel={(option) => option}
                                        onChange={(event, newValue) => {
                                            setParameter(newValue as string);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Parameter"
                                                placeholder="Parameter" />
                                        )} />
                                    <div className="mt-2">
                                        <Button variant="outlined" color='primary'
                                            onClick={handleGetValueCount}
                                        >
                                            Get Value Counts
                                        </Button>
                                    </div>
                                </div>
                            </Paper>

                            <Paper
                                className="my-3 mx-2 p-3 w-full h-full"
                            >
                                <PageTitle title='Score Results' />
                                {valuesCounts.length > 0 ?
                                    <div>
                                        <Button
                                            className="my-2"
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setOpen(true)}
                                        > Save Query</Button>
                                        <ResponsiveContainer
                                            key='ResponsiveContainer'
                                            width="100%" height={490}
                                        >
                                            <BarChart
                                                key='BarChart'
                                                data={data}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                                maxBarSize={100}
                                            >
                                                <CartesianGrid
                                                    key='CartesianGrid'
                                                    strokeDasharray="3 3" />
                                                <XAxis
                                                    key='XAxis'
                                                    dataKey="name" />
                                                <YAxis
                                                    key='YAxis'
                                                />
                                                <Tooltip
                                                    key='Tooltip'
                                                />
                                                <Legend
                                                    key='Legend'
                                                    wrapperStyle={{ bottom: '15px' }} />
                                                <Bar
                                                    key='Bar'
                                                    dataKey="score" fill="#8884d8">
                                                    {
                                                        data.map((d, idx) => {
                                                            console.log(d);
                                                            let bestColor = "#5eba7d";
                                                            let otherColor = "#8884d8";
                                                            if (d.score === bestScore) {
                                                                return <Cell key={`Cell_${idx}`} fill={bestColor} />
                                                            } else {
                                                                return <Cell key={`Cell_${idx}`} fill={otherColor} />
                                                            }

                                                        })
                                                    }
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div> : <p>No score Results</p>}
                            </Paper>

                        </div>
                    </div>
                </div>


                <CustomModal open={open} setOpen={setOpen}>
                    <CreateNewQueryFrom  open={open} setOpen={setOpen} valuesCounts={valuesCounts} />
                </CustomModal>
            </Grid>
        </Grid>
    )
}


const mapStateToProps = (rootState: RootState) => {
    const { eventProperties, parameters, valuesCounts } = rootState.configEditor;
    return {
        eventProperties,
        parameters,
        valuesCounts
    }
}

const mapDispatch = {
    getEventPropertiesValues: () => getEventPropertiesValues(),
    getParametersByEvent: (event: string) => getParametersByEvent(event),
    getValueCounts: (event: string, parameter: string) => getValueCounts(event, parameter),
    // saveQueryRequest:(saveQueryRequest:SaveQueryRequest) => saveQueryRequest(saveQueryRequest)
}


export default connect(mapStateToProps, mapDispatch)(NewQuery)