
export function getFileSizeMB(file: File): number {
    const { size } = file;
    const mbs = size / 1024 / 1024;
    return mbs
}





export function getFileSizeMsg(file: File): string {
    const { size } = file;
    const kbs = size / 1024;
    if (kbs < 1000) {
        return `${kbs.toFixed(2)}kb`
    } else {
        return `${(kbs / 1024).toFixed(2)}mb`
    }
}









export function arraysEqual(a: string[] | string, b: string[] | string) {
    /*
        Array-aware equality checker:
        Returns whether arguments a and b are == to each other;
        however if they are equal-lengthed arrays, returns whether their 
        elements are pairwise == to each other recursively under this
        definition.
    */
    if (a instanceof Array && b instanceof Array) {
        if (a.length !== b.length)  // assert same length
            return false;
        for (var i = 0; i < a.length; i++)  // assert each element equal
            if (!arraysEqual(a[i], b[i]))
                return false;
        return true;
    } else {
        return a === b;  // if not both arrays, should be the same
    }
}


export const  BACKEND_URL = 'http://locahost:3001';