import { DesignInterface } from "../../../redux/abtests/interfaces"

type Props = {
    design: DesignInterface | null
}
function ViewDesignJson({ design }: Props) {
    const jsonObject = {
        name: design?.name,
        link: design?.image
    }
    return (
        <div>{JSON.stringify(jsonObject)}</div>
    )
}
export default ViewDesignJson