import axios, { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { setAlert } from "../admin/adminActions";
import { ERR_SNACKBAR, SUCCESS_SNACKBAR } from "../helpers/constants";
import {
  createAbTest,
  createDesignGroup,
  createDesignInDesignGroup,
  deleteAbTest,
  deleteDesign,
  deleteDesignGroup,
  getAbTest,
  getAllAbTests,
  setAbTest,
  setAbTests,
  setDesignGroup,
  updateAbTestComment,
  updateDesign,
  updateDesignGroup,
} from "./abTestsActions";
import {
  AbTestInterface,
  ACTIONS,
  DesignGroup,
  DesignGroupInterface,
  DesignInterface,
  formatAbTestGridRows,
} from "./interfaces";
// GET ALL AB TEST

type GetAllAbTestSaga = {
  status: number;
  message: string;
  data: {
    total: number;
    length: number;
    abTests: AbTestInterface[];
  };
};
type GetAbTestsInDbAction = ReturnType<typeof getAllAbTests>;
function* handleGetAllAbTestsInDb({ payload }: GetAbTestsInDbAction) {
  try {
    const slug = payload.projectSlug;
    const page = payload.page;
    const limit = payload.limit;
    const dir = payload.dir;
    const sortBy = payload.sortBy;

    let url = `/api/v1/abtests/projects/${slug}?page=${page}&limit=${limit}`;
    if(sortBy.length > 0){
      url += `&sort=${sortBy}&dir=${dir}`;
    }

    const res: AxiosResponse<GetAllAbTestSaga> = yield call(axios.get, url);
    if (!res) throw new Error("No result from Request!");
    const { status, message, data } = res.data;
    if (status !== 200) throw new Error(message);

    const { total, length, abTests } = data;

    const gridRows = formatAbTestGridRows(abTests);
    yield put(setAbTests(abTests, gridRows, length, total));
    // yield put(
    //   setAlert({
    //     msg: "A/B Tests Fetched Successfully",
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
  } catch (err: any) {
    console.log("err", err);
    yield put(
      setAlert({
        msg: err.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptGetAllAbTestsInDb() {
  yield takeLatest([ACTIONS.GET_AB_TESTS_IN_DB], handleGetAllAbTestsInDb);
}

// GET SINGLE AB TEST
type GetAbTestResult = {
  status: number;
  message: string;
  data: {
    abTest: AbTestInterface;
    designGroupsResults: DesignGroup[];
  };
};
type GetAbTestInDbAction = ReturnType<typeof getAbTest>;
function* handleGetAbTestInDb({ payload }: GetAbTestInDbAction) {
  try {
    const abTestSlug = payload.abTestSlug;
    const projectSlug = payload.projectSlug;
    const url = `/api/v1/abtests/projects/${projectSlug}/slug/${abTestSlug}/`;
    const res: AxiosResponse<GetAbTestResult> = yield call(axios.get, url);
    if (!res) throw new Error("No result from Request!");
    const { status, message, data } = res.data;
    if (status !== 200) throw new Error(message);

    yield put(setAbTest(data.abTest, data.designGroupsResults));
    // yield put(
    //   setAlert({
    //     msg: "A/B Test Fetched Successfully",
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
  } catch (e: any) {
    console.log("err", e);
    yield put(
      setAlert({
        msg: e.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptGetAbTestInDb() {
  yield takeLatest([ACTIONS.GET_AB_TEST_IN_DB], handleGetAbTestInDb);
}

// CREATE AB TEST
type CreateAbTestResult = {
  status: number;
  message: string;
  data: AbTestInterface;
};
type CreateAbTestsInDbAction = ReturnType<typeof createAbTest>;
function* handleCreateAbTestInDb({ payload }: CreateAbTestsInDbAction) {
  try {
    const url = "/api/v1/abtests";
    const res: AxiosResponse<CreateAbTestResult> = yield call(
      axios.post,
      url,
      payload
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, data } = res.data;
    if (status !== 201) throw new Error(message);
    // yield put(
    //   setAlert({
    //     msg: "A/B Test Created Successfully",
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
    window.location.reload();
  } catch (err: any) {
    yield put(
      setAlert({
        msg: err.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptCreateAbTestInDb() {
  yield takeLatest([ACTIONS.CREATE_AB_TESTS_IN_DB], handleCreateAbTestInDb);
}

// UPDATE AB TEST COMMENT
type UpdateAbTestCommentResult = {
  status: number;
  message: string;
  data: AbTestInterface;
};
type UpdateAbTestCommentInDbAction = ReturnType<typeof updateAbTestComment>;
function* handleUpdateAbTestCommentInDb({
  payload,
}: UpdateAbTestCommentInDbAction) {
  try {
    const url = "/api/v1/abtests/comments/update";
    const res: AxiosResponse<UpdateAbTestCommentResult> = yield call(
      axios.put,
      url,
      payload
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, data } = res.data;
    if (status !== 202) throw new Error(message);
    // yield put(
    //   setAlert({
    //     msg: "A/B Test Updated Successfully",
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
    window.location.reload();
  } catch (err: any) {
    console.log("err", err);
    yield put(
      setAlert({
        msg: err.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptUpdateAbTestCommentInDb() {
  yield takeLatest(
    [ACTIONS.UPDATE_AB_TEST_COMMENT_IN_DB],
    handleUpdateAbTestCommentInDb
  );
}

// DELETE AB TEST
type DeleteAbTestsInDbAction = ReturnType<typeof deleteAbTest>;
function* handleDeleteAbTestInDb({ payload }: DeleteAbTestsInDbAction) {
  try {
    const url = `/api/v1/abtests/${payload.abTestId}`;
    const res: AxiosResponse = yield call(axios.delete, url);
    if (!res) throw new Error("NO RESULT RETURNED FROM API");

    if (res.status !== 204) throw new Error("Cannot delete AB Test");
    // yield put(
    //   setAlert({
    //     msg: "A/B Test Deleted Successfully",
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
    window.history.back();
  } catch (e: any) {
    yield put(
      setAlert({
        msg: e.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}

function* interceptDeleteAbTest() {
  yield takeLatest([ACTIONS.DELETE_AB_TEST_IN_DB], handleDeleteAbTestInDb);
}

// CREATE DESIGN GROUP
type CreateDesignGroupResponse = {
  status: number;
  message: string;
  data: {
    designGroup: DesignGroupInterface;
    designs: DesignInterface[];
  };
};
type CreateDesignGroupInDbAction = ReturnType<typeof createDesignGroup>;
function* handleCreateDesignGroupInDb({
  payload,
}: CreateDesignGroupInDbAction) {
  try {
    const url = "/api/v1/designgroups";

    const { name, abtest, type, images, designs } = payload;
    const form = new FormData();

    form.append("name", name);
    form.append("abTest", abtest);
    form.append("type", type);
    designs.forEach((design) => {
      form.append("designs", design);
    });
    if (type === "image")
      images?.forEach((image) => {
        form.append("image", image);
      });

    const res: AxiosResponse<CreateDesignGroupResponse> = yield call(
      axios.post,
      url,
      form,
      { headers: { "Content-Type": "multipart/form-data" } }
    );

    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, data } = res.data;
    if (status !== 200) throw new Error(message);
    yield put(setDesignGroup(data.designGroup, data.designs));
    // yield put(
    //   setAlert({
    //     msg: "Design Group Created Successfully",
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
    window.location.reload();
  } catch (e: any) {
    console.log("err", e);
    yield put(
      setAlert({
        msg: e.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptCreateDesignGroupInDb() {
  yield takeLatest(
    [ACTIONS.CREATE_DESIGN_GROUP_IN_DB],
    handleCreateDesignGroupInDb
  );
}
// DELETE DESIGN GROUP
type DeleteDesignGroupInDbAction = ReturnType<typeof deleteDesignGroup>;
function* handleDeleteDesignGroupInDb({
  payload,
}: DeleteDesignGroupInDbAction) {
  try {
    const url = `/api/v1/designgroups/${payload.designGroupId}`;
    const res: AxiosResponse = yield call(axios.delete, url);
    if (!res) throw new Error("NO RESULT RETURNED FROM API");

    if (res.status !== 204) throw new Error("Cannot delete AB Test");
    // yield put(
    //   setAlert({
    //     msg: "Design Group Deleted Successfully",
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
    window.location.reload();
  } catch (e: any) {
    yield put(
      setAlert({
        msg: e.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}

// UPDATE DESIGN GROUP
type UpdateDesignGroupResponse = {
  status: number;
  message: string;
  data: DesignGroupInterface;
};
type UpdateDesignGroupInDbAction = ReturnType<typeof updateDesignGroup>;
function* handleUpdateDesignGroupInDb({
  payload,
}: UpdateDesignGroupInDbAction) {
  try {
    const url = `/api/v1/designgroups/${payload.designGroupId}`;
    const data = payload.updateDesignGroupRequest;
    const res: AxiosResponse<UpdateDesignGroupResponse> = yield call(
      axios.put,
      url,
      data
    );
    if (!res) throw new Error("NO result returned from API");
    const { status, message } = res.data;
    if (status !== 202) throw new Error(message);
    // yield put(
    //   setAlert({
    //     msg: "Design Group Updated Successfully",
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
    window.location.reload();
  } catch (e: any) {
    yield put(
      setAlert({
        msg: e.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptUpdateDesignGroupInDb() {
  yield takeLatest(
    [ACTIONS.UPDATE_DESIGN_GROUP_IN_DB],
    handleUpdateDesignGroupInDb
  );
}

function* interceptDeleteDesignGroup() {
  yield takeLatest(
    [ACTIONS.DELETE_DESIGN_GROUP_IN_DB],
    handleDeleteDesignGroupInDb
  );
}
// DELETE DESIGN
type DeleteDesignInDbAction = ReturnType<typeof deleteDesign>;
function* handleDeleteDesignInDb({ payload }: DeleteDesignInDbAction) {
  try {
    const url = `/api/v1/designgroups/designs/${payload.designId}`;
    const res: AxiosResponse = yield call(axios.delete, url);
    if (!res) throw new Error("NO RESULT RETURNED FROM API");

    if (res.status !== 204) throw new Error("Cannot delete Design");
    // yield put(
    //   setAlert({
    //     msg: "Design  Deleted Successfully",
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
    window.location.reload();
  } catch (e: any) {
    yield put(
      setAlert({
        msg: e.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptDeleteDesign() {
  yield takeLatest([ACTIONS.DELETE_DESIGN_IN_DB], handleDeleteDesignInDb);
}

// UPDATE DESIGN
type UpdateDesignResponse = {
  status: number;
  message: string;
  data: DesignInterface;
};
type UpdateDesignInDbAction = ReturnType<typeof updateDesign>;
function* handleUpdateDesignInDb({ payload }: UpdateDesignInDbAction) {
  try {
    const url = `/api/v1/designgroups/designs/${payload.designId}`;

    const { name, image } = payload.updateDesignRequest;
    const form = new FormData();

    form.append("name", name);
    if (image) {
      form.append("image", image);
      form.append("type", "image");
    }

    const res: AxiosResponse<UpdateDesignResponse> = yield call(
      axios.put,
      url,
      form,
      { headers: { "Content-Type": "multipart/form-data" } }
    );

    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message } = res.data;
    if (status !== 202) throw new Error(message);
    // yield put(
    //   setAlert({
    //     msg: "Design  Updated Successfully",
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
    window.location.reload();
  } catch (e: any) {
    console.log("err", e);
    yield put(
      setAlert({
        msg: e.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptUpdateDesignInDb() {
  yield takeLatest([ACTIONS.UPDATE_DESIGN_IN_DB], handleUpdateDesignInDb);
}

// CREATE SINGLE DESIGN IN DESIGN GROUP
type CreateDesignInDesignGroupInDbAction = ReturnType<
  typeof createDesignInDesignGroup
>;

type CreateDesignInDesignGroupResponse = {
  status: number;
  message: string;
  data: DesignInterface;
};

function* handleCreateDesignInDesignGroupInDb({
  payload,
}: CreateDesignInDesignGroupInDbAction) {
  try {
    const designGroupId = payload.designGroupId;
    const design = payload.design;
    const form = new FormData();
    form.append("name", design.name);
    form.append("designGroup", design.designGroup);
    if (design.image) {
      form.append("image", design.image);
      form.append("type", "image");
    }

    const url = `/api/v1/designgroups/${designGroupId}/designs`;
    const res: AxiosResponse<CreateDesignInDesignGroupResponse> = yield call(
      axios.post,
      url,
      form,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, data } = res.data;
    if (status !== 201) throw new Error(message);
    // yield put(
    //   setAlert({
    //     msg: "Design  Created Successfully",
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
    window.location.reload();
  } catch (e: any) {
    yield put(
      setAlert({
        msg: e.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}

function* interceptCreateDesignInDesignGroupInDb() {
  yield takeLatest(
    [ACTIONS.CREATE_DESIGN_IN_DESIGN_GROUP_IN_DB],
    handleCreateDesignInDesignGroupInDb
  );
}

export function* abTestsSagas() {
  yield all([
    call(interceptGetAllAbTestsInDb),
    call(interceptUpdateAbTestCommentInDb),
    call(interceptGetAbTestInDb),
    call(interceptCreateAbTestInDb),
    call(interceptCreateDesignGroupInDb),
    call(interceptDeleteAbTest),
    call(interceptDeleteDesignGroup),
    call(interceptDeleteDesign),
    call(interceptCreateDesignInDesignGroupInDb),
    call(interceptUpdateDesignGroupInDb),
    call(interceptUpdateDesignInDb),
  ]);
}
