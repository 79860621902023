import { ReduxAction } from "../rootReducer"
import { ACTIONS, AdminState, ADMIN_INIT_STATE } from "./interfaces"


export const adminReducer = (state: AdminState = ADMIN_INIT_STATE, action: ReduxAction): AdminState => {
    switch(action.type) {    
        // NORMAL ACTIONS ------------------------------------------------------------------------
        // case ACTIONS.SET_AUTHED:
        //     let { value, username, sessionId, refreshToken } = action.payload;
        //     return {
        //         ...state,
        //         authed: value,
        //         username: value === true ? username : "",
        //         sessionId,
        //         refreshToken
        //     }
        case ACTIONS.SET_ALERT_SAGA:
            return {
                ...state,
                alertMsg: action.payload
            }
       
        case ACTIONS.SET_TOGGLE_THEME:
            return {
                ...state,
                theme: action.payload
            }
        default:
            return { ...state }
    }
}