export const ACTIONS = {
    GET_ALL_PROJECTS_IN_DB: "GET_ALL_PROJECTS_IN_DB",
    CREATE_PROJECT_IN_DB: "CREATE_PROJECT_IN_DB",
    UPDATE_PROJECT_IN_DB:"UPDATE_PROJECT_IN_DB",
    GET_PROJECT_IN_DB: "GET_PROJECT_IN_DB",
    DELETE_PROJECT_IN_DB:"DELETE_PROJECT_IN_DB",


    // SAGA ACTION
    SET_PROJECTS: "SET_PROJECTS",
    SET_PROJECT: "SET_PROJECT"


}

export const PROJECTS_INITIAL_STATE: ProjectsState = {
    projects: [],
    gridRows: [],
    total: 0,
    length: 0,
    project: {
        api: '',
        createdAt: '',
        environmentId: '',
        name: '',
        projectId: '',
        slug: '',
        updatedAt: '',
        abTestNumber: 0,
        __v: 0,
        _id: ''
    }
}

export interface ProjectsState {
    projects: ProjectInterface[],
    gridRows: ProjectGridRow[],
    total: number,
    length: number,
    project: ProjectInterface
}

export interface ProjectInterface {
    api: string,
    createdAt: string,
    environmentId: string
    name: string,
    projectId: string,
    slug: string,
    updatedAt: string,
    abTestNumber: number,
    __v: number,
    _id: string,
}

export interface ProjectGridRow {
    slug: string;
    _id: string;
    name: string;
    createdAt: string;
    abTestNumber: number;
    numberAbTests: number;
}


export function createData(projectInterface: ProjectInterface): ProjectGridRow {
    return {
        slug: projectInterface.slug,
        _id: projectInterface._id,
        name: projectInterface.name,
        createdAt: projectInterface.createdAt,
        abTestNumber: projectInterface.abTestNumber,
        numberAbTests: 0
    }
}


export function formatProjectGridRows(projects: ProjectInterface[]): ProjectGridRow[] {
    return projects.map(project => createData(project));
}

//--------------------------------------------------------------
//--------------------------------------------------------------

export interface CreateProjectRequest {
    name: string,
}
export interface UpdateProjectRequest{
    name?: string,
    
}