export const ACTIONS = {
  GET_REMOTE_CONFIG_IN_DB: "GET_REMOTE_CONFIG_IN_DB",
  GET_ALL_REMOTE_CONFIGS_IN_DB: "GET_ALL_REMOTE_CONFIGS_IN_DB",
  CREATE_REMOTE_CONFIG_IN_DB: "CREATE_REMOTE_CONFIG_IN_DB",
  UPDATE_REMOTE_CONFIG_IN_DB: "UPDATE_REMOTE_CONFIG_IN_DB",
  DELETE_REMOTE_CONFIG_IN_DB: "DELETE_REMOTE_CONFIG_IN_DB",

  // SAGA ACTION
  SET_REMOTE_CONFIGS: "SET_REMOTE_CONFIGS",
  SET_REMOTE_CONFIG: "SET_REMOTE_CONFIG",
};

///////////////// INITIAL STATE
export const REMOTE_CONFIG_INITIAL_STATE: RemoteConfigsState = {
  remoteConfig: {
    key: "",
    type: "",
    disabled: false,
    value: "",
    project: "",
    slug: "",
    updatedAt: "",
    __v: 0,
    _id: "",
  },
  remoteConfigs: [],
  gridRows: [],
  total: 0,
  length: 0,
};

export interface RemoteConfigsState {
  remoteConfig: RemoteConfigInterface;
  remoteConfigs: RemoteConfigInterface[];
  gridRows: RemoteConfigGridRow[];
  total: number;
  length: number;
}

export interface RemoteConfigInterface {
  key: string;
  type: string;
  value: string;
  disabled: boolean;
  project: string;
  slug: string;
  updatedAt: string;
  __v: number;
  _id: string;
}

export interface RemoteConfigGridRow {
  slug: string;
  _id: string;
  key: string;
  disabled: boolean;
  type: string;
  value: string;
}
/////////////

export function createData(
  remoteConfigInterface: RemoteConfigInterface
): RemoteConfigGridRow {
  return {
    slug: remoteConfigInterface.slug,
    _id: remoteConfigInterface._id,
    key: remoteConfigInterface.key,
    disabled: remoteConfigInterface.disabled,
    type: remoteConfigInterface.type,
    value: remoteConfigInterface.value,
  };
}

export function formatRemoteConfigGridRows(
  remoteConfigs: RemoteConfigInterface[]
): RemoteConfigGridRow[] {
  return remoteConfigs.map((remoteConfig) => createData(remoteConfig));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface RemoteConfigRequest {
  key: string;
  type: string;
  value: string;
  projectId: string;
  image?: any;
}

export interface UpdateRemoteConfigRequest {
  value: string;
  image?: any;
}
