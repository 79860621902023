import { MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";

import { DesignGroupInterface, DesignInterface } from "../redux/abtests/interfaces";

type Props = {
    changeRemoteConfigMap: Map<string, boolean>,
    designGroup: DesignGroupInterface,
    designs: DesignInterface[]
}

function AssignAbTestWinnerForm({ changeRemoteConfigMap, designGroup, designs }: Props) {
    const getWinner = (designs: DesignInterface[]) => {
        const sortedArray = designs.sort((a, b) => {
            const numA = parseInt(a.ratio, 10);
            const numB = parseInt(b.ratio, 10);

            if (numA > numB) {
                return -1; // `a` should come before `b`
            } else if (numA < numB) {
                return 1; // `b` should come before `a`
            } else {
                return 0; // no change in order
            }
        });
        return sortedArray[0].name;
    }
    const [value, setValue] = useState<boolean>();
    return (
        <section>

            <p>Change Remote Config for the winner for {designGroup.name} </p>
            <div className="flex flex-row flex-grow">
                <Select
                    className="flex-none m-1"
                    placeholder='Remote Config Boolean Value'
                    label="Remote Config Boolean Value"
                    value={value}
                    name={`type`}
                    sx={{
                        width: '175px',
                    }}
                    onChange={(e: any) => {
                        setValue(e.target.value)
                        changeRemoteConfigMap.set(designGroup._id, e.target.value as boolean);
                    }}
                >
                    <MenuItem selected value='true'>Assign Winner</MenuItem>
                    <MenuItem value='false'>Don't Assign</MenuItem>
                </Select>
                <TextField
                    className="flex-none m-1"
                    placeholder='Modified Remote Config'
                    label="Modified Remote Config"
                    disabled
                    value={designGroup.configEditor}
                />


                <TextField
                    className="flex-none m-1"
                    placeholder='Replaced Value'
                    label="Replaced Value"
                    disabled
                    value={getWinner(designs)}
                />
            </div>
        </section>
    )
}
export default AssignAbTestWinnerForm