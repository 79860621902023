import axios, { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { setAlert } from "../admin/adminActions";
import { ERR_SNACKBAR } from "../helpers/constants";
import {
  assignWinnersToRemoteConfig,
  getAllQueriesResult,
  getDesignEventPropertiesValues,
  getEventPropertiesValues,
  getParametersByEvent,
  getValueCounts,
  saveQueryRequest,
  setAllQueriesResult,
  setEventProperties,
  setParameters,
  setValueCounts,
  startAbTest,
  stopAbTest,
} from "./configEditorActions";
import { ACTIONS, QueryResult, ValueCount } from "./interfaces";

// START AB TEST
type CreateAbTestResult = {
  status: number;
  message: string;
};
type StartAbTestInDbAction = ReturnType<typeof startAbTest>;
function* handleStartAbTestInDb({ payload }: StartAbTestInDbAction) {
  try {
    const url = "/api/v1/configeditor/test/start";
    const res: AxiosResponse<CreateAbTestResult> = yield call(
      axios.post,
      url,
      payload
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message } = res.data;
    if (status !== 201) throw new Error(message);
    window.location.reload();
  } catch (err: any) {
    yield put(
      setAlert({
        msg: err.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}

function* interceptStartAbTestInDb() {
  yield takeLatest([ACTIONS.START_AB_TEST_IN_DB], handleStartAbTestInDb);
}
// STOP AB TEST
type StopAbTestResult = {
  status: number;
  message: string;
};
type StopAbTestInDbAction = ReturnType<typeof stopAbTest>;
function* handleStopAbTestInDb({ payload }: StopAbTestInDbAction) {
  try {
    const url = "/api/v1/configeditor/test/end";
    const res: AxiosResponse<StopAbTestResult> = yield call(
      axios.post,
      url,
      payload
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message } = res.data;
    if (status !== 200) throw new Error(message);
    window.location.reload();
  } catch (err: any) {
    console.log("err", err);
    yield put(
      setAlert({
        msg: err.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptStopAbTestInDb() {
  yield takeLatest([ACTIONS.END_AB_TEST_IN_DB], handleStopAbTestInDb);
}
type GetEventPropertiesInDbAction = ReturnType<typeof getEventPropertiesValues>;
type GetEventPropertiesResult = {
  status: number;
  message: string;
  data: string[];
};
function* handleGetEventPropertiesInDb(get: GetEventPropertiesInDbAction) {
  try {
    const url = "/api/v1/configeditor/eventpropertiesvalues";
    const res: AxiosResponse<GetEventPropertiesResult> = yield call(
      axios.get,
      url
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, data } = res.data;
    if (status !== 200) throw new Error(message);
    yield put(setEventProperties(data));
  } catch (err: any) {
    console.log("err", err);
    yield put(
      setAlert({
        msg: err.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}

function* interceptGetEventPropertiesInDb() {
  yield takeLatest(
    [ACTIONS.GET_EVENT_PROPERTIES_VALUES_IN_DB],
    handleGetEventPropertiesInDb
  );
}

type GetDesignEventPropertiesResult = {
  status: number;
  message: string;
  data: string[];
};
type GetDesignEventPropertiesInDbAction = ReturnType<
  typeof getDesignEventPropertiesValues
>;
function* handleGetDesignEventPropertyValuesInDb({
  payload,
}: GetDesignEventPropertiesInDbAction) {
  try {
    const url = "/api/v1/configeditor/eventpropertiesvalues";
    const res: AxiosResponse<GetDesignEventPropertiesResult> = yield call(
      axios.put,
      url,
      payload
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, } = res.data;
    if (status !== 202) throw new Error(message);
    window.location.reload();
    // yield put(setDeignEventValues())
  } catch (err: any) {
    const url = "/api/v1/configeditor/updateremoteconfigs";
    const res: AxiosResponse<GetDesignEventPropertiesResult> = yield call(
      axios.put,
      url,
      payload
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, } = res.data;
    if (status !== 202) throw new Error(message);
    window.location.reload();
    yield put(
      setAlert({
        msg: err.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}

function* interceptGetDesignEventPropertyValuesInDb() {
  yield takeLatest(
    [ACTIONS.GET_DESIGN_EVENT_PROPERTIES_VALUES_IN_DB],
    handleGetDesignEventPropertyValuesInDb
  );
}
type GetEventParametersResult = {
  status: number;
  message: string;
  data: string[];
};
type GetParameterByEventInDbAction = ReturnType<typeof getParametersByEvent>;
function* handleGetParameterByEvent({
  payload,
}: GetParameterByEventInDbAction) {
  try {
    const event = payload;
    const url = "/api/v1/configeditor/event";
    const res: AxiosResponse<GetEventParametersResult> = yield call(
      axios.post,
      url,
      { event }
    );
    if (!res) throw new Error("No result returned from API");
    const { status, message, data } = res.data;
    if (status !== 200) throw new Error(message);
    yield put(setParameters(data));
  } catch (err: any) {
    yield put(
      setAlert({
        msg: err.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}

function* interceptGetParameterByEvent() {
  yield takeLatest(
    [ACTIONS.GET_PARAMETERS_BY_EVENT],
    handleGetParameterByEvent
  );
}

type AssignWinnersToRemoteConfigAction = ReturnType<
  typeof assignWinnersToRemoteConfig
>;
function* handleAssignWinnersToRemoteConfigInDb({
  payload,
}: AssignWinnersToRemoteConfigAction) {
  try {
    const url = "/api/v1/configeditor/updateremoteconfigs";
    const res: AxiosResponse<GetDesignEventPropertiesResult> = yield call(
      axios.put,
      url,
      payload
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, } = res.data;
    if (status !== 202) throw new Error(message);
    //  window.location.reload();
  } catch (err: any) {
    yield put(
      setAlert({
        msg: err.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptAssignWinnersToRemoteConfigInDb() {
  yield takeLatest(
    [ACTIONS.ASSIGN_WINNER_TO_REMOTE_CONFIG_IN_DB],
    handleAssignWinnersToRemoteConfigInDb
  );
}
type GetValueCountsResult = {
  status: number;
  message: string;
  data: ValueCount[];
};
type GetValueCountsActionInDb = ReturnType<typeof getValueCounts>;
function* handleGetValueCountsInDb({ payload }: GetValueCountsActionInDb) {
  try {
    const url = "/api/v1/configeditor/valuecounts";
    const res: AxiosResponse<GetValueCountsResult> = yield call(
      axios.post,
      url,
      payload
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { message, status, data } = res.data;
    if (status !== 200) throw new Error(message);
    yield put(setValueCounts(data));
  } catch (err: any) {
    yield put(
      setAlert({
        msg: err.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}

function* interceptGetValueCountsInDb() {
  yield takeLatest([ACTIONS.GET_VALUE_COUNTS], handleGetValueCountsInDb);
}


type CreateQueryRequestResult = {
  status: number,
  message: string,
  data: ValueCount[]
}
type SaveQueryRequestInDbAction = ReturnType<typeof saveQueryRequest>
function* handleSaveQueryRequestInDb({payload}:SaveQueryRequestInDbAction){
  try{
    const url = "/api/v1/configeditor/queryrequests";
    const res: AxiosResponse<CreateQueryRequestResult> = yield call(
      axios.post,
      url,
      payload
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { message, status, } = res.data;
    if (status !== 201) throw new Error(message);
    window.location.reload();

  }catch(err:any){
    yield put(
      setAlert({
        msg: err.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptSaveQueryRequestInDb(){
  yield takeLatest([ACTIONS.SAVE_QUERY_REQUEST],handleSaveQueryRequestInDb)
}
type GetAllQueriesResult = {
  status: number,
  message: string,
  data: {
    length: number;
    total: number;
    data:QueryResult [];
  }
}
type GetAllQueriesInDBAction = ReturnType<typeof getAllQueriesResult>
function* handleGetAllQueriesInDb(get: GetAllQueriesInDBAction){
  try{
    const url = "/api/v1/configeditor/queryrequests";
    const res: AxiosResponse<GetAllQueriesResult> = yield call(
      axios.get,
      url);
     if(!res)throw new Error('NO RESULT RETURNED FROM API');
     
     const { message, status, data } = res.data;
     if(status !== 200) throw new Error(message);
     yield put(setAllQueriesResult(data.data));

  }catch(err: any){
    console.log("err", err);
    yield put(
      setAlert({
        msg: err.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptGetAllQueriesInDb(){
  yield takeLatest([ACTIONS.GET_ALL_QUERIES],handleGetAllQueriesInDb)
}

export function* configEditorSagas() {
  yield all([
    call(interceptStartAbTestInDb),
    call(interceptStopAbTestInDb),
    call(interceptGetEventPropertiesInDb),
    call(interceptGetDesignEventPropertyValuesInDb),
    call(interceptAssignWinnersToRemoteConfigInDb),
    call(interceptGetParameterByEvent),
    call(interceptGetValueCountsInDb),
    call(interceptSaveQueryRequestInDb),
    call(interceptGetAllQueriesInDb)
  ]);
}
