import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { setAlert } from "../../../redux/admin/adminActions";
import { AlertMsg } from "../../../redux/admin/interfaces";
import { WARN_SNACKBAR } from "../../../redux/helpers/constants";
import { ReduxAction, RootState } from "../../../redux/rootReducer";
import { getFileSizeMB, getFileSizeMsg } from "../../../reusables/helpers.tsx/utils";

type Itype = "image" | "text" | "float" | "boolean" | "integer";

type Props = {
    designs: string[],
    previewUrls: string[],
    files: File[] | null,
    type: Itype
    open: boolean,
    setAlert: (alertMsg: AlertMsg) => ReduxAction,
    alertMsg: AlertMsg,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function AddOneDesignModal({
    designs,
    files,
    previewUrls,
    type,
    open,
    setOpen,
    setAlert,
    alertMsg
}: Props) {
    const dipatch = useDispatch();
    const [value, setValue] = useState<string>('');
    const [file, setFile] = useState<File | null>(null);


    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [fileSize, setFileSize] = useState('');

    const handleAddDesign = () => {

        if (!value) {
            dipatch(setAlert({
                msg: 'design name required!',
                config: WARN_SNACKBAR
            }));
            return;
        }


        if (String(type) === 'image') {
            if (file) {
                if (getFileSizeMB(file) > 1.6) {
                    setAlert({
                        msg: 'The file size is greater than 1.6MB',
                        config: WARN_SNACKBAR
                    });
                    return;
                }

                designs.push(value);
                files?.push(file);
                if (previewUrl)
                    previewUrls?.push(previewUrl);
            } else {
                dipatch(setAlert({
                    msg: 'image required!',
                    config: WARN_SNACKBAR
                }));
                return;
            }
        } else {
            designs.push(value);
        }

        setValue('');
        setPreviewUrl('');
        setOpen(false)
    }


    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setFile(file);
            const size = getFileSizeMsg(file);
            setFileSize(size);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setPreviewUrl(reader.result as string);
            };


            if (getFileSizeMB(file) > 1.6) {
                dipatch(setAlert({
                    msg: 'The file size is greater than 1.6MB',
                    config: WARN_SNACKBAR
                }));
                return;
            }

        }
    };

    return (
        <div className="flex flex-col">
            {String(type) === 'text' ?
                <section className="my-3">
                    <TextField
                        name="value"
                        label="Design Text Value"
                        key='text-value'
                        value={value}
                        type='text'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(event.target.value);
                        }}
                        required /></section>
                : null}

            {String(type) === 'integer' ?
                <TextField
                    name="value"
                    label="Design Integer Value"
                    key='integer-value'
                    value={value}
                    type='number'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        let eventStr = event.target.value;
                        const eventArr = eventStr.split('.');
                        setValue(eventArr[0]);
                    }}
                    required />
                : null}

            {String(type) === 'float' ? <section className="my-3">
                <TextField
                    name="value"
                    label="Design Float Value"
                    key='float-value'
                    value={value}
                    type='number'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setValue(event.target.value);
                    }}
                    required /></section>
                : null}

            {String(type) === 'boolean' ?
                <section className="my-3">
                    <Select
                        value={value}
                        placeholder='Remote Config Boolean Value'
                        label="Remote Config Boolean Value"
                        key='boolean-value'
                        onChange={(e) => {
                            setValue(e.target.value)
                        }}
                        fullWidth
                    >
                        <MenuItem value='true'>True</MenuItem>
                        <MenuItem value='false'>False</MenuItem>
                    </Select>
                </section> : null
            }
            {String(type) === 'image' ?
                <div className="flex flex-col flex-grow">
                    <section className="p-1">
                        <TextField
                            className="my-3"
                            name="value"
                            label="Design Name"
                            key='design-name-value'
                            value={value}
                            type='text'
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setValue(event.target.value);
                            }}
                            required />
                    </section>
                    <section className="p-1">
                        <Button
                            className="my-3"
                            fullWidth
                            variant="outlined"
                            component="label"
                            disableElevation
                        >
                            Upload File
                            <input
                                type="file"
                                hidden
                                accept=".jpg,.jpeg,.png"
                                onChange={handleFileInputChange}
                            />
                        </Button>
                        <div>
                            {previewUrl && <section className='flex flex-col'>
                                <img src={previewUrl} className="w-[300px] h-[300px]" alt="Preview" />
                                <p className='text-base font-semibold text-[#e4b800]'>{fileSize}</p>
                            </section>
                            }
                        </div>
                    </section>
                </div>
                : null

            }

            <Button
                className="my-3"
                disableElevation
                color='primary'
                variant='contained'
                onClick={handleAddDesign}
            >
                Add Variant
            </Button>
        </div>
    )
}

const mapDispatch = {
    setAlert: (alert: AlertMsg) => setAlert(alert),
}

const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    return { alertMsg }
}

export default connect(mapStateToProps, mapDispatch)(AddOneDesignModal)