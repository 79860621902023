import { all, call } from 'redux-saga/effects';
import { abTestsSagas } from './abtests/abTestsSagas';
import { adminSagas } from './admin/adminSagas';
import { configEditorSagas } from './configEditor/configEditorSagas';
import { navigationSagas } from './navigation/navigationSagas';
import { projectsSagas } from './projects/projectsSagas';
import { remoteConfigsSagas } from './remoteConfigs/remoteConfigSagas';


export default function* rootSaga() {
    yield all([
        call(projectsSagas),
        call(abTestsSagas),
        call(adminSagas),
        // call(externalApiSagas),
        call(remoteConfigsSagas),
        call(navigationSagas),
        call(configEditorSagas)
    ]);
}