import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SideNav from "../../pages/SideNav";
import { ProjectsState } from "../../redux/projects/interfaces";
import { getProject } from "../../redux/projects/projectsActions";
import { ReduxAction, RootState } from "../../redux/rootReducer";
import CustomModal from "../../reusables/CustomModal/CustomModal";
import PageTitle from "../../reusables/PageTitle";
import AbTests from "../AbTests/AbTests";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import DeleteProjectForm from "./DeleteProjectForm";
import UpdateProjectForm from "./UpdateProjectForm";
type Props = {
    getProject: (slug: string) => ReduxAction,
    project: ProjectsState["project"],
}


function Project({
    getProject,
    project,
}: Props) {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    useEffect(() => {
        dispatch(getProject(String(slug)));
    }, []);
    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <SideNav />
            </Grid>
            <Grid item xs={9} sx={{ height: '100%' }}>
                <div className="h-screen max-h-screen">
                    <div className="container flex flex-col ">
                        <BreadCrumb />
                        <PageTitle title={project.name} />
                        <div className="my-3 p-3 flex flex-row flex-grow overflow-x-scroll">
                            <Button
                                onClick={() => { window.location.assign(`/home/projects/${slug}/remoteconfigs`) }}
                                className="mx-2 flex-none"
                                disableElevation
                                variant="contained">Remote Configs
                            </Button>

                            <Button
                                onClick={() => setOpen(true)}
                                className="mx-2 flex-none"
                                disableElevation
                                color='secondary'
                                variant="contained">Update Project
                            </Button>
                            <Button
                                className="mx-2 flex-none"
                                onClick={() => window.location.assign(`/home/projects/${slug}/navigation`)}
                                color='primary'
                                disableElevation
                                variant="contained">App Stores Navigation
                            </Button>
                            <Button
                                className="mx-2 flex-none ml-auto"
                                onClick={() => setOpenDelete(true)}
                                color='error'
                                disableElevation
                                variant="outlined">Delete Project
                            </Button>
                        </div>

                        <PageTitle title='A/B Tests' />
                        <AbTests />
                        <CustomModal open={open} setOpen={setOpen}>
                            <UpdateProjectForm project={project} open={open} setOpen={setOpen} />
                        </CustomModal>

                        <CustomModal open={openDelete} setOpen={setOpenDelete}>
                            <DeleteProjectForm
                                open={openDelete} setOpen={setOpenDelete}
                                projectId={project._id} />
                        </CustomModal>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}
const mapStateToProps = (rootState: RootState) => {
    const { project } = rootState.projects;
    const { alertMsg } = rootState.admin
    return {
        project,
        alertMsg
    }
}

const mapDispatch = {
    getProject: (slug: string) => getProject(slug),
}


export default connect(mapStateToProps, mapDispatch)(Project)