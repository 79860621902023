import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { getFileSizeMsg } from "../../../reusables/helpers.tsx/utils";

type Itype = "image" | "text" | "float" | "boolean" | "integer";

type Props = {
    designs: string[],
    previewUrls: string[],
    files: File[] | null,
    index: number
    type: Itype
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function EditOneDesignModal({ designs,
    files,
    previewUrls,
    type,
    index,
    open,
    setOpen
}: Props) {

    const [value, setValue] = useState<string>('');
    const [file, setFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [fileSize, setFileSize] = useState('');


    const handleEditDesign = () => {
        if (String(type) === 'image') {
            if (file)
                files?.splice(index, 1, file);
            if (previewUrl)
                previewUrls.splice(index, 1, previewUrl);

        }
        designs.splice(index, 1, value);
        setValue('');
        setPreviewUrl('');
        setOpen(false)
    }


    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setFile(file);
            const size = getFileSizeMsg(file);
            setFileSize(size);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setPreviewUrl(reader.result as string);
            };
        }
    };

    return (
        <div className="flex flex-col">
            {String(type) === 'text' ?
                <TextField
                    name="value"
                    label="Design Text Value"
                    key='text-value'
                    value={value}
                    type='text'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setValue(event.target.value);
                    }}
                    required />
                : null}

            {String(type) === 'integer' ?
                <TextField
                    name="value"
                    label="Design Integer Value"
                    key='integer-value'
                    value={value}
                    type='number'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setValue(event.target.value);
                    }}
                    required />
                : null}

            {String(type) === 'float' ?
                <TextField
                    name="value"
                    label="Design Float Value"
                    key='float-value'
                    value={value}
                    type='number'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setValue(event.target.value);
                    }}
                    required />
                : null}

            {String(type) === 'boolean' ?
                <section className="my-3">
                    <Select
                        value={value}
                        placeholder='Remote Config Boolean Value'
                        label="Remote Config Boolean Value"
                        key='boolean-value'
                        onChange={(e) => {
                            setValue(e.target.value)
                        }}
                        fullWidth
                    >
                        <MenuItem value='true'>True</MenuItem>
                        <MenuItem value='false'>False</MenuItem>
                    </Select>
                </section> : null
            }
            {String(type) === 'image' ?
                <div>
                    <section className="p-1">
                        <TextField
                            className="my-3"
                            name="value"
                            label="Design Name"
                            key='design-name-value'
                            value={value}
                            type='text'
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setValue(event.target.value);
                            }}
                            required />
                    </section>
                    <section className="p-1">
                        <Button
                            className="my-3"
                            variant="contained"
                            component="label"
                            disableElevation
                        >
                            Upload File
                            <input
                                type="file"
                                hidden
                                accept=".jpg,.jpeg,.png"
                                onChange={handleFileInputChange}
                            />
                        </Button>
                        <div className="mt-2">
                            {previewUrl && <section className='flex flex-col'>
                                <img src={previewUrl} className="w-[300px] h-[300px]" alt="Preview" />
                                <p className='text-base font-semibold text-[#e4b800]'>{fileSize}</p>
                            </section>
                            }
                        </div>
                    </section>
                </div>
                : null

            }

            <Button
                className="my-3"
                disableElevation
                color='primary'
                variant='contained'
                onClick={handleEditDesign}
            >
                Edit Design
            </Button>
        </div>
    )
}
export default EditOneDesignModal