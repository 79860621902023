import { Button, TextField } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../redux/admin/adminActions";
import { AlertMsg } from "../../redux/admin/interfaces";
import { ProjectInterface, UpdateProjectRequest } from "../../redux/projects/interfaces";
import { updateProject } from "../../redux/projects/projectsActions";
import { ReduxAction, RootState } from "../../redux/rootReducer";
import PageTitle from "../../reusables/PageTitle";
import './CreateProjectForm.css';

type Props = {
    updateProject: (p: UpdateProjectRequest, projectId: string) => ReduxAction,
    setAlert: (alert: AlertMsg) => ReduxAction,
    project: ProjectInterface,
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>> | ((b: boolean) => ReduxAction),
}

function UpdateProjectForm(
    {
        updateProject,
        setAlert,
        project,
        open,
        setOpen
    }: Props) {
    const [error, setError] = useState<boolean>(true);
    const [name, setName] = useState<string>(project.name);
    const handleDismiss = () => {
        setOpen(false);
    }
    const handleUpdateProject = () => {
        // TODO create alert action
        const body: UpdateProjectRequest = {

        }
        if (name) body['name'] = name;
        // create body to send to action 
        updateProject(body, project._id);
    }
    return (
        <div className="flex flex-col  p-2">

            <PageTitle title="Project Fields" />
            <section>
                <TextField
                    className="mt-3"
                    fullWidth
                    required
                    error={!name}
                    label="Name"
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                    }}
                />
            </section>
            <div>
                <Button
                    className="mt-3"
                    onClick={handleUpdateProject}
                    disableElevation
                    fullWidth
                    sx={{ fontSize: '15px' }}
                    variant="contained">
                    Update  Project
                </Button>
                <Button
                    className="mt-3"
                    onClick={handleDismiss}
                    disableElevation
                    fullWidth
                    variant="outlined">
                    Cancel
                </Button>
            </div>
        </div>
    )
}
const mapDispatch = {
    updateProject: (p: UpdateProjectRequest, projectId: string) => updateProject(p, projectId),
    setAlert: (p: AlertMsg) => setAlert(p)
}
const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    return {
        alertMsg
    }
}


export default connect(mapStateToProps, mapDispatch)(UpdateProjectForm)
