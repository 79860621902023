// DATABASE CRUD ACTION

import {
  ACTIONS,
  RemoteConfigGridRow,
  RemoteConfigInterface,
  RemoteConfigRequest,
  UpdateRemoteConfigRequest,
} from "./interfaces";

export function getAllRemoteConfigs(
  projectId: string,
  searchField: string,
  page: number,
  limit: number,
  sortBy: string,
  dir: string
) {
  return {
    type: ACTIONS.GET_ALL_REMOTE_CONFIGS_IN_DB,
    payload: { projectId, searchField, page, limit, sortBy, dir },
  };
}



export function createRemoteConfig(remoteConfig: RemoteConfigRequest) {
  return {
    type: ACTIONS.CREATE_REMOTE_CONFIG_IN_DB,
    payload: remoteConfig,
  };
}

export function updateRemoteConfig(
  remoteConfig: UpdateRemoteConfigRequest,
  remoteConfigId: string
) {
  return {
    type: ACTIONS.UPDATE_REMOTE_CONFIG_IN_DB,
    payload: { remoteConfig, remoteConfigId },
  };
}

export function deleteRemoteConfig(remoteConfigId: string) {
  return {
    type: ACTIONS.DELETE_REMOTE_CONFIG_IN_DB,
    payload: remoteConfigId,
  };
}

// SAGA ACTIONS
export function setRemoteConfigs(
  remoteConfigs: RemoteConfigInterface[],
  gridRows: RemoteConfigGridRow[],
  length: number,
  total: number
) {
  return {
    type: ACTIONS.SET_REMOTE_CONFIGS,
    payload: { remoteConfigs, gridRows, length, total },
  };
}

