
import AddIcon from '@mui/icons-material/Add';
import { Card } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
type Props = {
    setOpen: Dispatch<SetStateAction<boolean>>,
    // theme: AdminState["theme"]
}

function AddCard({
    setOpen,
}:Props){
    return (
        <Card onClick={() => setOpen(true)} elevation={3} 
        // className={theme === "light" ? s.addCardLight : s.addCardDark}
        className='w-56 flex flex-col items-center 
        justify-center cursor-pointer 
        flex-shrink-0 bg-gray-300
        h-[405px]
        my-3
        '
        >
            <AddIcon sx={{ fontSize: "150px" }} />
            <h3 className='text-blue-600'>ADD VARIANT</h3>
        </Card>
    )
}
export default AddCard