import axios, { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { setAlert, setAlertSaga } from "../admin/adminActions";
import { ERR_SNACKBAR, SUCCESS_SNACKBAR } from "../helpers/constants";
import {
  ACTIONS,
  formatProjectGridRows,
  ProjectInterface,
  UpdateProjectRequest,
} from "./interfaces";
import {
  createProject,
  deleteProject,
  getAllProjects,
  getProject,
  setProject,
  setProjects,
  updateProject,
} from "./projectsActions";

/** GET ALL PROJECTS */
type GetAllProjectsResponse = {
  status: number;
  message: string;
  data: {
    total: number;
    length: number;
    data: ProjectInterface[];
  };
};

// GET ALL PROJECTS
type GetAllProjectInDbAction = ReturnType<typeof getAllProjects>;
function* handleGetAllProjectsInDb({ payload }: GetAllProjectInDbAction) {
  try {
    const page = payload.page;
    const limit = payload.limit;
    const dir = payload.dir;
    const sortBy = payload.sortBy;

    let url = `/api/v1/projects?page=${page}&limit=${limit}`;
    if(sortBy.length > 0){
      url += `&dir=${dir}&sort=${sortBy}`;
    }
    const res: AxiosResponse<GetAllProjectsResponse> = yield call(
      axios.get,
      url
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, data } = res.data;
    if (status !== 200) throw new Error(message); 
    const projects = data.data;
    const total = data.total;
    const length = data.length;
    const gridRows = formatProjectGridRows(projects);
    yield put(setProjects(projects, gridRows, length, total));
    // yield put(
    //   setAlert({
    //     msg: 'Projects Fetched Successfully',
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
  } catch (err: any) {
    console.log("err", err);
    yield put(
      setAlert({
        msg: err.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}

function* interceptGetProjectsInDb() {
  yield takeLatest([ACTIONS.GET_ALL_PROJECTS_IN_DB], handleGetAllProjectsInDb);
}

// GET PROJECT

type GetProjectInDbAction = ReturnType<typeof getProject>;
interface GetProjectResponse {
  status: number;
  message: string;
  data: ProjectInterface;
}
function* handleGetProjectInDb({ payload }: GetProjectInDbAction) {
  try {
    const slug = payload;

    const url = `/api/v1/projects/${slug}`;
    const res: AxiosResponse<GetProjectResponse> = yield call(axios.get, url);
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, data } = res.data;
    if (status !== 200) throw new Error(message);
    yield put(setProject(data));
    // yield put(
    //   setAlert({
    //     msg: 'Project Fetched Successfully',
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
  } catch (e: any) {
    console.log(e);
    yield put(
      setAlert({
        msg: e.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptGetProjectInDb() {
  yield takeLatest([ACTIONS.GET_PROJECT_IN_DB], handleGetProjectInDb);
}

// CREATE PROJECT

type CreateProjectInDbAction = ReturnType<typeof createProject>;
interface CreateProjectResponse {
  status: number;
  message: string;
  data: ProjectInterface;
}
function* handleCreateProjectInDb(action: CreateProjectInDbAction) {
  try {
    const project = action.payload;
    const url = "/api/v1/projects";
    const res: AxiosResponse<CreateProjectResponse> = yield call(
      axios.post,
      url,
      {
        name: project.name,
      }
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, data } = res.data;
    if (status !== 201) throw new Error(message);
    // yield put(
    //   setAlert({
    //     msg: 'Project Created Successfully',
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );  
    window.location.reload();
  } catch (e: any) {
    console.log(e);
    yield put(
      setAlert({
        msg: e.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptCreateProjectInDb() {
  yield takeLatest([ACTIONS.CREATE_PROJECT_IN_DB], handleCreateProjectInDb);
}
// UPDATE PROJECT
type UpdateProjectInDbAction = ReturnType<typeof updateProject>;
function* handleUpdateProjectInDb({ payload }: UpdateProjectInDbAction) {
  try {
    const project = payload.body;
    const body: UpdateProjectRequest = {};
    if (project.name) body["name"] = project.name;

    const projectId = payload.projectId;
    const url = `/api/v1/projects/${projectId}`;
    const res: AxiosResponse<CreateProjectResponse> = yield call(
      axios.put,
      url,
      body
    );
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, data } = res.data;
    if (status !== 202) throw new Error(message);
    // yield put(
    //   setAlert({
    //     msg: 'Project Updated Successfully',
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );  
    window.location.replace("/home/projects");
  } catch (e: any) {
    console.log(e);
    yield put(
      setAlert({
        msg: e.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptUpdateProjectInDb() {
  yield takeLatest([ACTIONS.UPDATE_PROJECT_IN_DB], handleUpdateProjectInDb);
}

// DELETE PROJECT

type DeleteProjectInDbAction = ReturnType<typeof deleteProject>;
function* handleDeleteProjectInDb({ payload }: DeleteProjectInDbAction) {
  try {
    const projectId = payload.projectId;
    const url = `/api/v1/projects/${projectId}`;
    const res: AxiosResponse = yield call(axios.delete, url);
    if (!res) throw new Error("NO RESULT RETURNED FROM API!");
    if (res.status !== 204) throw new Error("DELETE API RESULT NOT INTENDED!");
    window.location.replace("/home/projects");
    // yield put(
    //   setAlert({
    //     msg: 'Project Deleted Successfully',
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );  
  } catch (e: any) {
    console.log(e);
    yield put(
      setAlert({
        msg: e.response.data.message,
        config: ERR_SNACKBAR,
      })
    );
  }
}
function* interceptDeleteProjectInDb() {
  yield takeLatest([ACTIONS.DELETE_PROJECT_IN_DB], handleDeleteProjectInDb);
}

export function* projectsSagas() {
  yield all([
    call(interceptGetProjectsInDb),
    call(interceptGetProjectInDb),
    call(interceptUpdateProjectInDb),
    call(interceptCreateProjectInDb),
    call(interceptDeleteProjectInDb),
  ]);
}
