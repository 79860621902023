import TextField from "@mui/material/TextField";
type Props = {
  setSearchQuery : React.Dispatch<React.SetStateAction<string>>,
  label: string,
  buttonClick: () => void
}

function SearchInput({ setSearchQuery, label, buttonClick }: Props) {

  return (
      <TextField
        id="search-bar"
        fullWidth
        className="mr-2"
        onChange={(e) => {
          setSearchQuery(e.target.value);
          buttonClick();
        }}
        label={label}
        variant="outlined"
        placeholder="Search..."
        size="small"
      />
  );
}
export default SearchInput;
