import { DesignGroup } from "../../redux/abtests/interfaces"
import EventPropertyDataInput from "./EventPropertyDataInput"

type Props = {
    designNames: string[],
    eventProperties: string[]
    designGroupsResults: DesignGroup[]
}

function DesignPropertyData({ designNames , eventProperties, designGroupsResults }: Props) {
    return (<div className="flex flex-row flex-grow">
        {designNames.map((design,i) =>{
            return <EventPropertyDataInput 
            key={i} 
            designName={design}
            eventProperties={eventProperties}
            designGroup={designGroupsResults[i].designGroup}
            />
        })}
    </div>)
}
export default DesignPropertyData