import { ReduxAction } from "../rootReducer";
import {
  ACTIONS,
  ConfigEditorState,
  CONFIG_EDITOR_INITIAL_STATE,
} from "./interfaces";

export const configEditorReducer = (
  state: ConfigEditorState = CONFIG_EDITOR_INITIAL_STATE,
  action: ReduxAction
): ConfigEditorState => {
  switch (action.type) {
    // SAGA ACTIONS ------------------------------------------------------------------------
    case ACTIONS.SET_START_AB_TEST_SUCCESS:
      return {
        ...state,
        status: state.status,
        message: state.message,
      };
    case ACTIONS.SET_EVENT_PROPERTIES_VALUES:
      return {
        ...state,
        status: state.status,
        message: state.message,
        eventProperties: action.payload.eventProperties,
      };

    case ACTIONS.SET_PARAMETERS_BY_EVENT:
      return {
        ...state,
        status: state.status,
        message: state.message,
        parameters: action.payload.parameters,
      };
      case ACTIONS.SET_VALUE_COUNTS:
      return {
        ...state,
        status: state.status,
        message: state.message,
        valuesCounts: action.payload.valuesCounts,
      };
      case ACTIONS.SET_ALL_QUERIES:
      return {
        ...state,
        status: state.status,
        message: state.message,
        queryResult: action.payload.queriesResult,
      };
    default:
      return { ...state };
  }
};
