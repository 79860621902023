import { Button, Card, CardActions, CardContent, CardMedia, IconButton, Tooltip } from "@mui/material";
import { type } from "@testing-library/user-event/dist/type";
import { useState } from "react";
import AddOneDesignToDesignGroupForm from "../components/AbTests/modals/AddOneDesignToDesignGroupForm";
import DeleteDesignForm from "../components/AbTests/modals/DeleteDesignForm";
import EditOneDesignModal from "../components/AbTests/modals/EditOneDesignModal";
import UpdateOneDesignInDesignGroupForm from "../components/AbTests/modals/UpdateOneDesignInDesignGroupForm";
import ViewDesignJson from "../components/AbTests/modals/ViewDesignJson";
import { DesignGroupInterface, DesignInterface } from "../redux/abtests/interfaces"
import CustomModal from "./CustomModal/CustomModal";
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

type Props = {
    design: DesignInterface,
    designGroup: DesignGroupInterface,
    isWinner?: boolean
    testStarted: boolean
}

function GotDesignCard({
    design,
    designGroup,
    isWinner,
    testStarted
}: Props) {
    const [open, setOpen] = useState(false);
    const [designText, setDesignText] = useState<string>(design.name);


    const [deleteDesignOpen, setDeleteDesignOpen] = useState(false);
    const [updateDesignOpen, setUpdateDesignOpen] = useState(false);
    const [openViewDesign, setOpenViewDesign] = useState(false);
    const [openAddSignleDesignToDesignGroup, setOpenAddSignleDesignToDesignGroup] = useState(false);


    const DesignText = () => (
        designText && designText.length > 140 ? (
            <Tooltip title={designText}>
                <div className="
                h-36 p-3 bg-gray-200
                font-bold text-left overflow-hidden"
                    style={{
                        width: 'calc(100 %)',
                        borderBottom: '2px solid #1a1a1a',
                        backgroundColor: isWinner ? 'rgb(94, 186, 125)' : 'transparent'
                    }}
                >
                    <div>
                        {designText}
                    </div>
                </div>
            </Tooltip >
        ) : (
            <div className="
        h-36 p-3 bg-gray-200
        font-bold text-left overflow-hidden"
                style={{
                    width: 'calc(100 %)',
                    borderBottom: '2px solid #1a1a1a',
                    backgroundColor: isWinner ? 'rgb(94, 186, 125)' : 'transparent'
                }}>
                <div>
                    {designText}
                </div>
            </div>
        )
    );
    return (
        <Card
            style={{ backgroundColor: isWinner ? 'rgb(94, 186, 125)' : 'transparent' }}
            className="w-56 mx-2 flex-shrink-0 relative h-[405px] p-0"
        >
            {
                !design.image ? (
                    <CardMedia
                        component={DesignText} />
                ) : (
                    <CardMedia
                        component="img"
                        alt="design img"
                        image={design.image}
                        className="object-contain object-top"
                    />
                )
            }
            <div className="absolute w-full"
                style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.13)',
                    bottom: 0
                }}
            >
                <CardContent className="pb-2"
                //  sx={theme === "dark" ? darkModeCardContent : {}}
                >
                    <p className="text-xl font-bold">{designText}</p>
                    <p className="text-sm">Clicks: {design.clicks}</p>
                    <p className="text-sm">Properties: {design.properties}</p>
                    <p className="text-sm font-medium">Ratio: {design.ratio}</p>
                </CardContent>

                <CardActions>
                    <IconButton
                        disabled={testStarted}
                        size="small"
                        className="text-xl"
                        color="warning"
                        onClick={() => setUpdateDesignOpen(true)}
                    ><EditIcon /></IconButton>
                    <IconButton
                        disabled={testStarted}
                        size="small"
                        color="error"
                        className="text-xl"
                        onClick={() => setDeleteDesignOpen(true)}
                    ><DeleteIcon /></IconButton>
                    {designGroup.type === 'image' ?
                        <IconButton
                            disabled={testStarted}
                            size="small"
                            color="primary"
                            className="text-xl"
                            onClick={() => setOpenViewDesign(true)}
                        ><InfoIcon /></IconButton> : null}
                </CardActions>
            </div>



            {/* SIGNLE DESIGN MODALS  */}
            <CustomModal open={openViewDesign} setOpen={setOpenViewDesign}>
                <ViewDesignJson design={design} />
            </CustomModal>


            <CustomModal open={deleteDesignOpen} setOpen={setDeleteDesignOpen}>
                <DeleteDesignForm designId={design?._id as string}
                    open={deleteDesignOpen} setOpen={setDeleteDesignOpen}
                />
            </CustomModal>

            <CustomModal open={updateDesignOpen} setOpen={setUpdateDesignOpen}>
                <UpdateOneDesignInDesignGroupForm design={design} designGroup={designGroup}
                    open={updateDesignOpen} setOpen={setUpdateDesignOpen}
                />
            </CustomModal>

            <CustomModal open={openAddSignleDesignToDesignGroup} setOpen={setOpenAddSignleDesignToDesignGroup}>
                <AddOneDesignToDesignGroupForm designGroup={designGroup as DesignGroupInterface}
                    open={openAddSignleDesignToDesignGroup} setOpen={setOpenAddSignleDesignToDesignGroup}
                />
            </CustomModal>

        </Card>
    )
}
export default GotDesignCard