export const ACTIONS = {
  GET_AB_TESTS_IN_DB: "GET_AB_TESTS_IN_DB",
  CREATE_AB_TESTS_IN_DB: "CREATE_AB_TESTS_IN_DB",
  UPDATE_AB_TEST_IN_DB: "UPDATE_AB_TEST_IN_DB",
  UPDATE_AB_TEST_COMMENT_IN_DB: "UPDATE_AB_TEST_COMMENT_IN_DB",
  GET_AB_TEST_IN_DB: "GET_AB_TEST_IN_DB",
  DELETE_AB_TEST_IN_DB: "DELETE_AB_TEST_IN_DB",
  CREATE_DESIGN_GROUP_IN_DB: "CREATE_DESIGN_GROUP_IN_DB",
  UPDATE_DESIGN_GROUP_IN_DB: "UPDATE_DESIGN_GROUP_IN_DB",
  DELETE_DESIGN_GROUP_IN_DB: "DELETE_DESIGN_GROUP_IN_DB",
  DELETE_DESIGN_IN_DB: "DELETE_DESIGN_IN_DB",
  UPDATE_DESIGN_IN_DB: "UPDATE_DESIGN_IN_DB",
  CREATE_DESIGN_IN_DESIGN_GROUP_IN_DB: "CREATE_DESIGN_IN_DESIGN_GROUP_IN_DB",

  // SAGA ACTION
  SET_AB_TESTS: "SET_AB_TESTS",
  SET_AB_TEST: "SET_AB_TEST",
  SET_DESIGNS: "SET_DESIGNS",
};

// MAIN INTERFACES
export const AB_TEST_INITIAL_STATE: AbTestState = {
  abTests: [],
  gridRows: [],
  total: 0,
  length: 0,

  designGroup: {
    designs: [],
    designGroup: {
      _id: "",
      name: "",
      type: "",
      configEditor: "",
      event: "",
      eventName: "",
      property: "",
      parameter: "",
      createdAt: "",
      updatedAt: "",
      slug: "",
    },
  },
  data: {
    abTest: {
      _id: "",
      name: "",
      slug: "",
      comments: "",
      startDate: "",
      beforeAbTestSlug: "",
      afterAbTestSlug: "",
      event: "",
      eventName: "",
      endDate: "",
      project: "",
      createdAt: "",
      integrationId: "",
      updatedAt: "",
      designs: 0,
      configEditors: [],
    },
    designGroupsResults: [],
  },
};

export interface AbTestState {
  abTests: AbTestInterface[];
  gridRows: AbTestGridRow[];
  total: number;
  length: number;
  designGroup: DesignGroup;
  data: {
    abTest: AbTestInterface;
    designGroupsResults: DesignGroup[];
  };
}

export interface DesignGroup {
  designGroup: DesignGroupInterface;
  designs: DesignInterface[];
}

export interface AbTestInterface {
  _id: string;
  name: string;
  slug: string;
  comments: string;
  project: string;
  beforeAbTestSlug: string;
  afterAbTestSlug: string;
  startDate: string;
  eventName: string;
  event: string;
  endDate: string;
  createdAt: string;
  integrationId: string;
  updatedAt: string;
  designs: number;
  configEditors: string[];
}

export interface AbTestGridRow {
  _id: string;
  name: string;
  slug: string;
  comments: string;
  startDate: string;
  project: string;
  integrationId: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  designs: number;
}

export interface DesignGroupInterface {
  _id: string;
  name: string;
  type: string;
  configEditor: string;
  eventName: string;
  event: string;
  property: string;
  parameter: string;
  createdAt: string;
  updatedAt: string;
  slug: string;
}

export interface DesignInterface {
  _id: string;
  name: string;
  slug: string;
  clicks: number;
  properties: number;
  ratio: string;
  createdAt: string;
  updatedAt: string;
  image?: string;
}

export function createData(abTestInterface: AbTestInterface): AbTestGridRow {
  return {
    slug: abTestInterface.slug,
    _id: abTestInterface._id,
    name: abTestInterface.name,
    integrationId: abTestInterface.integrationId, 
    startDate: abTestInterface.startDate ? new Date(abTestInterface.startDate).toISOString() : '' ,
    endDate: abTestInterface.endDate ? new Date(abTestInterface.endDate).toISOString() : '' ,
    project: abTestInterface.project,
    comments: abTestInterface.comments,
    createdAt: abTestInterface.createdAt,
    designs: abTestInterface.designs,
    updatedAt: abTestInterface.updatedAt,
  };
}

export function formatAbTestGridRows(
  abTests: AbTestInterface[]
): AbTestGridRow[] {
  return abTests.map((abTest) => createData(abTest));
}

// REQUESTS

export interface CreateAbTestRequest {
  project: string;
  name: string;
  comments: string;
}

export interface UpdateAbTestCommentRequest {
  abTestId: string,
  comment: string
}


export interface CreateDesignGroupRequest {
  name: string;
  abtest: string;
  type: string;
  designs: string[];
  images?: any[];
}

export interface DesignRequest {
  name: string;
  image?: any;
  designGroup: string;
}

export interface UpdateDesignGroupRequest {
  name: string;
}

export interface UpdateDesignRequest {
  name: string;
  image?: any;
}



export interface GetDesignEventPropertiesRequest {
  projectSlug: string;
  designGroupId: string;
  event: string;
  property: string;
  parameter: string;
}
