import AddBoxIcon from '@mui/icons-material/AddBox';
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { connect } from 'react-redux';
import { createDesignInDesignGroup, updateDesign } from '../../../redux/abtests/abTestsActions';
import { DesignGroupInterface, DesignInterface, DesignRequest, UpdateDesignRequest } from "../../../redux/abtests/interfaces";
import { setAlert } from '../../../redux/admin/adminActions';
import { AlertMsg } from '../../../redux/admin/interfaces';
import { ReduxAction, RootState } from '../../../redux/rootReducer';
import { getFileSizeMsg } from '../../../reusables/helpers.tsx/utils';
import PageTitle from "../../../reusables/PageTitle";

type Itype = "image" | "text" | "float" | "boolean" | "integer";

type Props = {
    updateDesign: (designGroupId: string,design: UpdateDesignRequest) => ReduxAction,
    open: boolean,
    design: DesignInterface | null,

    designGroup: DesignGroupInterface | null,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function UpdateOneDesignInDesignGroupForm({ 
    updateDesign,
    design,
    designGroup,
    open, 
    setOpen
}: Props) {

    const [type, setType] = useState<Itype>(designGroup?.type as Itype);
    const [boolValue, setBoolValue] = useState<string>('true');
    const [value, setValue] = useState<string>(design?.name as string);



    // IMAGE UPLOAD VARS
    const [file, setFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [fileSize, setFileSize] = useState('');


    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setFile(file);
            const size = getFileSizeMsg(file);
            setFileSize(size);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setPreviewUrl(reader.result as string);
            };
        }
    };

    const handleUpdateDesignInDesignGroup = () => {
        const designRequest: UpdateDesignRequest = {
            name: value,
        }
        if(file)
        designRequest.image = file;

        updateDesign(design?._id as string,designRequest);
    }
    const handleDismiss = () => {
        setOpen(false);
    }

    return (
        <div>
            <PageTitle title={`Update design fields`} />

            {String(type) === 'text' ?
                <section className="my-3">
                    <TextField
                        name="value"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(event.target.value);
                        }}
                        label="Deign Text Value"
                        value={value}
                        fullWidth
                        required
                    />
                </section> : null
            }
            {String(type) === 'integer' ?
                <section className="my-3">
                    <TextField
                        name="value"
                        type='number'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(event.target.value);
                        }}
                        label="Design Integer Value"
                        value={value}
                        fullWidth
                        required
                    />
                </section> : null
            }
            {String(type) === 'float' ?
                <section className="my-3">
                    <TextField
                        name="value"
                        type='number'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(event.target.value);
                        }}
                        label="Design Float Value"
                        value={value}
                        fullWidth
                        required
                    />
                </section> : null
            }
            {String(type) === 'boolean' ?
                <section className="my-3">
                    <Select
                        value={boolValue}
                        placeholder='Design Boolean Value'
                        label="Design Boolean Value"
                        name='type'
                        onChange={(e) => {
                            setBoolValue(e.target.value)
                        }}
                        fullWidth
                    >
                        <MenuItem value='true'>True</MenuItem>
                        <MenuItem value='false'>False</MenuItem>
                    </Select>
                </section> : null
            }

            {String(type) === 'image' ?
                <section className="my-3">

                    <TextField
                        name="value"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(event.target.value);
                        }}
                        label="Deign Value"
                        value={value}
                        fullWidth
                        required
                    />

                    <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        disableElevation
                    >
                        Upload File <AddBoxIcon />
                        <input
                            type="file"
                            hidden
                            accept=".jpg,.jpeg,.png"
                            onChange={handleFileInputChange}
                        />
                    </Button>
                    <div className="mt-2">
                            {previewUrl && <section className='flex flex-col'>
                                <img src={previewUrl} className="w-[300px] h-[300px]" alt="Preview" />
                                <p className='text-base font-semibold text-[#e4b800]'>{fileSize}</p>
                            </section>
                            }
                        </div>
                </section>
                : null
            }

            <Button
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleUpdateDesignInDesignGroup}
            >Update Design Group</Button>
            <Button
                className="mt-3"
                onClick={handleDismiss}
                disableElevation
                fullWidth
                variant="outlined">
                Cancel
            </Button>
        </div>
    )
    }

    const mapDispatch = {
        updateDesign: (designGroupId: string,design: UpdateDesignRequest) => updateDesign(designGroupId,design),
        setAlert: (p: AlertMsg) => setAlert(p)
    }
    const mapStateToProps = (rootState: RootState) => {
        const { alertMsg } = rootState.admin;
        return {
            alertMsg,
        }
    }
export default connect(mapStateToProps,mapDispatch)(UpdateOneDesignInDesignGroupForm)