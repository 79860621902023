import { Button, TextField } from "@mui/material"
import { Dispatch, SetStateAction, useState } from "react"
import { connect } from "react-redux"
import { saveQueryRequest } from "../../redux/configEditor/configEditorActions"
import { SaveQueryRequest, ValueCount } from "../../redux/configEditor/interfaces"
import { ReduxAction } from "../../redux/rootReducer"
import PageTitle from "../../reusables/PageTitle"

type Props = {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>> | ((b: boolean) => ReduxAction),
    valuesCounts: ValueCount[]
    saveQueryRequest: (saveQueryRequest: SaveQueryRequest) => ReduxAction
}


function CreateNewQueryFrom({
    open,
    setOpen,
    valuesCounts,
    saveQueryRequest
}: Props) {
    const [name,setName] = useState('');
    const handleSaveQuery = ()=> {
        const queryRequest : SaveQueryRequest = {
            name,
            valuesCounts
        }

        saveQueryRequest(queryRequest);

    }
    
    const handleDismiss = () => {
        setOpen(false);
    }

    return (
        <div className="flex flex-col  p-2">
            <PageTitle title='New Query Fields' />
            <section>
                <TextField
                    className="mt-3"
                    fullWidth
                    required
                    error={!name}
                    label="Name"
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                    }}
                />
            </section>
            <Button
                className="mt-3"
                onClick={handleSaveQuery}
                disableElevation
                fullWidth
                variant="contained">
                Create New Query
            </Button>
            <Button
                className="mt-3"
                onClick={handleDismiss}
                disableElevation
                fullWidth
                variant="outlined">
                Cancel
            </Button>

        </div>
    )
}

const mapDispatch = {
    saveQueryRequest: (p: SaveQueryRequest) => saveQueryRequest(p),
}

export default connect(null, mapDispatch)(CreateNewQueryFrom)