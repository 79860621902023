import AddBoxIcon from '@mui/icons-material/AddBox';
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { connect } from 'react-redux';
import { createDesignInDesignGroup } from '../../../redux/abtests/abTestsActions';
import { DesignGroupInterface, DesignRequest } from "../../../redux/abtests/interfaces";
import { setAlert } from '../../../redux/admin/adminActions';
import { AlertMsg } from '../../../redux/admin/interfaces';
import { WARN_SNACKBAR } from '../../../redux/helpers/constants';
import { ReduxAction, RootState } from '../../../redux/rootReducer';
import { getFileSizeMB, getFileSizeMsg } from '../../../reusables/helpers.tsx/utils';
import PageTitle from "../../../reusables/PageTitle";

type Itype = "image" | "text" | "float" | "boolean" | "integer";

type Props = {
    createDesignInDesignGroup: (designGroupId: string, design: DesignRequest) => ReduxAction,
    open: boolean,
    designGroup: DesignGroupInterface | null,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function AddOneDesignToDesignGroupForm({
    createDesignInDesignGroup,

    designGroup,
    open,
    setOpen
}: Props) {

    const [type, setType] = useState<Itype>(designGroup?.type as Itype);
    const [boolValue, setBoolValue] = useState<string>('true');
    const [value, setValue] = useState<string>('');



    // IMAGE UPLOAD VARS
    const [file, setFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [fileSize, setFileSize] = useState<string>('');

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setFile(file);
            const size = getFileSizeMsg(file);
            setFileSize(size);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setPreviewUrl(reader.result as string);
            };
        }
    };

    const handleCreateDesignInDesignGroup = () => {
        if (file)
            if (getFileSizeMB(file) > 1.6) {
                setAlert({ msg: "The file size is greater than 1.6MB", config: WARN_SNACKBAR });
                // setWarning("image larger than 1.6MB")
                return
            }


        const designRequest: DesignRequest = {
            designGroup: designGroup?._id as string,
            name: value,
        }
        if (file)
            designRequest.image = file;

        createDesignInDesignGroup(designGroup?._id as string, designRequest);
    }
    const handleDismiss = () => {
        setOpen(false);
    }

    return (
        <div>
            <PageTitle title={`Create a new design for ${designGroup?.name} `} />

            {String(type) === 'text' ?
                <section className="my-3">
                    <TextField
                        name="value"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(event.target.value);
                        }}
                        label="Deign Text Value"
                        value={value}
                        fullWidth
                        required
                    />
                </section> : null
            }
            {String(type) === 'integer' ?
                <section className="my-3">
                    <TextField
                        name="value"
                        type='number'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            let eventStr = event.target.value;
                            const eventArr = eventStr.split('.');
                            setValue(eventArr[0]);
                        }}
                        label="Design Integer Value"
                        value={value}
                        fullWidth
                        required
                    />
                </section> : null
            }
            {String(type) === 'float' ?
                <section className="my-3">
                    <TextField
                        name="value"
                        type='number'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(event.target.value);
                        }}
                        label="Design Float Value"
                        value={value}
                        fullWidth
                        required
                    />
                </section> : null
            }
            {String(type) === 'boolean' ?
                <section className="my-3">
                    <Select
                        value={boolValue}
                        placeholder='Design Boolean Value'
                        label="Design Boolean Value"
                        name='type'
                        onChange={(e) => {
                            setBoolValue(e.target.value)
                        }}
                        fullWidth
                    >
                        <MenuItem value='true'>True</MenuItem>
                        <MenuItem value='false'>False</MenuItem>
                    </Select>
                </section> : null
            }

            {String(type) === 'image' ?
                <section className="my-3">

                    <TextField
                        name="value"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(event.target.value);
                        }}
                        label="Deign Value"
                        value={value}
                        fullWidth
                        required
                    />

                    <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        className='my-2'
                        disableElevation
                    >
                        Upload File <AddBoxIcon />
                        <input
                            type="file"
                            hidden
                            accept=".jpg,.jpeg,.png"
                            onChange={handleFileInputChange}
                        />
                    </Button>
                    {previewUrl && <section className='mt-2'>
                        <img src={previewUrl} className="w-[300px] h-[300px]" alt="Preview" />
                        <p className='text-base text-[#e4b800]'>{fileSize}</p>
                    </section>}
                </section>
                : null
            }

            <Button
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleCreateDesignInDesignGroup}
            >Add Design</Button>
            <Button
                className="mt-3"
                onClick={handleDismiss}
                disableElevation
                fullWidth
                variant="outlined">
                Cancel
            </Button>
        </div>
    )
}

const mapDispatch = {
    createDesignInDesignGroup: (designGroupId: string, design: DesignRequest) => createDesignInDesignGroup(designGroupId, design),
    setAlert: (p: AlertMsg) => setAlert(p)
}
const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    return {
        alertMsg,
    }
}
export default connect(mapStateToProps, mapDispatch)(AddOneDesignToDesignGroupForm)