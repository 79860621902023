import { Button, TextField } from "@mui/material"
import { useState } from "react"
import { connect } from "react-redux"
import { updateAbTestComment } from "../../../redux/abtests/abTestsActions"
import { AbTestInterface, UpdateAbTestCommentRequest } from "../../../redux/abtests/interfaces"
import { ReduxAction, RootState } from "../../../redux/rootReducer"
import PageTitle from "../../../reusables/PageTitle"

type Props = {
    abTest: AbTestInterface,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    updateAbTestComment: (p: UpdateAbTestCommentRequest) => ReduxAction
}
function UpdateAbTestCommentForm({
    abTest,
    open,
    setOpen,
    updateAbTestComment
}:Props){
    const [comment,setComment] = useState<string>(abTest.comments);
    const handleDismiss = () => {
        setOpen(false)
    }
    const handleUpdateAbTestComment = () =>{
        const updateAbTestCommentRequest : UpdateAbTestCommentRequest  = {
            abTestId: abTest._id,
            comment,
        }
        updateAbTestComment(updateAbTestCommentRequest);
    }
    return (
        <div className="p-2">
            <PageTitle title={`Update Comment for ${abTest.name}`} />
            <section>
                <TextField
                    className="my-3"
                    fullWidth
                    required
                    label="Ab test Comment"
                    value={comment}
                    onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                        await setComment(event.target.value);
                        
                    }}
                />
            </section>
            <div className="">
                <Button
                    className="mt-3 mx-3"
                    onClick={handleUpdateAbTestComment}
                    disableElevation
                    color='primary'
                    sx={{ fontSize: '15px' }}
                    variant="contained">
                    Update Ab Test Comment
                </Button>
                <Button
                    className="mt-3 mx-3"
                    onClick={handleDismiss}
                    disableElevation
                    color='info'
                    sx={{ fontSize: '15px' }}
                    variant="outlined">
                    Cancel
                </Button>
            </div>
        </div>
    )

}

const mapStateToProps = (rootState: RootState) => {
    return {
        
    }
}

const mapDispatch = {
     updateAbTestComment:(p: UpdateAbTestCommentRequest) => updateAbTestComment(p),
}

export default connect(mapStateToProps,mapDispatch)(UpdateAbTestCommentForm)