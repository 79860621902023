
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createDesignGroup } from "../../../redux/abtests/abTestsActions";
import { CreateDesignGroupRequest } from "../../../redux/abtests/interfaces";
import { setAlert } from "../../../redux/admin/adminActions";
import { AlertMsg } from "../../../redux/admin/interfaces";
import { WARN_SNACKBAR } from "../../../redux/helpers/constants";
import { ReduxAction, RootState } from "../../../redux/rootReducer";
import AddCard from "../../../reusables/AddCard";
import CustomModal from "../../../reusables/CustomModal/CustomModal";
import DesignCard from "../../../reusables/DesignCard";
import PageTitle from "../../../reusables/PageTitle";
import AddOneDesignModal from "./AddOneDesignModal";

type Itype = "image" | "text" | "float" | "boolean" | "integer";

type Props = {
    abtestId: string,
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>> | ((b: boolean) => ReduxAction),
    createDesignGroup: (createDesignGroupRequest: CreateDesignGroupRequest) => ReduxAction,
    setAlert: (alertMsg: AlertMsg) => ReduxAction,
    alertMsg: AlertMsg,

}


function CreateDesignGroupForm({
    abtestId,
    createDesignGroup,
    setAlert,
    alertMsg
}: Props) {

    const dipatch = useDispatch();
    const [name, setName] = useState('');
    const [type, setType] = useState<Itype>('image');
    const [designs, setDesigns] = useState<string[]>([]);

    // IMAGE UPLOAD VARS
    const [files, setFiles] = useState<File[] | null>([]);
    const [previewUrls, setPreviewUrls] = useState<string[]>([]);

    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    // UPDATE VARS
    const [index, setIndex] = useState(-1);



    const handleDeleteDesign = (index: number) => {
        const filteredItems = designs.filter((item, i) => i !== index);
        setDesigns(filteredItems);

        if (type as string === 'image') {
            const filteredImages = previewUrls.filter((item, i) => i !== index);
            setPreviewUrls(filteredImages);
        }
    }


    const handleCreateDesignGroup = () => {
        if (!name) {
            dipatch(setAlert({
                msg: 'design group name required!',
                config: WARN_SNACKBAR
            }))
            return;
        }

        const createDesignGroupRequest: CreateDesignGroupRequest = {
            name,
            abtest: abtestId,
            type,
            designs,
            images: files as any
        }

        createDesignGroup(createDesignGroupRequest);
        setOpen(false);
    }

    return (
        <div className="flex flex-col flex-grow">
            <PageTitle title='Variants' />
            {/* design name */}
            <section
                className="w-56">
                <TextField
                    className="my-3"
                    required
                    fullWidth
                    error={!name}
                    label="Design Name"
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                    }}
                />
            </section>
            {/* Type of the designs  */}
            <section className="w-56">
                <p>Type</p>
                <Select
                    value={type}
                    placeholder='Type'
                    label="Type"
                    fullWidth
                    name='type'
                    onChange={(e) => {
                        setType(e.target.value as Itype)

                        // reset values
                        setDesigns([]);
                        setFiles([]);
                        setPreviewUrls([]);
                    }}
                >
                    <MenuItem value='image'>Image</MenuItem>
                    <MenuItem value='text'>Text</MenuItem>
                    <MenuItem value='float'>Float</MenuItem>
                    <MenuItem value='integer'>Integer</MenuItem>
                    <MenuItem value='boolean'>Boolean</MenuItem>
                </Select>
            </section>

            {/* add a new/view design  */}

            <div className="flex flex-row items-center overflow-x-scroll gap-5 my-4 h-[440px] overflow-x-scroll">
                {designs.map((d, i) =>
                    <DesignCard
                        key={i}
                        designs={designs}
                        previewUrls={previewUrls}
                        files={files}
                        idx={i}
                        type={type}
                        handleDeleteDesign={handleDeleteDesign}
                    />
                )}
                <AddCard setOpen={setOpen} />
            </div>


            {/* create the design in the DB */}
            <section
                className="w-56">
                <Button
                    fullWidth
                    color='primary'
                    variant='contained'

                    onClick={handleCreateDesignGroup}
                >
                    Create Variant Group
                </Button>
            </section>
            {/* the modal to add the designs  */}
            <CustomModal open={open} setOpen={setOpen}>
                <AddOneDesignModal
                    designs={designs}
                    previewUrls={previewUrls}
                    files={files}
                    type={type}
                    open={open} setOpen={setOpen} />
            </CustomModal >


        </div >
    )
}
const mapDispatch = {
    createDesignGroup: (p: CreateDesignGroupRequest) => createDesignGroup(p),
    setAlert: (p: AlertMsg) => setAlert(p)
}
const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    const { designGroup, designs } = rootState.abTests.designGroup;
    return {
        alertMsg,
        designGroup,
        designs
    }
}

export default connect(mapStateToProps, mapDispatch)(CreateDesignGroupForm)