import AddIcon from '@mui/icons-material/Add';
import { Button, TextField } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../redux/admin/adminActions";
import { AlertMsg } from "../../redux/admin/interfaces";
import { ERR_SNACKBAR } from "../../redux/helpers/constants";
import { CreateProjectRequest } from "../../redux/projects/interfaces";
import { createProject } from "../../redux/projects/projectsActions";
import { ReduxAction, RootState } from "../../redux/rootReducer";
import PageTitle from '../../reusables/PageTitle';
import './CreateProjectForm.css';

type Props = {
    createProject: (p: CreateProjectRequest) => ReduxAction,
    setAlert: (alert: AlertMsg) => ReduxAction,
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>> | ((b: boolean) => ReduxAction),
}

function CreateProjectForm(
    {
        createProject,
        setAlert,
        open,
        setOpen

    }: Props) {
    const [error, setError] = useState<boolean>(true);
    const [name, setName] = useState<string>('');

    const handleDismiss = () => {
        setOpen(false);
    }

    const handleCreateProject = () => {
        // TODO create alert action

        if (!name) { setAlert({ msg: 'name required', config: ERR_SNACKBAR }); return; }

        // create body to send to action 
        const body: CreateProjectRequest = {
            name,
        }
        createProject(body);
    }
    return (
        <div className="flex flex-col  p-2">

            <PageTitle title='Project Fields' />
            <section>
                <TextField
                    className="mt-3"
                    fullWidth
                    required
                    error={!name}
                    label="Name"
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                        if (!name) setError(true)
                        else setError(false);
                    }}
                />
            </section>
            <Button
                className="mt-3"
                onClick={handleCreateProject}
                disableElevation
                fullWidth
                variant="contained">
                Create Project
            </Button>
            <Button
                className="mt-3"
                onClick={handleDismiss}
                disableElevation
                fullWidth
                variant="outlined">
                Cancel
            </Button>
        </div>
    )
}
const mapDispatch = {
    createProject: (p: CreateProjectRequest) => createProject(p),
    setAlert: (p: AlertMsg) => setAlert(p)
}
const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    return {
        alertMsg
    }
}


export default connect(mapStateToProps, mapDispatch)(CreateProjectForm)
