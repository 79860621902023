import { Button, Card, CardActions, CardContent, CardMedia, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import EditOneDesignModal from "../components/AbTests/modals/EditOneDesignModal";
import CustomModal from "./CustomModal/CustomModal";
type Itype = "image" | "text" | "float" | "boolean" | "integer";
type Props = {
    handleDeleteDesign: (item: number) => void
    designs: string[],
    idx: number,
    previewUrls: string[]
    files: any | File[],
    type: Itype
}

function DesignCard({
    handleDeleteDesign,
    designs,
    idx,
    previewUrls,
    files,
    type
}: Props) {
    const [open, setOpen] = useState(false);
    const [designText, setDesignText] = useState<string>(designs[idx]);
    const DesignText = () => (
        designText && designText.length > 140 ? (
            <Tooltip title={designText}>
                <div className="
                h-36 p-3 bg-gray-200
                font-bold text-left overflow-hidden"
                    style={{
                        width: 'calc(100 %)',
                        borderBottom: '2px solid #1a1a1a'
                    }}
                >
                    <div>
                        {designText}
                    </div>
                </div>
            </Tooltip >
        ) : (
            <div className="
        h-36 p-3 bg-gray-200
        font-bold text-left overflow-hidden"
                style={{
                    width: 'calc(100 %)',
                    borderBottom: '2px solid #1a1a1a'
                }}>
                <div>
                    {designs[idx]}
                </div>
            </div>
        )
    );

    return (
        <Card
            className="w-56 flex-shrink-0 relative h-[405px]"
            key={idx}>
            {
                !previewUrls[idx] && !previewUrls[idx] ? (
                    <CardMedia
                        component={DesignText} />
                ) : (
                    <CardMedia
                        component="img"
                        alt="design img"
                        image={previewUrls[idx]}
                        className="object-contain object-top aspect-auto"
                    />
                )
            }
            <div className="absolute w-full"
                style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.13)',
                    bottom: 0
                }}
            >
                <CardContent className="pb-2"
                //  sx={theme === "dark" ? darkModeCardContent : {}}
                >
                    <p className="text-xl">{designs[idx]}</p>
                </CardContent>

                <CardActions>
                    <Button size="small" onClick={() => { setOpen(true); }}>Edit</Button>
                    <Button size="small" onClick={() => handleDeleteDesign(idx)}>Delete</Button>
                </CardActions>
            </div>

            <CustomModal open={open} setOpen={setOpen}>
                <EditOneDesignModal
                    designs={designs}
                    previewUrls={previewUrls}
                    files={files}
                    index={idx}
                    type={type}
                    open={open} setOpen={setOpen} />
            </CustomModal >
        </Card>
    );
}
export default DesignCard