import AddBoxIcon from '@mui/icons-material/AddBox';
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { setAlert } from "../../redux/admin/adminActions";
import { AlertMsg } from "../../redux/admin/interfaces";
import { WARN_SNACKBAR } from '../../redux/helpers/constants';
import { RemoteConfigRequest } from "../../redux/remoteConfigs/interfaces";
import { createRemoteConfig } from "../../redux/remoteConfigs/remoteConfigsActions";
import { ReduxAction, RootState } from "../../redux/rootReducer";
import { getFileSizeMB, getFileSizeMsg } from '../../reusables/helpers.tsx/utils';
import PageTitle from "../../reusables/PageTitle";



type Itype = "image" | "text" | "float" | "boolean" | "integer";

type Props = {
    projectId: string,
    createRemoteConfig: (remoteConfig: RemoteConfigRequest) => ReduxAction,
    open: boolean,
    setAlert: (alert: AlertMsg) => ReduxAction,
    alertMsg: AlertMsg,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function CreateRemoteConfigForm({
    projectId,
    createRemoteConfig,
    open,
    setOpen,
    setAlert,
    alertMsg

}: Props) {

    const dipatch = useDispatch();
    const [key, setKey] = useState<string>('');
    const [type, setType] = useState<Itype>('image');
    const [boolValue, setBoolValue] = useState('true');
    const [value, setValue] = useState('');


    // IMAGE UPLOAD VARS
    const [file, setFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [fileSize, setFileSize] = useState<string>('');

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setFile(file);
            const size = getFileSizeMsg(file);
            setFileSize(size);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setPreviewUrl(reader.result as string);
            };
        }
    };
    const handleDismiss = () => {
        setOpen(false);
    }

    const handleCreateRemoteConfig = () => {
        if (!key) {
            dipatch(setAlert({
                msg: 'key required!',
                config: WARN_SNACKBAR
            }));
            return;
        }
        if (!type) {
            dipatch(setAlert({
                msg: 'type required!',
                config: WARN_SNACKBAR
            }));
            return;
        }

        let createRCRequest: RemoteConfigRequest = {
            key,
            type,
            value,
            projectId,
            image: null
        }

        if (type === 'boolean') {
            createRCRequest['value'] = boolValue;
        }
        else if (type === 'image') {
            createRCRequest['value'] = '';

            if (!file) {
                dipatch(setAlert({
                    msg: 'file required!',
                    config: WARN_SNACKBAR
                }));
                return;
            }
            if (getFileSizeMB(file) > 1.6) {
                dipatch(setAlert({
                    msg: 'The file size is greater than 1.6MB',
                    config: WARN_SNACKBAR
                }));
                return;
            }
            createRCRequest['image'] = file;
        }
        else if (!value) {
            dipatch(setAlert({
                msg: 'value required!',
         
                config: WARN_SNACKBAR
            }));
            return;
        }
        createRemoteConfig(createRCRequest);
    }


    return (
        <div>
            <PageTitle title='Create Remote Config' />
            <section>
                <TextField
                    className="my-3"
                    fullWidth
                    required
                    error={!key}
                    label="Remote Config Key"
                    value={key}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setKey(event.target.value);
                    }}
                />
            </section>

            <section className="my-3">
                <p>Type</p>
                <Select
                    value={type}
                    placeholder='Type'
                    label="Type"
                    name='type'
                    onChange={(e) => {
                        setType(e.target.value as Itype)
                    }}
                    fullWidth
                >
                    <MenuItem value='image'>Image</MenuItem>
                    <MenuItem value='text'>Text</MenuItem>
                    <MenuItem value='float'>Float</MenuItem>
                    <MenuItem value='integer'>Integer</MenuItem>
                    <MenuItem value='boolean'>Boolean</MenuItem>
                </Select>
            </section>

            {String(type) === 'text' ?
                <section className="my-3">
                    <TextField
                        name="value"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(event.target.value);
                        }}
                        label="Remote Config Text Value"
                        value={value}
                        fullWidth
                        required
                    />
                </section> : null
            }
            {String(type) === 'integer' ?
                <section className="my-3">
                    <TextField
                        name="value"
                        type='number'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            let eventStr = event.target.value;
                            const eventArr = eventStr.split('.');
                            setValue(eventArr[0]);
                        }}
                        label="Remote Config Integer Value"
                        value={value}
                        fullWidth
                        required
                    />
                </section> : null
            }
            {String(type) === 'float' ?
                <section className="my-3">
                    <TextField
                        name="value"
                        type='number'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(event.target.value);
                        }}
                        label="Remote Config Float Value"
                        value={value}
                        fullWidth
                        required
                    />
                </section> : null
            }
            {String(type) === 'boolean' ?
                <section className="my-3">
                    <Select
                        value={boolValue}
                        placeholder='Remote Config Boolean Value'
                        label="Remote Config Boolean Value"
                        name='type'
                        onChange={(e) => {
                            setBoolValue(e.target.value)
                        }}
                        fullWidth
                    >
                        <MenuItem value='true'>True</MenuItem>
                        <MenuItem value='false'>False</MenuItem>
                    </Select>
                </section> : null
            }

            {String(type) === 'image' ?
                <section className="my-3">


                    <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        disableElevation
                    >
                        Upload File <AddBoxIcon />
                        <input
                            type="file"
                            hidden
                            accept=".jpg,.jpeg,.png"
                            onChange={handleFileInputChange}
                        />
                    </Button>
                    {previewUrl && <section className='mt-2'>
                        <img src={previewUrl} className="w-[300px] h-[300px]" alt="Preview" />
                        <p className='text-base font-semibold text-[#e4b800]'>{fileSize}</p>
                    </section>}
                </section>
                : null
            }

            <Button
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleCreateRemoteConfig}
            >Create Remote Config</Button>
            <Button
                className="mt-3"
                onClick={handleDismiss}
                disableElevation
                fullWidth
                variant="outlined">
                Cancel
            </Button>
        </div>
    )
}

const mapDispatch = {
    setAlert: (alert: AlertMsg) => setAlert(alert),
    createRemoteConfig: (remoteConfig: RemoteConfigRequest) => createRemoteConfig(remoteConfig)
}

const mapStateToProps = (rootState: RootState) => {
    const { alertMsg } = rootState.admin;
    return { alertMsg }
}
export default connect(mapStateToProps, mapDispatch)(CreateRemoteConfigForm);